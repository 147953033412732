import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslatePipe } from './translate.pipe';
import { MyCurrencyPipe } from './my-currency.pipe';
import { ModalComponent } from './modal-warning/modal.component';
import { PhonesellModalComponent } from './phonesell-modal/phonesell-modal.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FiltrarProfesoresPipe } from './filtrarProfesores.pipe';
import { ModalCookiesComponent } from './modal-cookies/modal-cookies.component';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FaConfig, FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import { DialogComponent } from './dialogs/dialog/dialog.component';
import { DialogoPopUp } from './components/alert/dialog/popup.service'
import { FlexLayoutModule } from '@angular/flex-layout';
import { PasteDirective } from './directives/paste.directive';
import { PoliticasComponent } from './politicas/politicas.component';
import { CalendarComponent } from '../business/ticket-sales/pages/date-selector/calendar/calendar.component';
import { MaterialModule } from '../shared/material/material.module';



@NgModule({
  declarations: [TranslatePipe, MyCurrencyPipe, FiltrarProfesoresPipe, ModalComponent, PhonesellModalComponent, ModalCookiesComponent, DialogComponent, DialogoPopUp, PoliticasComponent, PasteDirective, CalendarComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    FlexLayoutModule,
    MaterialModule
    
  ],

  exports: [TranslatePipe, MyCurrencyPipe, FiltrarProfesoresPipe, ModalComponent, PhonesellModalComponent, ModalCookiesComponent, DialogComponent,DialogoPopUp, PoliticasComponent, FlexLayoutModule,PasteDirective, CalendarComponent, MaterialModule]

})
export class SharedModule {
  // constructor() {
  //   library.add(fas, far, fab);
  // }
  constructor(library: FaIconLibrary, config: FaConfig) {
    config.fallbackIcon = faBan;
    library.addIconPacks(fas);
    library.addIconPacks(far);
    library.addIconPacks(fab);
  
  }
}
