
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { VentaService } from 'src/app/core/services/venta.service';
import { CompraCarrito } from 'src/app/models/compra-carrito.model';
import { LineaProducto } from 'src/app/models/compra.model';
import { Producto } from 'src/app/models/producto.model';
import { ProductosService } from '../productos.service';

@Injectable({
  providedIn: 'root'
})
export class EspectaculosService {
  compra: CompraCarrito;
  private fechaAutomaticaSubject: BehaviorSubject<any>;
  public fechaAutomatica$: Observable<any>;

  private posicionVentaSubject: BehaviorSubject<any>;
  public posicionVenta$: Observable<any>;



  constructor(private productosService: ProductosService,
    private ventaService: VentaService) { 
      this.fechaAutomaticaSubject = new BehaviorSubject<any>(false);
      this.fechaAutomatica$ = this.fechaAutomaticaSubject.asObservable();
      this.posicionVentaSubject = new BehaviorSubject<any>(undefined);
      this.posicionVenta$ = this.posicionVentaSubject.asObservable();

    }


  get fechaAutomaticaValue(){
    return this.fechaAutomaticaSubject.value;
  }

  public setFechaAutomatica(valor){
    this.fechaAutomaticaSubject.next(valor);
  }

  get posicionVentaValue(){
    return this.posicionVentaSubject.value;
  }

  public setPosicionVenta(valor){
    this.posicionVentaSubject.next(valor);
  }

  
  butacasToEntrada(butaca){
    let posicionVenta = this.ventaService.compraValue.productos && this.ventaService.compraValue.productos[0] && this.ventaService.compraValue.productos[0].posicionVenta ? this.ventaService.compraValue.productos[0].posicionVenta : this.modificarValue && this.posicionVentaValue && +this.posicionVentaValue > 0   ? this.posicionVentaValue : this.ventaService.calcularposicionVenta();

    // let posicionVenta = this.ventaService.compraValue.productos && this.ventaService.compraValue.productos[0] && this.ventaService.compraValue.productos[0].posicionVenta ? this.ventaService.compraValue.productos[0].posicionVenta : this.productosService.calcPosicionVenta(); 
    //let posicionVenta = this.ventaService.compraValue.productos[0] && this.ventaService.compraValue.productos[0].posicionVenta ? this.ventaService.compraValue.productos[0].posicionVenta : this.productosService.calcPosicionVenta(); 
    const butacaToEntrada : Producto = {
    //...butaca,
    NombreProducto: butaca.tipoSeleccionado.NombreProducto,
    MaximoTarifa: butaca.tipoSeleccionado.MaximoTarifa,
    MinimoTarifa: butaca.tipoSeleccionado.MinimoTarifa,
    PrecioBase: butaca.tipoSeleccionado.PrecioBase,
    PVPInternet: butaca.tipoSeleccionado.PVPInternet,
    Comision:butaca.tipoSeleccionado.Comision,
    NumeroTotalEntradasBono: butaca.tipoSeleccionado.NumeroTotalEntradasBono,
    OrdenTarifa: false,
    ComisionInternet:butaca.tipoSeleccionado.ComisionInternet,
    ComisionBase:butaca.tipoSeleccionado.ComisionBase,
    IvaComision:butaca.tipoSeleccionado.IVAComision,
    PrecioEnDivisaReferencia: butaca.tipoSeleccionado.PrecioInternet,
    DesglosarComision:butaca.tipoSeleccionado.desglosarComision,
    Descuento: butaca.tipoSeleccionado.Descuento,
    IVA: butaca.tipoSeleccionado.IVAPrecio,
    ProductoId: butaca.tipoSeleccionado.ProductoId,
    TipoProducto: butaca.tipoSeleccionado.TipoProducto,
    enlaceMenu: this.productosService.getEnlaceMenuId().pkid,
    posicionVenta: posicionVenta,
    GrupoInternet: butaca.tipoSeleccionado.GrupoInternet,
    PVPSinDescuento: butaca.tipoSeleccionado.PVPSinDescuento,
    motivoId: "",
    motivos: [],
    GrupoTarifa: butaca.tipoSeleccionado.GrupoTarifa,
    idEspectaculoAsociado: butaca.idEspectaculoAsociado,
    butacas: this.generarButaca(butaca),
    IdTarifa: butaca.tipoSeleccionado.IdTarifa
  }

      return butacaToEntrada;
}
  
generarButaca (butaca){
  const datos = [{
                idRecinto: butaca.tipoSeleccionado.idRecinto,
                NombreRecinto: butaca.info.infoGeneral.nombreRecinto,
                NombreGruposRecinto: this.productosService.espectaculoValue.NombreGruposRecinto,
                numFilaID: butaca.dataNumFila,
                numButacaID: butaca.dataNumButaca,
                FechaSesion: this.ventaService.fechaSeleccionadaValue.format('YYYY/MM/DD'),
                HoraSesion: this.ventaService.horaSeleccionadaValue.format('HH:mm'),
                EspectaculoId: butaca.idEspectaculoAsociado,
                NombreEspectaculo: this.productosService.espectaculoValue.NombreEspectaculo,
                TipoButaca: butaca.tipoSeleccionado.TipoButacaId,
                EnlaceMenuId: this.productosService.getEnlaceMenuId().pkid,
                idRecintoButaca: butaca.idRecintoButaca, //codigo de la butaca en el json
                idRB: butaca.PKIDRB    //codigo unico de la base de datos en tbRecintosButacas
  }]
  return datos;

}
 //addProduct como pushEntrada / pushButacaNoNumerada
  addProduct(butaca) {
    this.ventaService.sendProducto(this.butacasToEntrada(butaca));
  }

  sendProductoModificar(producto){
    this.ventaService.sendProductoModificar(producto);
  }

  pushButaca(butaca: any): boolean {
    if (this.comprobarSiButacaAgregada(butaca)) {
      return true;
    }
    this.addProduct(butaca);
    return true;
  }

  comprobarSiButacaAgregada(butaca: any) {
    let existe = false;
    const productosConButacas = this.ventaService.compraValue.productos.filter(producto => producto.producto.butacas && producto.producto.butacas.length > 0);
    productosConButacas.forEach(producto => {
      if(producto.producto.butacas.find(b => b.idRecintoButaca === butaca.idRecintoButaca)){
        existe = true;
      }
    });
    return existe;
  }

  butacasSeleccionadas(){
    let idButacas = [];
    const productosConButacas = this.ventaService.compraValue.productos.filter(producto => producto.producto.butacas && producto.producto.butacas.length > 0);
    productosConButacas.forEach(producto => {
      producto.producto.butacas.forEach(butaca => {
        idButacas.push(+butaca.idRecintoButaca); 
      });
      
    });
    return idButacas;
  }

  existeButaca(butaca){
    let newProducto: LineaProducto;
    //Yaribel 20230228 como tenemos que modificar el objeto hacemos una copia profunda para evitar reescribir el original
    const productosConButacas = JSON.parse(JSON.stringify(this.ventaService.compraValue.productos.filter(producto => producto.producto.butacas && producto.producto.butacas.length > 0)));
    productosConButacas.forEach(producto => {
      if(producto.producto.butacas.find(b => b.idRecintoButaca === butaca.idRecintoButaca)){       
        newProducto = {
          ...producto,
          cantidad: 1,
        };
        newProducto.producto.butacas = [{...JSON.parse(JSON.stringify(producto.producto.butacas.find(b => b.idRecintoButaca === butaca.idRecintoButaca)))}];
      }
    });
    return newProducto;
  }




  removeButaca(butaca: any) {
   this.ventaService.compraValue.eliminarProducto(this.butacasToEntrada(butaca));
  }

  removeAllButaca(): void {
    // this.setEntradasSeleccionadasValue([]);
  }
 
  existeProducto(butaca){
    let newProduct:Producto = this.butacasToEntrada(butaca);
    return this.ventaService.compraValue.existeProducto(newProduct);
  }

  eliminarButacaDesdeMapa(butaca){
  this.ventaService.compraValue.eliminarProducto(butaca.producto);
  }

  getCantidadPorProducto(butaca){
    let newProduct:Producto = this.butacasToEntrada(butaca);
    return this.ventaService.getCantidadPorProducto(newProduct);
  }

  //Yaribel 20230308 Se obtiene un nuevo objeto el producto y la butaca  para eliminarla del carrito
  obtenerProductoButaca(butaca){
    let newProducto: LineaProducto;
    //Yaribel 20230228 como tenemos que modificar el objeto hacemos una copia profunda para evitar reescribir el original
    const productosConButacas = JSON.parse(JSON.stringify(this.ventaService.carritoValue.compras.filter(producto => producto.producto.butacas && producto.producto.butacas.length > 0)));
    productosConButacas.forEach(producto => {
      if(producto.producto.butacas.find(b => b.idRB === butaca.idRB)){    
        if(producto.cantidad == 1){
          newProducto = producto;
        } else{
          newProducto = {
            ...JSON.parse(JSON.stringify(producto))
          };
          newProducto.producto.butacas = [{...JSON.parse(JSON.stringify(producto.producto.butacas.find(b => b.idRB === butaca.idRB)))}];
        }   
       
      }
    });
    return newProducto;
  }

  eliminarButacaDesdeCarrito(butaca){
    this.ventaService.carritoValue.eliminarEntrada(butaca);
    }

    idsCompraReservaInicial(){
      let idButacas = [];
      const productosConButacas = this.ventaService.compraValue.productos.filter(producto => producto.producto.butacas && producto.producto.butacas.length > 0);
      productosConButacas.forEach(producto => {
        producto.producto.butacas.forEach(butaca => {
          idButacas.push(+butaca.idRB); 
        });
        
      });
      return idButacas;
    }

    idsCompraReservaTodos(){
      let idButacas = [];
      const productosConButacas = this.ventaService.ordenarCarrito();
      if (productosConButacas) {
        productosConButacas.forEach((grupo, i) => {
          grupo.forEach(item => {
            if (item.producto.butacas!==undefined || item.producto.butacas) {
              item.producto.butacas.forEach(butaca => {
                idButacas.push(+butaca.idRB); 
              });
            }
          });
        }); 
      }    
      return idButacas;
    }



    idsCarroReservaInicial(){
      let idButacas = [];
      const productosConButacas = this.ventaService.carritoValue.compras.filter(producto => producto.producto.butacas && producto.producto.butacas.length > 0);      
      productosConButacas.forEach(producto => {
        producto.producto.butacas.forEach(butaca => {
          idButacas.push(+butaca.idRB); 
        });
        
      });
      return idButacas;
    }

    compraReservaInicial(){
      const productosConButacas = this.ventaService.compraValue.productos.filter(producto => producto.producto.butacas && producto.producto.butacas.length > 0);
      return productosConButacas;
    }

    carroReservaInicial(){
      const productosConButacas = this.ventaService.carritoValue.compras.filter(producto => producto.producto.butacas && producto.producto.butacas.length > 0);
      return productosConButacas;
    }

    modificarValue(){
      let valor = this.ventaService.modificarValue;
      return valor;
    }
    

}
