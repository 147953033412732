import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import * as moment from "moment";
import { ButacasService } from "../servicios/butacas.service";
import { InfoMapasService } from "../servicios/info-mapas.service";

@Component({
  selector: "iacpos-selector-butacas",
  templateUrl: "./selector-butacas.component.html",
  styleUrls: ["./selector-butacas.component.css"],
  host: {
    class: "col-12 col-xs-12 col-sm-12 col-md-12 col-lg-11 a",
  },
})
export class SelectorButacasComponent implements OnInit, OnDestroy {
  public cambiosMapa: Event;
  public cambiosMinimapa: Event;
  public nombreEstadio: string;
  renderMapa = true;
  estadosButacas: any;
  noNumerada = false;
  verMapa = false;
  tiposButacasRecinto = [];
  estadosButacasRecinto = [];
  sliderVisible = false;
  errorButacasMsg: string;

  hacerZoom = false;

  datosForm: FormGroup;
  claveEmpresa: string;

  @Input() menucontextual: boolean = false;
  @Input() fecha: string;
  @Input() hora: string;
  @Input() espectaculo: any;

  @ViewChild("container", { static: false }) container: any;
  @ViewChild("mapa", { static: false }) mapa: any;
  butacaMarcada = [];

  constructor(
    private formBuilder: FormBuilder,
    private infoMapas: InfoMapasService,
    private infoMapasService: InfoMapasService,
    public butacasService: ButacasService
  ) {}

  ngOnInit() {
    this.butacasService.filtroPreparado.subscribe((data) => {
      if (data) {
        this.sliderVisible = true;
      }
    });
    var momenthora = moment(this.hora, "HH:mm:ss");
    var momentfecha = moment(this.fecha, "YYYY/MM/DD");
    this.butacasService.setEspectaculoValue(this.espectaculo);
    this.butacasService.setFechaSeleccionadaValue(momentfecha);
    this.butacasService.setHoraSeleccionadaValue(momenthora);
    this.nombreEstadio = this.espectaculo.NombreFicheroSVG.split(".")[0];
    //this.nombreEstadio = this.nombremapa;

    this.butacasService.estadosButacas.subscribe((data) => {
      data.map((item) => this.estadosButacasRecinto.push(item.Estado));
    });
    this.infoMapasService
      .getButacas(this.nombreEstadio)
      .subscribe((butacas) => {
        if (butacas.length === 1) {
          this.noNumerada = true;
          this.verMapa = false;
          this.butacasService.getEstadosButacas(
            this.infoMapasService.recinto,
            this.nombreEstadio
          );
        } else {
          butacas.map((item) => {
            if (!this.tiposButacasRecinto.includes(item["data-tipo-butaca"])) {
              this.tiposButacasRecinto.push(item["data-tipo-butaca"]);
            }
          });
          this.verMapa = true;
          this.noNumerada = false;
        }
      });
    if (this.container) {
      this.infoMapas.boxWidth = this.container.nativeElement.offsetWidth - 12;
      this.infoMapas.boxHeight = this.container.nativeElement.offsetHeight - 12;
    }
    this.datosForm = this.formBuilder.group({
      numEntradas: [
        "",
        [
          Validators.required,
          Validators.maxLength(255),
          Validators.pattern("^[0-9]*$"),
        ],
      ],
    });
    this.butacasService.leyendaSubject.subscribe(
      (data) => (this.estadosButacas = data)
    );
    this.claveEmpresa = this.butacasService.getConfig("chClaveEmpresa");

    this.butacasService.butacaMarcada.subscribe((b: any) => {
      this.butacaMarcada = [];

      if (b != undefined && b != null && b.info && b.info.tiposDeEntrada) {
        for (let index = 0; index < b.info.tiposDeEntrada.length; index++) {
          this.butacaMarcada.push(b.info.tiposDeEntrada[index]);
        }
      }
    });
  }

  public onMostrarMarcadasChanged(value: boolean) {
    const listaButacasAnimacion = document.querySelectorAll("circle");

    if (value) {
      for (let iLBA = 0; iLBA < listaButacasAnimacion.length; iLBA++) {
        const elementMapa = listaButacasAnimacion[iLBA];
        if (!elementMapa.classList.contains("marcada")) {
          elementMapa.style.visibility = "hidden";
        }
      }
    } else {
      for (let iLBA = 0; iLBA < listaButacasAnimacion.length; iLBA++) {
        const elementMapa = listaButacasAnimacion[iLBA];
        if (!elementMapa.classList.contains("marcada")) {
          elementMapa.style.visibility = "initial";
        }
      }
    }
  }

  preventDefault(e) {
    if (e && e.preventDefault) {
      e.preventDefault(); // TODO : En chrome se lanzara un mensaje por consola debio a que el evento es passive
    }
    e.returnValue = false;
  }

  preventScroll(event) {
    if (window.addEventListener) {
      // older FF
      window.addEventListener("DOMMouseScroll", this.preventDefault, false);
    }

    document.addEventListener("wheel", this.preventDefault, { passive: false }); // Disable scrolling in Chrome

    window.onwheel = this.preventDefault; // modern standard
    // window.onmousewheel = document.onmousewheel = this.preventDefault; // older browsers, IE
    window.ontouchmove = this.preventDefault; // mobile
    // event.preventDefault();
  }

  doScroll(event) {
    if (window.removeEventListener) {
      window.removeEventListener("DOMMouseScroll", this.preventDefault, false);
    }
    document.removeEventListener("wheel", this.preventDefault, false); // Enable scrolling in Chrome
    // window.onmousewheel = document.onmousewheel = null;
    window.onwheel = null;
    window.ontouchmove = null;
    document.onkeydown = null;
  }



  ngOnDestroy() {
    this.butacasService.setAsientosDeshablitados([]);
  }

  eventMapTransformation(event: Event) {
    this.cambiosMapa = event;
  }

  eventMinimapTransformation(event: Event) {
    this.cambiosMinimapa = event;
  }

  onResize(box) {
    this.infoMapas.boxWidth = box.offsetWidth - 12;
    this.infoMapas.boxHeight = box.offsetHeight - 12;
  }

  masc() {
    this.infoMapas.button("masc");
  }

  arriba() {
    this.infoMapas.button("arriba");
  }

  derecha() {
    this.infoMapas.button("derecha");
  }

  izquierda() {
    this.infoMapas.button("izquierda");
  }

  abajo() {
    this.infoMapas.button("abajo");
  }

  menosc() {
    this.infoMapas.button("menosc");
  }

  recargarMapa() {
    //this.mapa.recargarMapa();
  }

  minimapaRender() {
    this.renderMapa = !this.renderMapa;
  }
}
