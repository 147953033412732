import { CompraCarrito } from './compra-carrito.model';
import 'moment/locale/es';

export class Carrito {
  compras: CompraCarrito[];
  incluirComision = true;

  constructor(compras: CompraCarrito[] = []) {
    this.compras = compras;
  }

  setIncluirComision(value){
    this.incluirComision = value;
  }
  agregarCompra(compra: CompraCarrito) {
    this.compras.push(compra);
  }

  eliminarTodasEntradasPorTipoEntrada(compra: CompraCarrito) {
    this.compras = this.compras.filter(c => c.posicionVenta !== compra[0].posicionVenta);
  }

  eliminarTodasEntradasPorTipoAforoSP(compra: CompraCarrito) {
    this.compras = this.compras.filter(c => c.posicionVenta !== compra.posicionVenta);
  }

  eliminarEntradasTipo(compra: CompraCarrito) {
    const index = this.compras.findIndex(e => e.posicionVenta == compra.posicionVenta && e.producto.ProductoId === compra.producto.ProductoId && e.producto.TipoProducto === compra.producto.TipoProducto);
    this.compras.splice(index, 1);
  }

  eliminarEntrada(compra: CompraCarrito, datosProducto, datosSeleccionados) {     
    const indice = this.compras.findIndex(e => e.posicionVenta == compra.posicionVenta && e.producto.ProductoId === compra.producto.ProductoId && e.producto.TipoProducto === compra.producto.TipoProducto);    
    if (datosSeleccionados) {
      const compraArray: any = compra;
      let cantidadEntradas;
      if (compraArray.producto.ProductosBono.length) {
        cantidadEntradas = compraArray.producto.ProductosBono.filter(bono => bono.CuentaNominada === '1').length;
      } else {
        cantidadEntradas = 1;
      }
      const index = datosProducto.indexOf(datosSeleccionados);     
      datosProducto.splice(index, cantidadEntradas);
    }
    if (compra.cantidad > 1) {
      this.compras[indice].cantidad--; 
      //Yaribel 20230308 modificamos para gestionar butacas
      if(compra.producto.butacas && compra.producto.butacas.length > 0)
      {
      const index = this.compras.findIndex(e => e.posicionVenta == compra.posicionVenta && e.producto.ProductoId === compra.producto.ProductoId && e.producto.TipoProducto === compra.producto.TipoProducto);
      const indexB = this.compras[index].producto.butacas.findIndex(b=> b.idRecintoButaca === compra.producto.butacas[0].idRecintoButaca);
      if(compra.producto.butacas && compra.producto.butacas.length > 0)
      {
        this.compras[index].producto.butacas.splice(indexB, 1);
        this.compras[index].cantidad--;        
      } 
    } 
    } else {
      const compraArray: any = compra;
      let encontrado = this.compras.includes(compraArray);
      const index = this.compras.findIndex(e => e.posicionVenta == compra.posicionVenta && e.producto.ProductoId === compra.producto.ProductoId && e.producto.TipoProducto === compra.producto.TipoProducto); //this.compras.indexOf(compraArray);
      this.compras.splice(index, 1);
    }
  }

  eliminarProductosPorposicionVenta(id) {
    this.compras = this.compras.filter(producto =>
      producto.posicionVenta !== id);
  }

  calcularPrecioTotal() {
    let precioTotal = 0;
    this.compras.forEach(compra => { 
      if(this.incluirComision && compra.producto.DesglosarComision == "0"){
        precioTotal = Number.parseFloat(precioTotal.toFixed(4)) + ((Number.parseFloat(Number.parseFloat((compra.producto.PVPSinDescuento).replace(/,/, '.')).toFixed(4)) + Number.parseFloat(Number.parseFloat((compra.producto.ComisionInternet || compra.producto.Comision || '0' ).replace(/,/, '.')).toFixed(4))) * compra.cantidad);
      }else{
        precioTotal = Number.parseFloat(precioTotal.toFixed(4)) + (Number.parseFloat(Number.parseFloat((compra.producto.PVPSinDescuento).replace(/,/, '.')).toFixed(4)) * compra.cantidad);
      }
      //precioTotal = Number.parseFloat(precioTotal.toFixed(4)) + (Number.parseFloat(Number.parseFloat((compra.producto.PVPSinDescuento).replace(/,/, '.')).toFixed(4)) * compra.cantidad);
      //precioTotal = Math.round(precioTotal * 100) / 100;
    });
    return Number.parseFloat(precioTotal.toFixed(2));
    // return precioTotal;
  }

  calcularPrecioTotalGrupoCarrito(grupo) {
    const posicionVenta = grupo[0].posicionVenta;
    let precioTotal = 0;
    this.compras.forEach((compra: any) => {
      if (compra.posicionVenta === posicionVenta) {
        precioTotal = Number.parseFloat(precioTotal.toFixed(4)) + (Number.parseFloat(Number.parseFloat((compra.producto.PVPSinDescuento).replace(/,/, '.')).toFixed(4)) * compra.cantidad);
        //precioTotal = Math.round(precioTotal * 100) / 100;
      }
    });
    return Number.parseFloat(precioTotal.toFixed(2));
    // return precioTotal;
  }

  calcularPrecioTotalSinIva() {
    let precioTotal = 0;
    this.compras.forEach(compra => {
      precioTotal = Number.parseFloat(precioTotal.toFixed(4)) + (Number.parseFloat(Number.parseFloat(compra.producto.PrecioBase.replace(/,/, '.')).toFixed(4)) * compra.cantidad);
      //precioTotal = Math.round(precioTotal * 100) / 100;  
    });
    return Number.parseFloat(precioTotal.toFixed(2));
    // return precioTotal;
  }

  calcularImporteInicial() {
    let precioTotal = 0;
    this.compras.forEach(compra => {
      if(this.incluirComision && compra.producto.DesglosarComision == "0"){
        precioTotal = Number.parseFloat(precioTotal.toFixed(4)) + ((Number.parseFloat(Number.parseFloat(compra.producto.PVPSinDescuento.replace(/,/, '.')).toFixed(4)) + Number.parseFloat(Number.parseFloat(compra.producto.ComisionInternet.replace(/,/, '.')).toFixed(4))) * compra.cantidad);
      }else{
        precioTotal = Number.parseFloat(precioTotal.toFixed(4)) + (Number.parseFloat(Number.parseFloat(compra.producto.PVPSinDescuento.replace(/,/, '.')).toFixed(4)) * compra.cantidad);
      }
      // precioTotal = Number.parseFloat(precioTotal.toFixed(4)) + (Number.parseFloat(Number.parseFloat(compra.producto.PVPSinDescuento.replace(/,/, '.')).toFixed(4)) * compra.cantidad);
      //precioTotal = Math.round(precioTotal * 100) / 100;
    });
    return Number.parseFloat(precioTotal.toFixed(2));
    // return precioTotal;
  }

  calcularTotalEntradas() {
    let totalEntradas = 0;
    this.compras.forEach(compra => {
      if (compra.producto.NumeroTotalEntradasBono !== '0') {
        totalEntradas = totalEntradas + (Number.parseInt(compra.producto.NumeroTotalEntradasBono, 10) * compra.cantidad);
      } else {
        totalEntradas += compra.cantidad;
      }
    });
    return totalEntradas;
  }

  calcularCantidadProductos() {
    let cantidadProductos = 0;
    this.compras.forEach(compra => {
      cantidadProductos = cantidadProductos + compra.cantidad;
    });
    return cantidadProductos;
  }

  getCantidadPorProducto(compra) {
    return compra.cantidad;
  }

  getPrecioPorProducto(compra) {
    let precioTotal = 0;
    if(this.incluirComision && compra.producto.DesglosarComision == "0"){
      precioTotal = compra.cantidad * (Number.parseFloat(Number.parseFloat((compra.producto.PVPSinDescuento).replace(/,/, '.')).toFixed(4)) + Number.parseFloat(Number.parseFloat((compra.producto.ComisionInternet).replace(/,/, '.')).toFixed(4)));
    } else{
      precioTotal = compra.cantidad * Number.parseFloat(Number.parseFloat((compra.producto.PVPSinDescuento).replace(/,/, '.')).toFixed(4));
    }
    //precioTotal = compra.cantidad * Number.parseFloat(Number.parseFloat((compra.producto.PVPSinDescuento).replace(/,/, '.')).toFixed(4));
    //precioTotal = Math.round(precioTotal * 100) / 100;
    //return Number.parseFloat(precioTotal.toFixed(4));;
    return Number.parseFloat(precioTotal.toFixed(2));;
    // return precioTotal;
  }


  calcularComision() {
    let precioTotal = 0;
    this.compras.forEach(compra => {
      if(this.incluirComision && compra.producto.DesglosarComision == "1") {         
        precioTotal = Number.parseFloat(precioTotal.toFixed(4)) + (Number.parseFloat(Number.parseFloat((compra.producto.ComisionBase).replace(/,/, '.')).toFixed(4)) * compra.cantidad);
      }      
    });
     /* Validar 3er dígito */    
     let strPrecio = precioTotal.toString();
     let arrPrecio = [];
     if(strPrecio.includes('.')) {
       arrPrecio = strPrecio.split('.');
       if(arrPrecio[1].length > 2) {
         precioTotal = arrPrecio[1][2] === '5' ? Number.parseFloat(`${arrPrecio[0]}.${arrPrecio[1]}`) + 0.001 : Number.parseFloat(`${arrPrecio[0]}.${arrPrecio[1]}`);
       }       
     } 
    return Number.parseFloat(precioTotal.toFixed(2));
  }

  calcularIVAComision() {
    let precioTotal = 0;
    this.compras.forEach(compra => {
      if(this.incluirComision && compra.producto.DesglosarComision == "1")
      {
        //let comisionInternet = Number.parseFloat((compra.producto.ComisionInternet).replace(/,/, '.'))
        //let comisionBase = Number.parseFloat((compra.producto.ComisionBase).replace(/,/, '.'))
        //let IvaComision = Number.parseFloat((Number.parseFloat(comisionInternet.toFixed(4)) - Number.parseFloat(comisionBase.toFixed(4))).toFixed(4))
        //precioTotal = Number.parseFloat(precioTotal.toFixed(4)) + (IvaComision * compra.cantidad);

        let comisionInternet = Number.parseFloat((compra.producto.ComisionInternet).replace(/,/, '.')).toFixed(4);
        let comisionBase = Number.parseFloat((compra.producto.ComisionBase).replace(/,/, '.')).toFixed(4);
        let IvaComision = Number.parseFloat((Number.parseFloat(comisionInternet) - Number.parseFloat(comisionBase)).toFixed(4))
        precioTotal = Number.parseFloat(precioTotal.toFixed(4)) + (IvaComision * compra.cantidad);

      }
    });
    //return Number.parseFloat(precioTotal.toFixed(4));
    return Number.parseFloat(precioTotal.toFixed(2));
  }

  calcularDescuento(){
    let precioTotal = 0 ;
    this.compras.forEach(compra => {
      precioTotal = Number.parseFloat(precioTotal.toFixed(4)) +  this.getTwoDecimal(Number.parseFloat((compra.producto.Descuento).replace(/,/, '.')).toFixed(4)) * compra.cantidad;
      //precioTotal = Number.parseFloat(precioTotal.toFixed(4)) + Number.parseFloat(Number.parseFloat((compra.producto.Descuento).replace(/,/, '.')).toFixed(4)) * compra.cantidad;
      //precioTotal = Math.round(precioTotal * 100) / 100;
    });
    //return Number.parseFloat(precioTotal.toFixed(4));
    precioTotal = Number.parseFloat(precioTotal.toFixed(4));
    return this.getTwoDecimal(precioTotal);
  }

  getTwoDecimal(num) { 
    /*let string = num.toString();
    return Number(string.substring(0, string.indexOf('.') + 3));*/
    if(!num) {
      return 0;
    }    
    if(typeof num !== 'number') {
      num = Number.parseFloat(num);
    }
    return Number.parseFloat((num).toFixed(2));
  }  
}

