import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Sesion } from './sesion.interface';
import { ProductosService } from 'src/app/business/productos.service';
import * as moment from 'moment';
import 'moment/locale/es';
import 'moment/min/locales';
import { VentaService } from 'src/app/core/services/venta.service';

interface CalendarDate {
  mDate: moment.Moment;
  selected?: boolean;
  today?: boolean;
  enabled?: boolean;
  complete?: boolean;
  ultimas?: boolean;
  selectable?: boolean;
}
interface ControlTiempos {
  iTiempoReservaMax?: number;
  iTiempoReservaMin?: number;
  iTiempoVentaMax?: number;
  iTiempoVentaMin?: number;
  chTiempoReservaMaxTipo?: string;
  chTiempoReservaMinTipo?: string;
  chTiempoVentaMaxTipo?: string;
  chTiempoVentaMinTipo?: string;
}

export enum Disponibilidad {
  DEFAULT, BUENA, ULTIMAS, AGOTADAS, FUERARANGO
}

@Injectable({
  providedIn: 'root'
})
export class SesionService {

  private sesionesSubject: BehaviorSubject<Sesion[]>;
  public sesiones$: Observable<Sesion[]>;

  private sesionesProfesorSubject: BehaviorSubject<Sesion[]>;
  public sesionesProfesor$: Observable<Sesion[]>;

  private weeksSubject: BehaviorSubject<CalendarDate[][]>;
  public weeks: Observable<CalendarDate[][]>;

  private noAforoSubject: BehaviorSubject<any>;
  public noAforo: Observable<any>;

  grupoInternetId: string;

  constructor(
    private productosService: ProductosService,
    private ventaService: VentaService,
  ) {
    this.sesionesSubject = new BehaviorSubject([]);
    this.sesiones$ = this.sesionesSubject.asObservable();

    this.sesionesProfesorSubject = new BehaviorSubject([]);
    this.sesionesProfesor$ = this.sesionesSubject.asObservable();

    this.weeksSubject = new BehaviorSubject([]);
    this.weeks = this.weeksSubject.asObservable();

    this.noAforoSubject = new BehaviorSubject(undefined);
    this.noAforo = this.noAforoSubject.asObservable();
  }

  public get noAforoValue() {
    return this.noAforoSubject.value;
  }

  public setNoAforo(aforo) {
    this.noAforoSubject.next(aforo);
  }

  public get weeksValue() {
    return this.weeksSubject.value;
  }

  public setWeeks(cliente) {
    this.weeksSubject.next(cliente);
  }

  get sesiones() {
    return this.sesionesSubject.value;
  }

  public setSesiones(sesiones) {
    this.sesionesSubject.next(sesiones);
  }

  get sesionesProfesor() {
    return this.sesionesProfesorSubject.value;
  }

  public setSesionesProfesor(sesiones) {
    this.sesionesProfesorSubject.next(sesiones);
  }

  getSesionesPorProducto(producto) {
    let diasIntervalo = '';
    //const dia = this.ventaService.fechaSeleccionadaValue.format('DD/MM/YYYY');
  
    if (producto.ProductosBono.length === 0) {
      diasIntervalo = producto.DiasIntervalo;
    } else {
      diasIntervalo = producto.ProductosBono[0].DiasIntervalo;
    }
    const horasList = [];
    if (diasIntervalo === '60') {
      this.sesiones.forEach((sesion: any) => {
        if (this.comprobarComentario(sesion)) {
          if (sesion.Fecha === moment().format('DD/MM/YYYY')) {
            if (moment(sesion.HoraInicioCompra, 'DD/MM/YYYY HH:mm').isAfter()) {
              horasList.push(sesion);
            }
          } else {
            horasList.push(sesion);
          }
        }
      });
      return horasList;
    } else {
        this.sesiones.forEach((sesion: any, i) => {
          if(sesion.TipoAforo === 'SD') {
            sesion = this.minVentaControlSD(this.sesiones, this.productosService.getEnlaceMenuId()).find(item =>item.TipoAforo === 'SD');
            if (sesion.Disponible === '0') {
              if (sesion.Fecha === moment().format('DD/MM/YYYY')) {
                if (moment(sesion.HoraFinCompra, 'DD/MM/YYYY HH:mm').isAfter()) {
                  horasList.push(sesion);
                }
              } else {
                horasList.push(sesion);
              }
            } else if (sesion.Disponible !== '0') {
              if (this.sesiones[i].Disponible !== '0') {
                if (sesion.Fecha === moment().format('DD/MM/YYYY')) {
                  if (moment(sesion.HoraFinCompra, 'DD/MM/YYYY HH:mm').isAfter()) {
                    horasList.push(sesion);
                  }
                } else {
                  horasList.push(sesion);
                }
              } else if (this.sesiones[i].Disponible === '0') {
                if (sesion.Fecha === moment().format('DD/MM/YYYY')) {                 
                  if (moment(sesion.HoraFinCompra, 'DD/MM/YYYY HH:mm').isAfter()) {
                    sesion.Disponible = '0';
                    horasList.push(sesion);
                  }
                } else {
                  sesion.Disponible = '0';
                  horasList.push(sesion);
                }
              }
            }            
          } else {
              if (this.comprobarComentario(sesion)) {           
                if (sesion.Disponible === '0') {
                  if (sesion.Fecha === moment().format('DD/MM/YYYY')) {
                    if (moment(sesion.HoraInicioCompra, 'DD/MM/YYYY HH:mm').isAfter()) {
                      horasList.push(sesion);
                    }
                  } else {
                    horasList.push(sesion);
                  }
                } else if (sesion.Disponible !== '0') {
                  if (this.sesiones[i].Disponible !== '0') {
                    if (sesion.Fecha === moment().format('DD/MM/YYYY')) {
                      if (moment(sesion.HoraInicioCompra, 'DD/MM/YYYY HH:mm').isAfter()) {
                        horasList.push(sesion);
                      }
                    } else {
                      horasList.push(sesion);
                    }
                  } else if (this.sesiones[i].Disponible === '0') {
                    if (sesion.Fecha === moment().format('DD/MM/YYYY')) {
                      if (moment(sesion.HoraInicioCompra, 'DD/MM/YYYY HH:mm').isAfter()) {
                        sesion.Disponible = '0';
                        horasList.push(sesion);
                      }
                    } else {
                      sesion.Disponible = '0';
                      horasList.push(sesion);
                    }
                  }
                }
              }
          }
        });  
        return horasList;
    }
  }

  comprobarComentario(sesion) {
    if (sesion.Comentarios !== 'SNOW' && sesion.Comentarios !== 'ESQUI') {
      return sesion;
    } else if (sesion.Comentarios === this.productosService.filtroTipoValue) {
      return sesion;
    }
  }

  getSesionesFromProductosProfesor(grupoInternetId) {
    this.grupoInternetId = '' + grupoInternetId;
    let guias = [];
    Object.keys(this.productosService.productosValue).forEach(producto => {
      this.productosService.guardarProfesoresValue.forEach(guia => {
        let sesiones = [];
        guia.Sesiones.forEach(s => {
          const recinto = this.productosService.recintosTodosValue.find(recin => recin.IdRecinto === s.Recinto);
          const sesion = {
            IdRecinto: s.Recinto,
            NombreRecinto: recinto.NombreRecinto,
            TipoAforo: recinto.TipoAforo,
            ControlaGrupo: '1',
            Disponible: '1',
            Fecha: moment(s.Fecha).format('DD/MM/YYYY'),
            HoraFin: '0',
            HoraInicio: s.Hora,
            RecintosSesionId: '0',
            ProductoId: this.productosService.productosValue[producto].ProductoId,
            Comentarios: s.Observaciones,
            Profesores: ''
          };
          sesiones = sesiones.concat(sesion);
        });
        const sesionesConMin = this.minVentaControl(sesiones, this.productosService.getEnlaceMenuId());
        const sesionesConMinMax = this.maxVentaControl(sesionesConMin, this.productosService.getEnlaceMenuId());
        const guiaa = {
          nombre: guia.NombreGuia,
          iGuiaId: guia.iGuiaId,
          ObservacionesGuia: guia.ObservacionesGuia,
          sesiones: sesionesConMinMax
        };
        guias.push(guiaa);
      });
    });
    this.setSesionesProfesor(guias);
    return guias;
  }

  getSesionesPorDiaYProductoProfesor(dia, producto) {
    const idProducto = producto.ProductoId;
    let horasList = [];
    const arrayProfesores = [];
    this.sesionesProfesor.forEach((guia: any) => {
      horasList = [];
      guia.sesiones.forEach((sesion: any) => {
        if (sesion.Fecha === dia && sesion.ProductoId === idProducto) {
          if (this.comprobarComentario(sesion)) {
            if (dia === moment().format('DD/MM/YYYY')) {
              if (moment(sesion.HoraInicioCompra, 'DD/MM/YYYY HH:mm').isAfter()) {
                horasList.push(sesion);
              }
            } else {
              horasList.push(sesion);
            }
          }
        }
      });
      const profeSesion = { guia, horasList }
      arrayProfesores.push(profeSesion);
    });
    return arrayProfesores;

  }
  //YS 20220719 modificamos el calculo de HoraInicioCompra a fecha completa
  minVentaControl(sesiones, enlaceMenu) {
    const tiempos: ControlTiempos = this.parseEnlaceMenu(enlaceMenu);
    let sesionesFilter;
    if (tiempos.chTiempoVentaMinTipo && tiempos.chTiempoVentaMinTipo === 'dias' || tiempos.chTiempoVentaMinTipo === 'meses') {
      let diasDesde = tiempos.iTiempoVentaMin - 1;
      if (tiempos.chTiempoVentaMinTipo === 'meses') {
        diasDesde = tiempos.iTiempoVentaMin * 30;
      }
      sesionesFilter = sesiones.filter(s => moment(s.Fecha, 'DD/MM/YYYY').isAfter(moment().add(diasDesde, 'days')));
      sesionesFilter.map(s => {
        if (!s.HoraInicioCompra) {
          s.HoraInicioCompra = s.Fecha + ' ' + s.HoraInicio;
        }
        return s;
      });
      return sesionesFilter;
    } else if (tiempos.chTiempoVentaMinTipo && tiempos.chTiempoVentaMinTipo === 'horas') {
      return sesiones.map(s => {
        const hora = moment(s.Fecha + ' ' + s.HoraInicio, 'DD/MM/YYYY HH:mm');
        hora.subtract(tiempos.iTiempoVentaMin, 'hour');
        const HoraInicioCompra = hora.format('DD/MM/YYYY HH:mm');
        return { ...s, HoraInicioCompra };
      });
    } else if (tiempos.chTiempoVentaMinTipo && tiempos.chTiempoVentaMinTipo === 'minutos') {
      return sesiones.map(s => {
        const hora = moment(s.Fecha + ' ' + s.HoraInicio, 'DD/MM/YYYY HH:mm');
        hora.subtract(tiempos.iTiempoVentaMin, 'minute');
        const HoraInicioCompra = hora.format('DD/MM/YYYY HH:mm');
        return { ...s, HoraInicioCompra };
      });
    } else {
      return sesiones.map(s => {
        if (!s.HoraInicioCompra) {
          s.HoraInicioCompra = s.Fecha + ' ' + s.HoraInicio;
        }
        return s;
      });
    }
  }

  minVentaControlSD(sesiones, enlaceMenu) {
    const tiempos: ControlTiempos = this.parseEnlaceMenu(enlaceMenu);
    let sesionesFilter;
    if (tiempos.chTiempoVentaMinTipo && tiempos.chTiempoVentaMinTipo === 'horas') {
      return sesiones.map(s => {
        const hora = moment(s.Fecha + ' ' + s.HoraFin, 'DD/MM/YYYY HH:mm');
        hora.subtract(tiempos.iTiempoVentaMin, 'hour');
        const HoraFinCompra = hora.format('DD/MM/YYYY HH:mm');
        return { ...s, HoraFinCompra };
      });
    } else if (tiempos.chTiempoVentaMinTipo && tiempos.chTiempoVentaMinTipo === 'minutos') {
      return sesiones.map(s => {
        const hora = moment(s.Fecha + ' ' + s.HoraFin, 'DD/MM/YYYY HH:mm');
        hora.subtract(tiempos.iTiempoVentaMin, 'minute');
        const HoraFinCompra = hora.format('DD/MM/YYYY HH:mm');
        return { ...s, HoraFinCompra };
      });
    } else {
      return sesiones.map(s => {
        if (!s.HoraFinCompra) {
          s.HoraFinCompra = s.Fecha + ' ' + s.HoraFin;
        }
        return s;
      });
    }
  }

  maxVentaControl(sesiones, enlaceMenu) {
    const tiempos: ControlTiempos = this.parseEnlaceMenu(enlaceMenu);
    let sesionesFilter;
    if (tiempos.chTiempoVentaMaxTipo && tiempos.chTiempoVentaMaxTipo === 'dias' || tiempos.chTiempoVentaMaxTipo === 'meses') {
      let diasDesde = tiempos.iTiempoVentaMax;
      if (tiempos.chTiempoVentaMaxTipo === 'meses') {
        diasDesde = tiempos.iTiempoVentaMax * 30;
      }
      sesionesFilter = sesiones.filter(s => moment(s.Fecha, 'DD/MM/YYYY').isBefore(moment().add(diasDesde, 'days')));
      sesionesFilter.map(s => {
        if (!s.HoraInicioCompra) {
          s.HoraInicioCompra = s.Fecha + ' ' + s.HoraInicio;
        }
        return s;
      });
      return sesionesFilter;
    } else if (tiempos.chTiempoVentaMaxTipo && tiempos.chTiempoVentaMaxTipo === 'horas') {
      return sesionesFilter.map(s => {
        const hora = moment(s.Fecha + ' ' + s.HoraInicio, 'DD/MM/YYYY HH:mm');
        hora.add(tiempos.iTiempoVentaMax, 'hour');
        const HoraInicioCompra = hora.format('DD/MM/YYYY HH:mm');
        return { ...s, HoraInicioCompra };
      });
    } else if (tiempos.chTiempoVentaMaxTipo && tiempos.chTiempoVentaMaxTipo === 'minutos') {
      return sesiones.map(s => {
        const hora = moment(s.Fecha + ' ' + s.HoraInicio, 'DD/MM/YYYY HH:mm');
        hora.subtract(tiempos.iTiempoVentaMax, 'minute');
        const HoraInicioCompra = hora.format('DD/MM/YYYY HH:mm');
        return { ...s, HoraInicioCompra };
      });
    } else {
      return sesiones.map(s => {
        if (!s.HoraInicioCompra) {
          s.HoraInicioCompra = s.Fecha + ' ' + s.HoraInicio;
        }
        return s;
      });
    }
  }
  minVentaControlCalendario(sesiones, enlaceMenu) {
    const tiempos: ControlTiempos = this.parseEnlaceMenu(enlaceMenu);
    let sesionesFilter;
    if (tiempos.chTiempoVentaMinTipo && tiempos.chTiempoVentaMinTipo === 'dias' || tiempos.chTiempoVentaMinTipo === 'meses') {
      let diasDesde = tiempos.iTiempoVentaMin - 1;
      if (tiempos.chTiempoVentaMinTipo === 'meses') {
        diasDesde = tiempos.iTiempoVentaMin * 30;
      }
      sesionesFilter = sesiones.filter(s => moment(s.fecha, 'DD/MM/YYYY').isAfter(moment().add(diasDesde, 'days')));
      return sesionesFilter;
    } else {
      return sesiones;
    }
  }

  maxVentaControlCalendario(sesiones, enlaceMenu) {
    const tiempos: ControlTiempos = this.parseEnlaceMenu(enlaceMenu);
    let sesionesFilter;
    if (tiempos.chTiempoVentaMaxTipo && tiempos.chTiempoVentaMaxTipo === 'dias' || tiempos.chTiempoVentaMaxTipo === 'meses') {
      let diasDesde = tiempos.iTiempoVentaMax;
      if (tiempos.chTiempoVentaMaxTipo === 'meses') {
        diasDesde = tiempos.iTiempoVentaMax * 30;
      }
      sesionesFilter = sesiones.filter(s => moment(s.fecha, 'DD/MM/YYYY').isBefore(moment().add(diasDesde, 'days')));
      return sesionesFilter;
    } else {
      return sesiones;
    }
  }

  parseEnlaceMenu(enlaceMenu) {
    const controlTiempo: ControlTiempos = {
      iTiempoReservaMax: +enlaceMenu.iTiempoReservaMax,
      iTiempoReservaMin: +enlaceMenu.iTiempoReservaMin,
      iTiempoVentaMax: +enlaceMenu.iTiempoVentaMax,
      iTiempoVentaMin: +enlaceMenu.iTiempoVentaMin,
      chTiempoReservaMaxTipo: enlaceMenu.chTiempoReservaMaxTipo,
      chTiempoReservaMinTipo: enlaceMenu.chTiempoReservaMinTipo,
      chTiempoVentaMaxTipo: enlaceMenu.chTiempoVentaMaxTipo,
      chTiempoVentaMinTipo: enlaceMenu.chTiempoVentaMinTipo
    };
    return controlTiempo;
  }

  maxDiasVerVentaCalendario(enlaceMenu, fechaActual, fecha ){

   const tiempos: ControlTiempos = this.parseEnlaceMenu(enlaceMenu);
   let fechaMaxima = moment(fechaActual);
   if(tiempos.iTiempoVentaMax && tiempos.iTiempoVentaMax !== 0){
    switch (tiempos.chTiempoVentaMaxTipo) {
      case 'dias': 
      fechaMaxima = fechaMaxima.add(tiempos.iTiempoVentaMax,'days');
        break;
      case 'meses':
        fechaMaxima = fechaMaxima.add(tiempos.iTiempoVentaMax,'months');
          break; 
      case 'horas':
        fechaMaxima = fechaMaxima.add(tiempos.iTiempoVentaMax,'hours');
        break;
      default: 
      fechaMaxima = fechaActual;    
        break;
    }
    return (fechaMaxima > fecha && (fechaMaxima.year() > fecha.year() || fechaMaxima.month() > fecha.month()));
  }
    return true;

  }

  minDiasVerVentaCalendario(enlaceMenu,fechaActual, fecha ){
    const tiempos: ControlTiempos = this.parseEnlaceMenu(enlaceMenu);
   let fechaMinima = moment(fechaActual);
   if(tiempos.iTiempoVentaMin && tiempos.iTiempoVentaMin !== 0){
    switch (tiempos.chTiempoVentaMinTipo) {
      case 'dias': 
      fechaMinima = fechaMinima.add(tiempos.iTiempoVentaMin,'days');
        break;
        case 'meses':
          fechaMinima = fechaMinima.add(tiempos.iTiempoVentaMin,'months');
          break;
          case 'horas':
            fechaMinima = fechaMinima.add(tiempos.iTiempoVentaMin,'hours');
            break;
    
      default:  
      fechaMinima = fechaActual;
        break;
    }
    return fechaMinima < fecha && (fechaMinima.year() < fecha.year() || fechaMinima.month() < fecha.month());
  }
  return true;
}
  
  // Recopilamos y Preparamos los datos necesarios
  validaReservas(listaCarrito,fechaSeleccionadaSinSessionValue) {
    let datos = [];
    let enlacesMenuEnLista = [];
    let sessions = [];
    let maxmin = [];
    //let listaCarrito = this.ventaService.ordenarCarrito();
    listaCarrito.forEach((grupo, i) => {
      grupo.forEach(item => {
        let posicionVentaAnterior = item.posicionVenta;     
        if(this.ventaService.fechaSeleccionadaSinSessionValue && this.ventaService.fechaSeleccionadaSinSessionValue.length > 0){
          this.ventaService.fechaSeleccionadaSinSessionValue.forEach(element => {
            if(element.posicionVenta == posicionVentaAnterior && item.producto.ProductoId == element.productoId)
            {
              element.posicionVenta = i + 1;
            }
          });
        }
		    item.posicionVenta = i + 1;
        this.ventaService.guardarFechaSeleccionadasSinSesion();
        if (!enlacesMenuEnLista.includes(item.producto.enlaceMenu)) {
          enlacesMenuEnLista.push(item.producto.enlaceMenu);
        }
        //enlacesMenuEnLista.push(item.producto.enlaceMenu);
      });
    });

    listaCarrito.forEach(posicionVenta => {
        //let posicionVenta of listaCarrito
    
        let encontrado = false;
      
        posicionVenta.forEach(carrito =>{
          let nombreGrupo = carrito.producto.NombreGrupo;
          if(!encontrado && carrito.sesionesSeleccionadas && carrito.sesionesSeleccionadas.length > 0){
            encontrado = true;
            datos.push(carrito.sesionesSeleccionadas);

            carrito.sesionesSeleccionadas.forEach(sesionSeleccionada => {
              sessions.push({"fecha":sesionSeleccionada.Fecha,"hora":sesionSeleccionada.HoraInicio,"NombreGrupo":nombreGrupo});
            })
          }
        });

        if(!encontrado){
          let fecha = fechaSeleccionadaSinSessionValue && fechaSeleccionadaSinSessionValue.length > 0  && fechaSeleccionadaSinSessionValue.find(find=> find.posicionVenta == posicionVenta[0].posicionVenta && find.productoId == posicionVenta[0].producto.ProductoId)?  
          moment(fechaSeleccionadaSinSessionValue.find(find=> find.posicionVenta == posicionVenta[0].posicionVenta && find.productoId == posicionVenta[0].producto.ProductoId).fecha, 'DD/MM/YYYY').format('DD/MM/YYYY') 
          : "";
          if(fecha && fecha != "")
            sessions.push({"fecha":fecha,"hora":"","NombreGrupo":""})
        }
    }); 

    //Obtenemos la información de maximos y mínimos de los enlaces implicados
    let maxmindata;
    enlacesMenuEnLista.forEach(enlace => {
      maxmindata = this.productosService.enlacesMenuValue.find(enlacedata => enlacedata.pkid == enlace);
      if (maxmindata && (maxmindata.iTiempoReservaMax>0 || maxmindata.iTiempoReservaMin>0))
        maxmin.push({"TiempoReservaMaxTipo":maxmindata.chTiempoReservaMaxTipo,"TiempoReservaMinTipo":maxmindata.chTiempoReservaMinTipo,"TiempoReservaMax":maxmindata.iTiempoReservaMax,"TiempoReservaMin":maxmindata.iTiempoReservaMin});
    }); 


     return this.noSePuedenReservar(maxmin,sessions);
    //return sessions;
  }

  // averiguamos si alguna sesión no se puede reservar en la información del carrito enviada
  noSePuedenReservar(maxmin, sessions) {
    // para cada elemento de maxmin mirar si hay alguna sesion de sessions que incumple.

    /*
    //para cada elemento maxmin comprobar que no se incumple casa elemento de sessions

    Ejemplo de elemento maxmin
    0: 
        TiempoReservaMax: "12"
        TiempoReservaMaxTipo: "meses"
        TiempoReservaMin: "5"
        TiempoReservaMinTipo: "dias"
    

    Ejemplo de elemento sessions
    0: 
        fecha: "12/10/2022"
        hora: ""
    */

    let nopuede = false; //a alguna session no se puede reservar
    maxmin.forEach(maxminelem => {
      let reservamin_minutos = this.pasarAminutos(maxminelem.TiempoReservaMinTipo,maxminelem.TiempoReservaMin);
      let reservamax_minutos = this.pasarAminutos(maxminelem.TiempoReservaMaxTipo,maxminelem.TiempoReservaMax);
      sessions.forEach(s => {
        let minutosparalasesion = this.getMinutestoSession(s.fecha, s.hora);
          if (minutosparalasesion<reservamin_minutos && reservamin_minutos>0) 
          {
            nopuede = true;
          }
          if (minutosparalasesion>reservamax_minutos && reservamax_minutos>0) {
            nopuede = true;
          }
      })
    });
    return nopuede;
  }

  //obtenemos dias hasta la fecha hora de la sesion
  getDiastoSession(fecha, hora) {
    var today = new Date();
    var lafechaStr = fecha;
    if (hora!='') lafechaStr +=" "+hora;
    var a = moment(today);
    if (hora!='') 
    var b = moment(lafechaStr,'DD/MM/YYYY HH:mm');
    else 
      var b = moment(lafechaStr,'DD/MM/YYYY');
    
    return b.diff(a, 'days');
  }
  


  //obtenemos minutos hasta la fecha/hora
  getMinutestoSession(fecha, hora) {
    var today = new Date();
    var lafechaStr = fecha;
    if (hora!='') lafechaStr +=" "+hora;
    var a = moment(today);
    if (hora!='') 
    var b = moment(lafechaStr,'DD/MM/YYYY HH:mm');
    else 
      var b = moment(lafechaStr,'DD/MM/YYYY');
    
    return b.diff(a, 'minutes');
  }

  //obtenemos minutos según tipo de tiempo y cantidad
  pasarAminutos(tipo, cantidad) {
    let minutos = 0;
    switch (tipo) {
      case "minutos":
        minutos = Number(cantidad);
      break;
      case "horas":
        minutos = Number(cantidad)*60;
      break;
      case "dias":
        minutos = Number(cantidad)*24*60;
      break;
      case "meses":
        minutos = Number(cantidad)*30*24*60;
      break;
      default:
        minutos = 0;
      break;
    }
    return minutos;
  }

}
