import { Pipe, PipeTransform } from '@angular/core';

const PADDING = '000000';

@Pipe({
  name: 'myCurrency'
})
export class MyCurrencyPipe implements PipeTransform {

  private DECIMAL_SEPARATOR: string;
  private FRACTION_SIZE: number;

  constructor() {
    this.DECIMAL_SEPARATOR = ',';
    this.FRACTION_SIZE = 2;
  }

  transform(value: number | string, separadorDecimal, numeroDecimales): string {
    this.DECIMAL_SEPARATOR = separadorDecimal;
    this.FRACTION_SIZE = numeroDecimales;
    if (value === undefined) {
      return '';
    }
    if (typeof value === 'string') {
      value = Number.parseFloat(value.replace(',', '.'));
    }
    // tslint:disable-next-line:prefer-const
    let [integer, fraction = ''] = (value || '0').toString().split('.');

    fraction = this.FRACTION_SIZE > 0
      ? this.DECIMAL_SEPARATOR + (fraction + PADDING).substring(0, this.FRACTION_SIZE)
      : '';

    return integer + fraction;
  }
}