import { Component, OnInit, ElementRef, Input, OnDestroy, } from '@angular/core';
import { ModalService } from '../modal.service';
import { InitService } from 'src/app/core/init/init.service';
import { AppConfiguration } from 'src/app/app.configuration';
import { ProductosService } from 'src/app/business/productos.service';
import { takeWhile } from 'rxjs/internal/operators/takeWhile';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '../translate.service';
import { ConfigurationService } from 'src/app/core/config/configuration.service';

@Component({
  selector: 'app-modal-cookies',
  templateUrl: './modal-cookies.component.html',
  styleUrls: ['./modal-cookies.component.scss']
})
export class ModalCookiesComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();

  check = false;
  cookies;
  avisoLegal;
  politicaCookies;
  hidden = false;
  cargado;
  selectIndex = [];
  cookiesExterno;
  @Input() id: string;

  private element: any;

  constructor(
    private productosService: ProductosService,
    private initService: InitService,
    private modalService: ModalService,
    private el: ElementRef,
    private config: AppConfiguration,
    private translateService: TranslateService,
    private configurationService: ConfigurationService,
    ) { this.element = el.nativeElement; }

  ngOnInit(): void {
    this.configurationService.configuration$.subscribe(
      config =>{
        if(config && config != undefined && config != null && config.datosProyecto)
        {
          this.cookiesExterno =  this.configurationService.getCaracteristicasAdicionalesProyecto().CookiesExterno;
        }
      });
     this.initService.modalCookies$.pipe(takeUntil(this.ngUnsubscribe)).pipe(takeWhile(() => !this.cargado)).subscribe(cargado => {
      this.cargado = cargado;
  
      if(this.cookiesExterno && this.cookiesExterno != undefined && this.cookiesExterno != null && this.cookiesExterno == 1 ){
        sessionStorage.setItem('cookiesExterno', 'true');
        //this.getCookiesExterno();
      }else if(!sessionStorage.getItem('cookiesExterno')){
      if (this.cargado) {
          if (this.productosService.noEsAdministracion()) {
            if (!sessionStorage.getItem('cookies')) {
              this.element.style.display = 'block';
              this.initService.cookies$.subscribe(cookies => this.cookies = cookies);
              this.cookies = this.initService.cookiesValue;
            } else {
              this.getCookies(JSON.parse(sessionStorage.getItem('cookies')));
            }
          } else if (!this.productosService.noEsAdministracion()) {
            if (!sessionStorage.getItem('cookies')) {
              this.initService.cookies$.subscribe(cookies => this.cookies = cookies);
              this.confirmar();
            } else {
              this.getCookies(JSON.parse(sessionStorage.getItem('cookies')));
            }
          }
        }
      }
    });
    const modal = this;
    if (!this.id) {
      //console.error('modal must have an id');
      return;
    }
    if(this.cookiesExterno == undefined || this.cookiesExterno == null || this.cookiesExterno != 1){
    document.body.appendChild(this.element);
    // tslint:disable-next-line:only-arrow-functions
    this.element.addEventListener('click', function (e: any) {
      if (e.target.className === 'jw-modal') {
        modal.close();
      }
    });
    this.modalService.add(this);
  }
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  open(flag?): void {
    if(flag){
      /* this.initService.cookies$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(cookies=>{
        this.cookies = cookies
        this.check=true
      }) */
     // this.getCookiesExterno()
    }
    this.element.style.display = 'block';
    document.body.classList.add('jw-modal-open');
  }

  close(): void {
    this.element.style.display = 'none';
    document.body.classList.remove('jw-modal-open');
  }

  confirmar() {
   
    if (this.cookies) {
      this.cookies.forEach(element => {
        element.aceptar = true;
      });
      sessionStorage.setItem('cookies', JSON.stringify(this.cookies));
      if (this.productosService.noEsAdministracion())
      sessionStorage.setItem('aceptaCookies', 'true');
      this.getCookies(this.cookies);
      this.initService.setAceptaCookieGoolge(true);
    }
    this.close();
  }

  cancelarConfigurar() {
    this.check = true;
  }

  aceptarSeleccion() {
    if (this.cookies) {
      sessionStorage.setItem('cookies', JSON.stringify(this.cookies));
      sessionStorage.setItem('aceptaCookies', 'true');
      this.checkScripts();
      this.getCookies(this.cookies);
    }
    this.close();
  }

  checkBoxChange(item) {
    const i = this.cookies.find(el => el.Nombre === item.Nombre);
    if (i.bNecesario !== '1') {
      i.aceptar = !i.aceptar;
    }
  }

  getCookies(cookies) {     
    cookies.forEach(cookie => {
      if (cookie.aceptar) {
        if (cookie.chScript !== '') {
          const script = JSON.parse(cookie.chScript);
          let scriptF;
          script.forEach((s: any) => { 
            if(s.noscript) {
              const nos = document.getElementsByTagName('noscript');              
              const content = document.createTextNode(s.text);
              nos[0].appendChild(content);              
            } else {
              scriptF = document.createElement('script');
              if (s.async) {
                scriptF.async = s.async;
              }
              if (s.src) {
                scriptF.src = s.src;
              }
              if (s.type) {
                scriptF.type = s.type;
              }
              if (s.text) {
                scriptF.text = s.text
              }
              document.head.appendChild(scriptF);
            }           
          });
        }
      }
    });
  }
  
  checkScripts(){
    this.cookies.forEach(cookie => {
      if (cookie.chScript !== '') {
        let scripts = document.getElementsByClassName(cookie.pkId)
        Array.from(scripts).forEach(element => {
          element.remove()
        });

      }
    });
  }
  showContent(index) {
    this.selectIndex[index] = !this.selectIndex[index];
  }

  getCookiesExterno(){
    this.initService.getCookies().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data=>{
      if(data.DatosResult && data.DatosResult.ListadoCookies.length>0){
        data.DatosResult.ListadoCookies.sort((a, b) => a.iOrdenVisualizacion - b.iOrdenVisualizacion).map(dataScript=>{
          if(dataScript.chScript && dataScript.chScript.length>0) {
            const script = JSON.parse(dataScript.chScript);
                  script.forEach((s: any) => {
                    const scriptF = document.createElement('script');
                    if (s.async) {
                      scriptF.async = s.async;
                    }
                    if (s.src) {
                      scriptF.src = s.src;
                    }
                    if (s.type) {
                      scriptF.type = s.type;
                    }
                    if (s.text) {
                      scriptF.text = s.text;
                    }
                    if(s.charset)
                    {
                      scriptF.charset = s.charset;
                    }
                    if(s.dataDocumentLanguage){
                      scriptF.setAttribute('data-document-languaje',s.dataDocumentLanguage);
                    }
                    if(s.dataDomainScript)
                    {
                      scriptF.setAttribute('data-domain-script',s.dataDomainScript);
                    }
        
                    document.head.appendChild(scriptF);
                  });
                }
        })
      }
    });    
  }
}


