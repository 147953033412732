import { Injectable, Inject } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '../../shared/translate.service';


@Injectable({
  providedIn: 'root'
})
export class IndexService {

  constructor(
    @Inject(DOCUMENT) private _document: HTMLDocument,
    private titleService: Title,
    private metaService: Meta,
    private translateService: TranslateService

  ) { }
  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  loadIndex(proyecto) {
    switch (proyecto.chClaveEmpresa) {
      case 'SNOWZONE':
        this.setTitle('Snowzone Tickets, Compra de Entradas Oficiales - Snowzone');
        this._document.getElementById('appFavicon').setAttribute('href', 'assets/img/SNOWZONE/favicon.png');
        this._document.getElementById('canonicalLink').setAttribute('href', 'https://tickets.madridsnowzone.com');
        this.metaService.updateTag(
          {
            name: 'description',
            content: 'Accede a Snowzone Tickets, el sitio oficial de venta de entradas de Snowzone y disfruta esquiando en la pista de nieve cubierta de Madrid ¡Cómpralas ya!'
          }
        );
        break;

      case 'PEDRERA':
        this.setTitle("Món Sant Benet Tickets, Compra d'Entrades Oficials - Món Sant Benet");
        this._document.getElementById('appFavicon').setAttribute('href', 'assets/img/PEDRERA/logosymbol_dark.png');
        this.metaService.updateTag(
          {
            name: 'description',
            content: 'Accede a Món Sant Benet Tickets, el sitio oficial de venta de entradas de Món Sant Benet ¡Cómpralas ya!'
          }
        );
        break;
        case 'MNDELTA':
          this.setTitle("Món Natura Delta de l'Ebre, Compra d'Entrades Oficials - Món Natura Delta de l'Ebre");
          this._document.getElementById('appFavicon').setAttribute('href', 'assets/img/MNDELTA/favicon_mn.png');
          this.metaService.updateTag(
            {
              name: 'description',
              content: "Accede a Món natura Delta de l'Ebre, el sitio oficial de venta de entradas de Món natura Delta de l'Ebre ¡Cómpralas ya!"
            }
          );
          break;
          case 'MNPIRINEUS':
          this.setTitle("Món Natura Pirineus , Compra d'Entrades Oficials - Món Natura Pirineus ");
          this._document.getElementById('appFavicon').setAttribute('href', 'assets/img/MNPIRINEUS/favicon_mn.png');
          this.metaService.updateTag(
            {
              name: 'description',
              content: "Accede a Món Natura Pirineus , el sitio oficial de venta de entradas de Món Natura Pirineus  ¡Cómpralas ya!"
            }
          );
          break;  
          case 'CMREBEI':
          this.setTitle("Congost de Mont-rebei , Compra d'Entrades Oficials - Congost de Mont-rebei ");
          this._document.getElementById('appFavicon').setAttribute('href', 'assets/img/CMREBEI/favicon_mn.png');
          this.metaService.updateTag(
            {
              name: 'description',
              content: "Accede a Congost de Mont-rebei , el sitio oficial de venta de entradas de Congost de Mont-rebei  ¡Cómpralas ya!"
            }
          );
          break;        
          case 'BIELSA':
            this.setTitle("La valle Bielsa Tickets, venta oficial de entradas - La valle Bielsa");
            this._document.getElementById('appFavicon').setAttribute('href', 'assets/img/BIELSA/favicon.ico');
            this.metaService.updateTag(
              {
                name: 'description',
                content: "Accede a La valle Bielsa, Tickets, el Sitio oficial de venta de entradas de la La valle Bielsa ¡Cómpralas ya!."
              }
            );
            break;        
          case 'REALESCUELA':
            this.setTitle("Tickets, venta oficial de entradas - Real Escuela Ecuestre de Jerez");
            this._document.getElementById('appFavicon').setAttribute('href', 'assets/img/REALESCUELA/favicon.png');
            this.metaService.updateTag(
              {
                name: 'description',
                content: "Accede a la Real Escuela Ecuestre de Jerez, Tickets, el Sitio oficial de venta de entradas ¡Cómpralas ya!."
              }
            );
            break;             
        case 'MAPFRE':
          this.setTitle("Fundación MAPFRE, venta oficial de entradas - Fundación MAPFRE");
          this._document.getElementById('appFavicon').setAttribute('href', 'assets/img/MAPFRE/favicon.ico');
          //TODO comentar para mapfre la linea de abajo controlar con cookie externo
          //this.getCookies();
          this.metaService.updateTag(
            {
              name: 'description',
              content: 'Accede a Fundación MAPFRE Tickets, el Sitio oficial de venta de entradas de la Fundación MAPFRE ¡Cómpralas ya!'
            }
          );
          break;

        case 'GRANADACARD':
          this.setTitle("GRANADA CARD, venta oficial de entradas");
          this._document.getElementById('appFavicon').setAttribute('href', 'assets/img/GRANADACARD/favicon.ico');
          //TODO comentar para mapfre la linea de abajo controlar con cookie externo
          //this.getCookies();
          this.metaService.updateTag(
            {
              name: 'description',
              content: 'Accede a GRANADA CARD Tickets, el Sitio oficial de venta de entradas de GRANADA CARD ¡Cómpralas ya!'
            }
          );
          break;
        
        case 'ESTEPONAEULEN':
          this.setTitle("Tickets, venta oficial de entradas - Servicio de Cultura - Ayuntamiento de Málaga");
          this._document.getElementById('appFavicon').setAttribute('href', 'assets/img/ESTEPONAEULEN/favicon.png');
          this.metaService.updateTag(
            {
              name: 'description',
              content: "Accede a EulenART Servicio de Cultura - Ayuntamiento de Málaga, Tickets, el Sitio oficial de venta de entradas ¡Cómpralas ya!."
            }
          );
          break;   

        case 'AYTO_ZGZ_FORO':
        case 'AYTO_ZGZ_TEATRO':
        case 'AYTO_ZGZ_PUERTO':
        case 'AYTO_ZGZ_TERMAS':
        case 'AYTO_ZGZ_GARG':
          this.setTitle("Tickets, venta oficial de entradas - Museo del Foro de Caesaraugusta");
          this._document.getElementById('appFavicon').setAttribute('href', 'assets/img/'+ proyecto.chClaveEmpresa +'/favicon.png');
          this.metaService.updateTag(
            {
              name: 'description',
              content: "Accede al Museo del Foro de Caesaraugusta - Ayuntamiento de Zaragoza, Tickets, el Sitio oficial de venta de entradas ¡Cómpralas ya!."
            }
          );
          break;      
        case 'FMCMP_Salamanca':
          this.setTitle("Tickets, venta oficial de entradas - Servicio de Cultura - Ayuntamiento de Salamanca");
          this._document.getElementById('appFavicon').setAttribute('href', 'assets/img/FMCMP_Salamanca/favicon.ico');
          this.metaService.updateTag(
            {
              name: 'description',
              content: "Accede a Fundación María Cristina Masaveu Peterson - Ayuntamiento de Salamanca, Tickets, el Sitio oficial de venta de entradas ¡Cómpralas ya!."
            }
          );
          break;      
        case 'FMCMP_Madrid':
          this.setTitle("Tickets, venta oficial de entradas - Servicio de Cultura - Ayuntamiento de Madrid");
          this._document.getElementById('appFavicon').setAttribute('href', 'assets/img/FMCMP_Madrid/favicon.ico');
          this.metaService.updateTag(
            {
              name: 'description',
              content: "Accede a Fundación María Cristina Masaveu Peterson - Ayuntamiento de Madrid, Tickets, el Sitio oficial de venta de entradas ¡Cómpralas ya!."
            }
          );
          break;      
    }
  }

  getCookies() {
   let cookies =[[{ "type":"text/javascript", "src":"https://cdn.cookielaw.org/consent/beb9469f-3e56-4cc6-9719-ccfd26b784a7/OtAutoBlock.js" },{"src":"https://cdn.cookielaw.org/scripttemplates/otSDKStub.js", "dataDocumentLanguage":"true", "type":"text/javascript", "charset":"UTF-8", "dataDomainScript":"beb9469f-3e56-4cc6-9719-ccfd26b784a7" },{"type":"text/javascript", "text":"function OptanonWrapper() { }" }],
   [{ "type": "text/javascript", "async": true, "src": "https://www.google-analytics.com/plugins/ua/linkid.js" },{ "type": "text/javascript", "async": true, "src": "https://www.google-analytics.com/analytics.js" },{ "async": true, "src": "https://www.googletagmanager.com/gtag/js?id=GTM-MQCRX76" },{ "text": "(function (w, d, s, l, i) { w[l] = w[l] || []; w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' }); var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.className = '3' ; j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f); })(window, document, 'script', 'dataLayer', 'GTM-MQCRX76');" }]]
   //{ "text": "window.dataLayer = window.dataLayer || []; function gtag() { dataLayer.push(arguments); } gtag('js', new Date()); gtag('config', 'GTM-MQCRX76');" } 
   cookies.forEach((cookie:any) => {
      if (cookie) {
        if (cookie !== '') {
          const script = cookie;
          script.forEach((s: any) => {
            const scriptF = document.createElement('script');
            if (s.async) {
              scriptF.async = s.async;
            }
            if (s.src) {
              scriptF.src = s.src;
            }
            if (s.type) {
              scriptF.type = s.type;
            }
            if (s.text) {
              scriptF.text = s.text;
            }
            if(s.charset)
            {
              scriptF.charset = s.charset;
            }
            if(s.dataDocumentLanguage){
              scriptF.setAttribute('data-document-languaje',s.dataDocumentLanguage);
            }
            if(s.dataDomainScript)
            {
              scriptF.setAttribute('data-domain-script',s.dataDomainScript);
            }

            document.head.appendChild(scriptF);
          });
        }
      }
    });
  }

}