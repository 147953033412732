import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '../user.service';
import { ToastrService } from 'ngx-toastr';
import { ProductosService } from 'src/app/business/productos.service';
import { TokenManagerService } from 'src/app/core/token/token-manager.service';
import { concatMap, map, take } from 'rxjs/operators';
import { InitService } from 'src/app/core/init/init.service';
import { TranslateService } from 'src/app/shared/translate.service';
import { ModalService } from 'src/app/shared/modal.service';
import { forkJoin, of, Subscription } from 'rxjs';
import { AppConfiguration } from 'src/app/app.configuration';
import { VentaService } from 'src/app/core/services/venta.service';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {
  loading: boolean;
  isLoading: boolean;
  email;
  nombre;
  telefono;
  datosUsuario$;
  editForm: FormGroup;
  user;
  provincias;
  paises;
  nifRegex = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKE]$/i;
  proyecto = this.config.getProyecto();
  tiposClientes: any;
  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private router: Router,
    private toast: ToastrService,
    private productosService: ProductosService,
    private tokenManager: TokenManagerService,
    private initService: InitService,
    private translateService: TranslateService,
    private modalService: ModalService,
    private config: AppConfiguration,
    private ventaService: VentaService,
    private _cdr: ChangeDetectorRef


  ) { }

  ngOnInit() {
    this.isLoading = false;
    this.loadData();
    // if (!this.initService.startedValue) {

    //   this.initService.getInitData().pipe(take(1)).subscribe(res => {

    //     this.loadData()
    //   })
    // } else {
    //   this.loadData()
    // }
    this.initService.traerDatosInicio(false);
  }

  loadData() {
    this.tokenManager.currentConexionId$.pipe(take(1)).subscribe(conexion => {
      if (conexion) { 
        forkJoin([
          this.productosService.GetProvincias(this.translateService.getLocale()),
          this.productosService.GetPaises(this.translateService.getLocale()),
          this.productosService.GetTipoClientes(this.translateService.getLocale())
        ]
        ).subscribe(([res1, res2,res3]) => {
          this.provincias = res1.DatosListas ? res1.DatosListas : []
          this.paises = res2.DatosListas ? res2.DatosListas : []
          this.tiposClientes= res3.DatosListas ? res3.DatosListas : []
          this.isLoading = true;
          this._cdr.detectChanges();

        });
        /* this.getProvincias()
        this.getPaises() */

        this.user = JSON.parse(sessionStorage.getItem('currentUser'));
        this.editForm = this.formBuilder.group({
          tipoClienteEdit: [this.user.TipoClienteId],
          EMail: [this.user.EMail, [Validators.required, Validators.pattern('^[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_\`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$')]],
          NombreClienteOriginal: [this.user.NombreClienteOriginal, [Validators.required, Validators.maxLength(255)]],
          PrimerApellidoCliente: [this.user.PrimerApellidoCliente, [Validators.required]],
          SegundoApellidoCliente: [this.user.SegundoApellidoCliente],
          codigopostal: [this.user.CP ? this.user.CP : this.user.codigopostal, [Validators.required]],

          // NombreClienteOriginal: [this.user.NombreClienteOriginal , [Validators.required, Validators.maxLength(255), Validators.pattern('^[A-zÀ-ÿ\\s]*$')]],
          // TipoDocumento: [this.user.TipoDocumento, [Validators.required]],
          //CIFoDNI: [this.user.CIFoDNI, [Validators.required,this.validar]],
          //Provincia: [this.user.Provincia, [Validators.required]],
          //Pais: [this.user.Pais, [Validators.required]],
          //telefono: [this.user.Telefono, [Validators.required]],
          //contrasena: ['', [Validators.required]],
          privacidad: [false, [Validators.requiredTrue]],
          PublicidadInterna: [this.user.PublicidadInterna == "1" ? true : false],

        });
        this.isLoading = true;
        //this.tokenManager.currentConexionId$.unsubscribe():
      }
    })
  }

  validar(control: AbstractControl): { [key: string]: boolean } | null {
    let value = control.value
    var validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
    var nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;
    var nieRexp = /^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;
    var str = value.toString().toUpperCase();

    if (!nifRexp.test(str) && !nieRexp.test(str)) return { DniValidate: true };

    var nie = str
      .replace(/^[X]/, '0')
      .replace(/^[Y]/, '1')
      .replace(/^[Z]/, '2');

    var letter = str.substr(-1);
    var charIndex = parseInt(nie.substr(0, 8)) % 23;


    if (validChars.charAt(charIndex) === letter) return null;
    if (value == "") return { DniValidate: true };

    return { DniValidate: true };
  }

  openMoreInfo() {
    this.modalService.open("moreInformation")
  }

  get f() {
    return this.editForm.controls;
  }

  getProvincias() {
    this.productosService.GetProvincias(this.translateService.getLocale()).subscribe((res:any) => {
      this.provincias = res.DatosListas ? res.DatosListas : []
      this.isLoading = true
      return
    })
  }

  getPaises() {
    this.productosService.GetPaises(this.translateService.getLocale()).subscribe((res:any) => {
      this.paises = res.DatosListas ? res.DatosListas : []
      this.isLoading = true
    })
  }

  editClicked() {
    const param = 2;
    const verificarRegistro = 0;
    this.loading = false;
    if (this.editForm.invalid) {
      return;
    }
    
    this.userService.registro(this.editForm.value, param, "my-account", this.editForm.get("PublicidadInterna").value,this.translateService.getLocale(), verificarRegistro)
    .pipe(concatMap((result:any) =>{
        if (result.DatosResult && result.DatosResult != null && result.DatosResult.Avisos && result.DatosResult.Avisos != null) {
          this.toast.success(this.translateService.data.modificadoCorrectamente, '', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
          this.loading = false;

          return this.userService.updateCliente(this.user.CodigoCliente).pipe(map((res: any) => {
            if (res && res.DatosResult) {
              this.userService.setDatosUserValue(res.DatosResult);
              this.userService.setCurrentUserValue(res.DatosResult);
              this.ventaService.mapCliente(res.DatosResult);
            }}));
        }else {
          //TODO devolver un OK cuando falle, no evaluar los errores   20220628
          this.toast.error(this.userService.getErrorTranslate(result["Mensajes"][0]["CodigoMensaje"]), 'Error', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
          });
          return of(null);
        }
      }))
      .subscribe(
        // res => {
        // if (res.DatosResult && res.DatosResult != null && res.DatosResult.Avisos && res.DatosResult.Avisos != null) {
        //  /*  this.userService.loginUser = {
        //     NombreCliente: this.editForm.controls.EMail.value,
        //     Contrasena: JSON.parse(sessionStorage.getItem('currentUser')).Contrasena,
        //     NombreClienteOriginal: this.editForm.controls.NombreClienteOriginal.value,
        //     //Telefono: this.editForm.controls.telefono.value
        //   }; */

        //   this.userService.setCurrentUserValue(this.editForm.value); 

        //   this.toast.success(this.translateService.data.modificadoCorrectamente, '', {
        //     timeOut: 3000,
        //     positionClass: 'toast-top-right',
        //   });
        // } else {
        //   this.toast.error(this.getErrorTranslate(res["Mensajes"][0]["CodigoMensaje"]), 'Error', {
        //     timeOut: 3000,
        //     positionClass: 'toast-top-right',
        //   });
        // }
        // this.loading = false;
      // }
      );
  }

  irCambiarContrasena() {
    this.router.navigate(['/account/reset-password']);
  }
}


