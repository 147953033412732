import { Injectable } from '@angular/core';
import * as moment from 'moment';

export interface Litmit { time: number; unit: string; };

@Injectable({
  providedIn: 'root'
})

export class GlobalService {
  clienteapi: any = { Id: '1', Token: 'aaa', EnlaceMenu: 'a' };
  idAplicacion: number = 19; //19 = CUSTOMMAN
  iniciarLogPass: string = 'CUSTO$A$SZ45$A$MMAN$A$23g!.';
  divisapordefectoSistema = "2";
  maxBotonesRapidosTPV = 50; //72
  //Constantes Identificadas

  Permisos: Array<any> = [];
  arr_nivlelesEntrada: Array<any> = [
    { id: '1', value: 'NORMAL' },
    { id: '2', value: 'REDUCIDA' },
    { id: '3', value: 'GRATUITA' },
    { id: '4', value: 'NORMAL IDA Y VUELTA' },
    { id: '5', value: 'REDUCIDA IDA Y VUELTA' },
    { id: '6', value: 'GRATUITA IDA Y VUELTA' }
  ];

  diasSemana: Array<any> = [
    { id: '1', value: 'Lunes' },
    { id: '2', value: 'Martes' },
    { id: '3', value: 'Miércoles' },
    { id: '4', value: 'Jueves' },
    { id: '5', value: 'Viernes' },
    { id: '6', value: 'Sábado' },
    { id: '7', value: 'Domingo' }
  ];

  tiposAforo: Array<any> = [
    { id: 'SF', value: 'Sesiones Fijas' },
    { id: 'SI', value: 'Sesiones Irregulares' },
    { id: 'SD', value: 'Sesiones Diarias' }/*,
    { id: 'PI', value: 'Sesiones por Intervalo' },
    { id: 'SP', value: 'Sesiones Privadas' }
    */
  ];

  plataformasVenta: Array<any> = [
    { id: '1', value: 'Internet' },
    { id: '2', value: 'Punto Venta KORE' }
  ]; //, { id: '3', value: "Taquilla Automática" }

  tipoProductoAsociadoTarifa: Array<any> = [{ id: '1', value: 'Entrada' }]; // , { id: '2', value: "Promoción" }, { id: '3', value: "Abonado" }
  impresorasSeleccionables: Array<any> = [
    { id: '0', value: 'Primaria' },
    { id: '1', value: 'Secundaria' },
    { id: '2', value: 'Ambas' }
  ];


  //Pasar un Valor string a boolean
  aBooleano(valor) {
    if (typeof valor === 'string') {
      if (
        valor.toLowerCase().trim() === 'false' ||
        valor.toLowerCase().trim() === '0' ||
        valor.toLowerCase().trim() === ''
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      if (typeof valor === 'boolean') {
        return valor;
      } else {
        if (typeof valor === 'number') {
          if (valor > 0) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    }
  }
  anadirCeroHoraLeft(hora: string, largo?: number): string {
    let respuesta: string = '';
    if (largo == null) largo = 8;
    while (hora.length + respuesta.length < largo) {
      respuesta = respuesta + '0';
    }
    return respuesta + hora;
  }

  aFecha(fecha: string): Date {
    // 
    // 
    if (fecha == null || fecha.length == 0) {
      return null;
    } else {
      var parts = fecha.split("/");
      return new Date(parseInt(parts[2].substring(0, 4)), parseInt(parts[1]) - 1, parseInt(parts[0]));
    }
  }
  //Pasar un Valor string a Bit (0/1)
  aBit(valor) {
    if (typeof valor === 'string') {
      if (
        valor.toLowerCase().trim() === 'false' ||
        valor.toLowerCase().trim() === '0' ||
        valor.toLowerCase().trim() === ''
      ) {
        return 0;
      } else {
        return 1;
      }
    } else {
      if (typeof valor === 'boolean') {
        if (valor) {
          return 1;
        } else {
          return 0;
        }
      } else {
        if (typeof valor === 'number') {
          if (valor > 0) {
            return 1;
          } else {
            return 0;
          }
        } else {
          return 0;
        }
      }
    }
  }

  /** 
   * Method that returns whether the date of an event is in a valid time
   * hour = Event time
   * limit = Object containing the values of iTiempoVentaMin and chTiempoVentaMinTipo
  **/
  public validateHours = (hour: string, limit: Litmit) : boolean => {
    const now = moment(moment(), 'HH:mm');
    const hourEvent = moment(hour, 'HH:mm');
    let subtract = moment.duration(now.diff(hourEvent));
    let hoursDiff = subtract.get('hours');
    let minutesDiff = subtract.get('minutes');   
    if(Object.keys(limit).length === 0) {
      return hoursDiff <= 0;
    } else {      
      if(limit.time < 0) {
        if(hoursDiff < 0){
          return true;
        }        
        limit.time = Math.abs(limit.time);
        if(limit.unit === 'horas' && hoursDiff > limit.time){
          return false;
        }
        if(limit.unit === 'minutos' && hoursDiff === 0 && minutesDiff > limit.time){
          return false;
        }         
      } else {
        if(hoursDiff > 0){
          return false;
        }     
        hoursDiff = Math.abs(hoursDiff);
        minutesDiff = Math.abs(minutesDiff);
        if(limit.unit === 'horas' && hoursDiff < limit.time){
          return false;
        }
        if(limit.unit === 'minutos' && hoursDiff === 0 && minutesDiff <= limit.time){
          return false;
        }
      }
      return true;                   
    }    
  }


  public VerificarDNI = (dni_original: string) : boolean => {
    let dni_calculado: string;
    const regex = /^[0-9]*$/;
  
  //Ponemos la letra en mayúscula
    dni_original = dni_original.toUpperCase();
    //Comprobamos que el DNI tenga 9 caracteres (8 números + 1 letra)
    if (dni_original.length == 9) {
      //Quitamos el último caracter dando por hecho que sea la letra
      dni_calculado = dni_original.substr(0,dni_original.length - 1);

      //Comprobamos que el resultado que nos queda sea solo el número
      if (regex.test(dni_calculado) === true) {
          //Calculamos el DNI completo
          dni_calculado = this.CalcularDNI_NIE_NIF(dni_calculado) //Calculamos la letra del DNI para comparar con la que tenemos
      } else {
        return false;
      }
          
      //Comprobamos que el DNI original sea igual al DNI obtenido
      if (dni_original === dni_calculado) {
        return true;  
      } else{
        return false;
      }

    }else
    {      
      return false;
    }
    
  }


  public VerificarNIE = (nie_original: string) : boolean => {
        let nie_calculado: string
        const regex = /^[0-9]*$/;       

        //Ponemos las letras en mayúscula
        nie_original = nie_original.toUpperCase();        

        //Comprobamos que el NIE tenga 9 caracteres (1 letra + 7 números + 1 letra)
        if (nie_original.length === 9) {
            //Comprobamos que el primer caracter sea la letra X, Y o Z
            if (nie_original.substr(0, 1) === "X" || nie_original.substr(0, 1) === "Y" || nie_original.substr(0, 1) === "Z") {
                //Sustituimos la letra por un número
                if (nie_original.substr(0, 1) === "X") {
                  nie_original = "0" + nie_original.substr(1)
                }

                if (nie_original.substr(0, 1) === "Y") {
                  nie_original = "1" + nie_original.substr(1)
                }

                if (nie_original.substr(0, 1) === "Z") {
                  nie_original = "2" + nie_original.substr(1)
                }

                //Quitamos el último caracter dando por hecho que sea la letra
                nie_calculado = nie_original.substr(0, nie_original.length - 1)

                //Comprobamos que el resultado que nos queda sea solo el número
                if (regex.test(nie_calculado) === true) {
                    //Calculamos el DNI completo
                    nie_calculado = this.CalcularDNI_NIE_NIF(nie_calculado) //Calculamos la letra del DNI para comparar con la que tenemos
                }
                else{
                  return false
                }

                //Comprobamos que el NIE original sea igual al NIE obtenido
                if (nie_original === nie_calculado) {
                  return true
                }                  
                else{
                  return false
                }
            }
            else{
              return false
            }   
        }
        else{
          return false
        }                    
  }

  public VerificarCIF = (cif_original: string) : boolean => {
    const regex = /^[0-9]*$/;   
    let digitos: string
    let letras_validas: string
    let letras_control: string
    let sumaPares: number
    let sumaTotal: number
    let impar1: number
    let impar2: number
    let impar3: number
    let impar4: number
    let digitoResultado: number
    let control1: string
    let control2: string
    
    letras_validas = "ABCDEFGHJNPQRSUVW";
    letras_control = "JABCDEFGHI";
    sumaPares = 0;
    sumaTotal = 0;
    impar1 = 0;
    impar2 = 0;
    impar3 = 0;
    impar4 = 0;
    digitoResultado = 0;
    control1 = "";
    control2 = "";

    //Ponemos las letras en mayúscula
    cif_original = cif_original.toUpperCase()

    //Comprobamos que el CIF tenga 9 caracteres (1 letra + 7 números + 1 letra   /   1 letra + 8 números)
    if (cif_original.length === 9) {
      //Comprobamos que el primer caracter sea una letra válida
      if (letras_validas.includes(cif_original.substr(0, 1)) === true) {
          //Comprobamos que los 7 siguientes carácteres sean números
          if (regex.test(cif_original.substr(1, 7)) === true) {
              //Cogemos los 7 dígitos
              digitos = cif_original.substr(1, 7);

              //Sumamos los dígitos de las posiciones pares
              sumaPares = parseInt(digitos.substr(1, 1)) + parseInt(digitos.substr(3, 1)) + parseInt(digitos.substr(5, 1));
              
              //Los digitos de las posiciones impares, los multiplicamos por 2 y sumamos los dígitos de su resultado
              impar1 = parseInt(digitos.substr(0, 1)) * 2;
              impar2 = parseInt(digitos.substr(2, 1)) * 2;
              impar3 = parseInt(digitos.substr(4, 1)) * 2;
              impar4 = parseInt(digitos.substr(6, 1)) * 2;

              if (impar1 > 9) {
                impar1 = parseInt((impar1.toString()).substr(0, 1)) + parseInt((impar1.toString()).substr(1, 1));
              }
              if (impar2 > 9) {
                impar2 = parseInt((impar2.toString()).substr(0, 1)) + parseInt((impar2.toString()).substr(1, 1));
              }
              if (impar3 > 9) {
                impar3 = parseInt((impar3.toString()).substr(0, 1)) + parseInt((impar3.toString()).substr(1, 1));
              }
              if (impar4 > 9) {
                impar4 = parseInt((impar4.toString()).substr(0, 1)) + parseInt((impar4.toString()).substr(1, 1));
              }

              //Sumamos todos los resultados
              sumaTotal = sumaPares + impar1 + impar2 + impar3 + impar4;

              //Nos quedamos con las unidades del resultado (último dígito)
              if (sumaTotal > 9){
                  digitoResultado = parseInt((sumaTotal.toString()).substr(1, 1));
              }
              else{
                  digitoResultado = sumaTotal;
              }

              //Restamos el dígito resultante a 10 si es diferente de 0, si es 0, será 0
              if (digitoResultado !== 0) {
                digitoResultado = 10 - digitoResultado;
              }

              //Comprobamos si el dígito de control debe ser una letra o un número
              if (digitos.substr(0, 2) === "00") {
                  //No residentes -> El control es una letra
                  control1 = letras_control.substr(digitoResultado, 1);
                  control2 = letras_control.substr(digitoResultado, 1);
              }
              else{
                  //Residentes -> Comprobamos la letra inicial
                  switch (cif_original.substr(0, 1)) {
                      case "P":
                      case "Q": 
                      case "S": 
                      case "W":
                          //Control es una letra
                          control1 = letras_control.substr(digitoResultado, 1);
                          control2 = letras_control.substr(digitoResultado, 1);
                          break;                      
                      case "A":
                      case "B":
                      case "E": 
                      case "H":
                          //Control es un número
                          control1 = (digitoResultado.toString());
                          control2 = (digitoResultado.toString());
                          break;                      
                      default:{
                          //Puede ser letra o número (pongo letra)
                          control1 = (digitoResultado.toString());
                          control2 = letras_control.substr(digitoResultado, 1);
                          break;
                      }
                  }
              }
              //Comprobamos que el CIF original sea igual al CIF obtenido             

              if (cif_original === (cif_original.substr(0, 1) + digitos.toString() + control1.toString()) || cif_original === (cif_original.substr(0, 1) + digitos.toString() + control2.toString())) {
                  return true
              }
              else{
                  return false
              }
          }
          else{
            return false
          }
      }
      else{
        return false
      }
    }
    else{
        return false
    }      

  }







  public CalcularDNI_NIE_NIF(numerosDNI: string):string {    
    const regex = /^[0-9]*$/;
    let a, b, c, NIF : number;
    let letra: string;
    let letras: string;
  
    letras = "TRWAGMYFPDXBNJZSQVHLCKE";
    
    //Comprobamos que solo recibamos números
    if (regex.test(numerosDNI) === false) {
      return "--ERROR--"
    }
            
    //Comprobamos que solo recibamos 8 carácteres
    if (numerosDNI.length !== 8) {
      return "--ERROR--"
    }
  
    //Calculamos la letra
    a = 0
    NIF = parseInt(numerosDNI);
    
    do
      {
        b = (NIF / 24)
        b = parseInt(b)
        c = NIF - (24 * b)
        a = a + c
        NIF = b
      } 
    while (b !== 0);

    b = (a / 23)
    b = parseInt(b)
    c = a - (23 * b)

    letra = letras.substr(parseInt(c), 1)
   
    return numerosDNI + letra ; 
  }
  



  
  //fin

}
