import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, take } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ConfigurationService } from '../core/config/configuration.service';
import { VentaService } from '../core/services/venta.service';
import * as moment from 'moment';
import 'moment/locale/es';
import { AppConfiguration } from '../app.configuration';
import { TokenInit } from '../models/token-init.model';
import { TranslateService } from '../shared/translate.service';


@Injectable({
  providedIn: 'root'
})
export class ProductosService {
  user;
  password;
  tipoDescuento;
  descuento;
  minDia;
  maxDia;
  enlacesMenu;
  gruposInternet;
  p;
  recintos;
  tieneSesiones = false;
  muestraSesionesPorProducto = false;
  estadosButacas = [];
  showDays: [] = [];

  private guardarProfesoresSubject: BehaviorSubject<any>;
  public guardarProfesores$: Observable<any>;

  private recintosTodosSubject: BehaviorSubject<any>;
  public recintosTodos$: Observable<any>;

  private datosInitSubject: BehaviorSubject<any>;
  public datosInit$: Observable<any>;

  private cargandoSubject: BehaviorSubject<any>;
  public cargando$: Observable<any>;

  private cargandoComponenteSubject: BehaviorSubject<any>;
  public cargandoComponente$: Observable<any>;

  private cargandoComponente2Subject: BehaviorSubject<any>;
  public cargandoComponente2$: Observable<any>;

  private otrosProductosSubject: BehaviorSubject<any>;
  public otrosProductos$: Observable<any>;

  private sinCamposSubject: BehaviorSubject<any>;
  public sinCampos$: Observable<any>;

  private filtroDiasSubject: BehaviorSubject<any>;
  public filtroDias$: Observable<any>;

  private filtroTipoSubject: BehaviorSubject<any>;
  public filtroTipo$: Observable<any>;

  private filtroGrupoSubject: BehaviorSubject<any>;
  public filtroGrupo$: Observable<any>;

  private filtroNivelSubject: BehaviorSubject<any>;
  public filtroNivel$: Observable<any>;

  //Yaribel 20221006   Añadimos nuevo filtro de idiomas
  private filtroIdiomaVisitaSubject: BehaviorSubject<any>;
  public filtroIdiomaVisita$: Observable<any>;

  private nombreGrupoSubject: BehaviorSubject<any>;
  public nombreGrupo$: Observable<any>;

  private nombreGrupoMenuSubject: BehaviorSubject<any>;
  public nombreGrupoMenu$: Observable<any>;

  private productosMenuSubject: BehaviorSubject<any>;
  public productosMenu$: Observable<any>;

  private productosHeaderSubject: BehaviorSubject<any>;
  public productosHeader$: Observable<any>;

  private productosSubject: BehaviorSubject<any>;
  public productos$: Observable<any>;

  private productosIntSubject: BehaviorSubject<any>;
  public productosInt$: Observable<any>;

  private enlacesMenuSubject: BehaviorSubject<any>;
  public enlaceMenu$: Observable<any>;

  private infoGrupoSubject: BehaviorSubject<any>;
  public infoGrupo$: Observable<any>;

  todosProductos: any;
  productosList: any;

  private mostrarProductosSubject: BehaviorSubject<any>;
  public mostrarProductos$: Observable<any>;

  private mostrarElegirProfesorSubject: BehaviorSubject<any>;
  public mostrarElegirProfesor$: Observable<any>;

  private tieneUserSubject: BehaviorSubject<any>;
  public tieneUser$: Observable<any>;

  private listaNivelesSubject: BehaviorSubject<any>;
  public listaNiveles$: Observable<any>;

  private listaTrimestresSubject: BehaviorSubject<any>;
  public listaTrimestres$: Observable<any>;


  /*Añadimos observables para controlor los productos del catalogo*/
  private todosProductosCatSubject: BehaviorSubject<any>;
  public todosProductosCat$: Observable<any>;

  private productosCatSubject: BehaviorSubject<any>;
  public productosCat$: Observable<any>;

  private backupProdsSubject: BehaviorSubject<any>;
  public backupProds$: Observable<any>;
  /* */

    /* ESPECTACULOS Añadimos observables para controlor los espectaculos*/
    private todosEspectaculosCatSubject: BehaviorSubject<any>;
    public todosEspectaculosCat$: Observable<any>;
  
    private espectaculosCatSubject: BehaviorSubject<any>;
    public espectaculosCat$: Observable<any>;
  
    private backupEspectaculosSubject: BehaviorSubject<any>;
    public backupEspectaculos$: Observable<any>;

    private filtroProductosSubject: BehaviorSubject<any>;
    public filtroProductos$:Observable<any>;

    private fechaFiltroProductosSubject:BehaviorSubject<any>;
    public fechaFiltroProductos$:Observable<any>;

    private espectaculoSubject: BehaviorSubject<any>;
    public espectaculo$: Observable<any>;
    
    private grupoInternetSubject: BehaviorSubject<any>;
    public grupoInternet$: Observable<any>;
    
    private showHoursSubject: BehaviorSubject<any>;
    public showHours$: Observable<any>;
    
    private ventaEspectaculoSubject: BehaviorSubject<any>;
    public ventaEspectaculo$: Observable<any>;

    private dataRegistroSubject: BehaviorSubject<boolean>;
    public dataRegistro$: Observable<boolean>;

    private plataformaReservaSubject: BehaviorSubject<boolean>;
    public plataformaReserva$: Observable<boolean>;


  constructor(
    private http: HttpClient,
    private config: AppConfiguration,
    private configurationService: ConfigurationService,
    private ventaService: VentaService,
    private translate: TranslateService
  ) {

    this.guardarProfesoresSubject = new BehaviorSubject([]);
    this.guardarProfesores$ = this.guardarProfesoresSubject.asObservable();

    this.recintosTodosSubject = new BehaviorSubject<any>([]);
    this.recintosTodos$ = this.recintosTodosSubject.asObservable();

    this.tieneUserSubject = new BehaviorSubject<any>(false);
    this.tieneUser$ = this.tieneUserSubject.asObservable();

    this.infoGrupoSubject = new BehaviorSubject<any>(undefined);
    this.infoGrupo$ = this.infoGrupoSubject.asObservable();

    this.datosInitSubject = new BehaviorSubject<any>(undefined);
    this.datosInit$ = this.datosInitSubject.asObservable();

    this.cargandoSubject = new BehaviorSubject<any>(false);
    this.cargando$ = this.cargandoSubject.asObservable();

    this.cargandoComponenteSubject = new BehaviorSubject<any>(false);
    this.cargandoComponente$ = this.cargandoComponenteSubject.asObservable();

    this.cargandoComponente2Subject = new BehaviorSubject<any>(false);
    this.cargandoComponente2$ = this.cargandoComponente2Subject.asObservable();

    this.sinCamposSubject = new BehaviorSubject<any>(false);
    this.sinCampos$ = this.sinCamposSubject.asObservable();

    this.filtroDiasSubject = new BehaviorSubject<any>('');
    this.filtroDias$ = this.filtroDiasSubject.asObservable();

    this.filtroTipoSubject = new BehaviorSubject<any>('');
    this.filtroTipo$ = this.filtroTipoSubject.asObservable();

    this.filtroGrupoSubject = new BehaviorSubject<any>('');
    this.filtroGrupo$ = this.filtroGrupoSubject.asObservable();

    this.filtroNivelSubject = new BehaviorSubject<any>('');
    this.filtroNivel$ = this.filtroNivelSubject.asObservable();

    //Yaribel 20221006   Añadimos nuevo filtro de idiomas
    this.filtroIdiomaVisitaSubject = new BehaviorSubject<any>('');
    this.filtroIdiomaVisita$ = this.filtroIdiomaVisitaSubject.asObservable();

    this.productosSubject = new BehaviorSubject<any>([]);
    this.productos$ = this.productosSubject.asObservable();

    this.productosMenuSubject = new BehaviorSubject<any>([]);
    this.productosMenu$ = this.productosMenuSubject.asObservable();

    this.productosHeaderSubject = new BehaviorSubject<any>([]);
    this.productosHeader$ = this.productosMenuSubject.asObservable();

    this.productosIntSubject = new BehaviorSubject<any>([]);
    this.productosInt$ = this.productosIntSubject.asObservable();

    this.otrosProductosSubject = new BehaviorSubject<any>(undefined);
    this.otrosProductos$ = this.otrosProductosSubject.asObservable();

    this.nombreGrupoSubject = new BehaviorSubject<any>(undefined);
    this.nombreGrupo$ = this.nombreGrupoSubject.asObservable();

    this.nombreGrupoMenuSubject = new BehaviorSubject<any>(undefined);
    this.nombreGrupoMenu$ = this.nombreGrupoMenuSubject.asObservable();

    this.enlacesMenuSubject = new BehaviorSubject<any>([]);
    this.enlaceMenu$ = this.enlacesMenuSubject.asObservable();

    this.mostrarProductosSubject = new BehaviorSubject<any>(true);
    this.mostrarProductos$ = this.mostrarProductosSubject.asObservable();

    this.mostrarElegirProfesorSubject = new BehaviorSubject<any>(false);
    this.mostrarElegirProfesor$ = this.mostrarElegirProfesorSubject.asObservable();

    this.listaNivelesSubject = new BehaviorSubject<any>([]);
    this.listaNiveles$ = this.listaNivelesSubject.asObservable();

    this.listaTrimestresSubject = new BehaviorSubject<any>([]);
    this.listaTrimestres$ = this.listaNivelesSubject.asObservable();

    this.productosList = of([]);

    /*Añadimos observables para controlor los productos del catalogo*/
    this.todosProductosCatSubject = new BehaviorSubject<any>([]);
    this.todosProductosCat$ = this.todosProductosCatSubject.asObservable();

    this.productosCatSubject = new BehaviorSubject<any>([]);
    this.productosCat$ = this.productosCatSubject.asObservable();

    this.backupProdsSubject = new BehaviorSubject<any>([]);
    this.backupProds$ = this.backupProdsSubject.asObservable();
    /* */

    /* ESPECTACULOS Añadimos observables para controlor los espectaculos*/
    this.todosEspectaculosCatSubject = new BehaviorSubject<any>([]);
    this.todosEspectaculosCat$ = this.todosEspectaculosCatSubject.asObservable();

    this.espectaculosCatSubject = new BehaviorSubject<any>([]);
    this.espectaculosCat$ = this.espectaculosCatSubject.asObservable();

    this.backupEspectaculosSubject = new BehaviorSubject<any>([]);
    this.backupEspectaculos$ = this.backupEspectaculosSubject.asObservable();

    this.filtroProductosSubject = new BehaviorSubject<any>(undefined);
    this.filtroProductos$ = this.filtroProductosSubject.asObservable();

    this.fechaFiltroProductosSubject = new BehaviorSubject<any>(undefined);
    this.fechaFiltroProductos$ = this.fechaFiltroProductosSubject.asObservable();

    this.espectaculoSubject = new BehaviorSubject(undefined);
    this.espectaculo$ = this.espectaculoSubject.asObservable();

    this.grupoInternetSubject = new BehaviorSubject('TODOS');
    this.grupoInternet$ = this.grupoInternetSubject.asObservable();

    this.showHoursSubject = new BehaviorSubject([]);
    this.showHours$ = this.showHoursSubject.asObservable();

    this.ventaEspectaculoSubject = new BehaviorSubject(false);
    this.ventaEspectaculo$ = this.ventaEspectaculoSubject.asObservable();

    this.dataRegistroSubject = new BehaviorSubject(false);
    this.dataRegistro$ = this.dataRegistroSubject.asObservable();

    this.plataformaReservaSubject = new BehaviorSubject(false);
    this.plataformaReserva$ = this.plataformaReservaSubject.asObservable();
    
  }
  
  public setDataRegistro = () => {
    const enlaceMenu = this.getEnlaceMenuId();
    if(enlaceMenu && enlaceMenu.CaracteristicasAdicionales) {      
      this.ventaService.setEnlaceMenuCarrito(enlaceMenu); 
      const objCaracteristicas =  JSON.parse(enlaceMenu.CaracteristicasAdicionales);     
      const registro = objCaracteristicas.CaracteristicasAdicionales.DatosRegistro ? true : false;
      this.dataRegistroSubject.next(registro);
    }    
  }

  get plataformaReservaValue(){
    return this.plataformaReservaSubject.value;
  }

  public setPlataformaReserva(plataforma){
    this.plataformaReservaSubject.next(plataforma);
  }

  get todosProductosCatValue(){
    return this.todosProductosCatSubject.value;
  }

  public setTodosProductosCat(todos){
    this.todosProductosCatSubject.next(todos);
  }

  get productosCatValue(){
    return this.productosCatSubject.value;
  }

  public setProductosCat(productos){
    this.productosCatSubject.next(productos);
  }

  get backupProdsValue(){
    return this.backupProdsSubject.value;
  }

  public setBackupProds(todos){
    this.backupProdsSubject.next(todos);
  }
/* */

  get guardarProfesoresValue() {
    return this.guardarProfesoresSubject.value;
  }

  public setGuardarProfesores(sesiones) {
    this.guardarProfesoresSubject.next(sesiones);
  }

  get recintosTodosValue() {
    return this.recintosTodosSubject.value;
  }

  setRecintosTodos(recintos) {
    this.recintosTodosSubject.next(recintos);
  }

  get listaTrimestresValue() {
    return this.listaTrimestresSubject.value;
  }

  setListaTrimestres(listaTrimestres) {
    this.listaTrimestresSubject.next(listaTrimestres);
  }

  get listaNivelesValue() {
    return this.listaNivelesSubject.value;
  }

  setListaNiveles(listaNiveles) {
    this.listaNivelesSubject.next(listaNiveles);
  }

  public get infoGrupoValue() {
    return this.infoGrupoSubject.value;
  }

  setInfoGrupoValue(token) {
    this.infoGrupoSubject.next(token);
  }

  public get tieneUserValue() {
    return this.tieneUserSubject.value;
  }

  setTieneUserValue(bool) {
    this.tieneUserSubject.next(bool);
  }

  public get datosInitValue() {
    return this.datosInitSubject.value;
  }

  setDatosInitValue(token: TokenInit) {
    const tokenStringify = JSON.stringify(token);
    this.datosInitSubject.next(tokenStringify);
  }

  public get cargandoValue() {
    return this.cargandoSubject.value;
  }

  setCargandoValue(cargando) {
    if (cargando) {
      this.setMostrarProductosValue(false);
    } else {
      this.setMostrarProductosValue(true);
    }
    this.cargandoSubject.next(cargando);
  }

  public get cargandoComponenteValue() {
    return this.cargandoComponenteSubject.value;
  }

  setCargandoComponenteValue(cargando) {
    this.cargandoComponenteSubject.next(cargando);
  }

  public get cargandoComponente2Value() {
    return this.cargandoComponente2Subject.value;
  }

  setCargandoComponente2Value(cargando) {
    this.cargandoComponente2Subject.next(cargando);
  }

  public get filtroTipoValue() {
    return this.filtroTipoSubject.value;
  }

  setFiltroTipoValue(opcion) {
    this.filtroTipoSubject.next(opcion);
  }

  public get sinCamposValue() {
    return this.sinCamposSubject.value;
  }

  setSinCamposValue(opcion) {
    this.sinCamposSubject.next(opcion);
  }

  public get otrosProductosValue() {
    return this.otrosProductosSubject.value;
  }

  setOtrosProductosValue(opcion) {
    this.otrosProductosSubject.next(opcion);
  }

  public get filtroDiasValue() {
    return this.filtroDiasSubject.value;
  }

  setFiltroDiasValue(opcion) {
    this.filtroDiasSubject.next(opcion);
  }


  public get filtroGrupoValue() {
    return this.filtroGrupoSubject.value;
  }

  setFiltroGrupoValue(opcion) {
    this.filtroGrupoSubject.next(opcion);
  }

  public get filtroNivelValue() {
    return this.filtroNivelSubject.value;
  }

  setFiltroNivelValue(opcion) {
    this.filtroNivelSubject.next(opcion);
  }


  //Yaribel 20221006   Añadimos nuevo filtro de idiomas
  public get filtroIdiomaVisitaValue(){
    return this.filtroIdiomaVisitaSubject.value;
  } 
  setFiltroIdiomaVisitaValue(option){
    this.filtroIdiomaVisitaSubject.next(option);
  }

  public get enlacesMenuValue() {
    return this.enlacesMenuSubject.value;
  }

  setEnlacesMenuValue(enlaces) {
    this.enlacesMenuSubject.next(enlaces);
  }

  public get mostrarProductosValue() {
    return this.mostrarProductosSubject.value;
  }

  setMostrarProductosValue(valor) {
    this.mostrarProductosSubject.next(valor);
  }

  public get mostrarElegirProfesorValue() {
    return this.mostrarElegirProfesorSubject.value;
  }

  setMostrarElegirProfesorValue(valor) {
    this.mostrarElegirProfesorSubject.next(valor);
  }

  setProductosMenuValue(gi) {
    this.productosMenuSubject.next(gi);
  }

  public get productosMenuValue() {
    return this.productosMenuSubject.value;
  }

  setProductosHeaderValue(gi) {
    this.productosHeaderSubject.next(gi);
  }

  public get productosHeaderValue() {
    return this.productosHeaderSubject.value;
  }

  public get productosValue() {
    return this.productosSubject.value;
  }

  setProductosValue(productos, enlaceMenu, grupoInternet, tipoReserva, nombreGrupo) {
    if (productos) {
      if (!productos.GrupoInternetPsellId) {
        Object.keys(productos).map(object => {
          productos[object].enlaceMenu = enlaceMenu;
          productos[object].GrupoInternet = grupoInternet;
          productos[object].TipoReservaId = tipoReserva;
          productos[object].NombreGrupo = nombreGrupo;
        });
      }
      this.productosSubject.next(productos);
    } else {
      this.productosSubject.next({});
    }
  }

  public get productosIntValue() {
    return this.productosIntSubject.value;
  }

  setProductosIntValue(productos) {
    this.productosIntSubject.next(productos);
  }

  public get nombreGrupoValue() {
    return this.nombreGrupoSubject.value;
  }

  setnombreGrupoValue(nombre) {
    this.nombreGrupoSubject.next(nombre);
  }

  public get nombreGrupoMenuValue() {
    return this.nombreGrupoMenuSubject.value;
  }

  setnombreGrupoMenuValue(nombre) {
    this.nombreGrupoMenuSubject.next(nombre);
  }

  getEnlaceMenuId() {
    if (this.datosInitValue) {

      const datosUrl = JSON.parse(this.datosInitValue);
      return this.enlacesMenuValue.find(e => e.chNombre === datosUrl.m);
    }
  }
  getEnlaceMenuIdPorId(emId) {
    return this.enlacesMenuValue.find(e => e.pkid === emId);    
  }

  getEnlaceMenuPorNombre(nombre) {
    const enlaceMenu = this.enlacesMenuValue.find(e => e.chNombre === nombre);
    return enlaceMenu.pkid;
  }

  getEnlaceMenuNombre(emId) {
    const enlaceMenu = this.enlacesMenuValue.find(e => e.pkid === emId);
    return enlaceMenu.chNombre;
  }

  getGrupoInternet() {
    return JSON.parse(this.datosInitValue);
  }

  getUserPwd() {
    this.user = JSON.parse(sessionStorage.getItem('admin')) ? JSON.parse(sessionStorage.getItem('admin')).u : '';
    this.password = JSON.parse(sessionStorage.getItem('admin')) ? JSON.parse(sessionStorage.getItem('admin')).p : '';
  }

  getDescuento() {
    //Modificamos para que se obtenga el descuento del currentUser
    //const userReserva = this.ventaService.userReservaValue;
    const userReserva = JSON.parse(sessionStorage.getItem('currentUser')) || this.ventaService.userReservaValue;
    if (userReserva) {
      if (userReserva.Descuento === '1') {
        this.tipoDescuento = userReserva.TipoDto;
        this.descuento = userReserva.rDescuento;
      }
    } else {
      this.tipoDescuento = undefined;
      this.descuento = undefined;
    }
  }

  clienteCompraBody() {

    let currentUser = JSON.parse(sessionStorage.getItem('currentUser'));

    const body = {
      Nombre: this.user ? this.user : currentUser ? currentUser.EMail : "",
      Contrasena: this.password ? this.password : currentUser ? currentUser.Contrasena : "",
      NombreContacto: this.user ? this.user : currentUser ? currentUser.NombreClienteOriginal : "",
      PersonaPago: '',
      CIFoDNI: '',
      Telefono: '',
      Direccion: '',
      Ciudad: '',
      ProvinciaContacto: '',
      CodigoPostal: '',
      Pais: '',
      CodigoPais: '', //Yaribel 20210531 Añadimos codigo pais al objeto clente
      Email: '',
      Apellidos: '',
      Apellido2Contacto: '',
      TelefonoMovilContacto: '',
      TipoViaContacto: '',
      PisoContacto: '',
      Nacionalidad: '',
      PrefijoInternacional: '',
      Edad: 0,
      Sexo: '',
      otroEmail: '',
      TipoDescuento: this.tipoDescuento,
      Descuento: this.descuento,
    };
    return body;
  }

  //GET PRODUCTOS SIN SESIONES
  getProductos() {
    this.getUserPwd();
    this.getDescuento();
    let fHasta = moment();
    fHasta = fHasta.add(1, 'year');
    let fechaHasta = fHasta.format('YYYY/MM/DD');
    let fechaDesde = this.ventaService.fechaSeleccionadaValue.format('YYYY/MM/DD');
    if(this.fechaFiltroProductosValue)
    {
      fechaDesde = this.fechaFiltroProductosValue.format('YYYY/MM/DD');
      fechaHasta = this.fechaFiltroProductosValue.format('YYYY/MM/DD');
    }
    const body = {
      Idioma: this.configurationService.configurationValue.datosInicio.Idioma0,
      clienteCompra: this.clienteCompraBody(),
      EnlaceMenuId: this.getEnlaceMenuId()?this.getEnlaceMenuId().pkid:null,
      GruposInternet: this.getGrupoInternet()? this.getGrupoInternet().gi:null,
      Espectaculo: 0,
      GrupoRecinto: 0,
      Recinto: 0,
      Fecha: fechaDesde,
      Hora: '',
      FechaHasta: fechaHasta,
      HoraHasta: '',
      IncluirSesiones: 2,
      Plataforma: this.getPlataforma()
    };
    return this.http.post(`${this.config.getConfig('API_URL')}/api/Tarifas/ProductosDisponibles`, body).pipe(map((res: any) => {
      return res.DatosResult;
    }));
  }

  //GET PRODUCTOS DE UN DIA SIN SESIONES
  getProductosDia(fecha) {
    this.getUserPwd();
    this.getDescuento();
    const body = {
      Idioma: this.configurationService.configurationValue.datosInicio.Idioma0,
      clienteCompra: this.clienteCompraBody(),
      EnlaceMenuId: this.getEnlaceMenuId()?this.getEnlaceMenuId().pkid : null,
      GruposInternet: this.getGrupoInternet()?this.getGrupoInternet().gi : null,
      Espectaculo: 0,
      GrupoRecinto: 0,
      Recinto: 0,
      Fecha: fecha,
      Hora: '',
      FechaHasta: fecha,
      HoraHasta: '',
      IncluirSesiones: 2,
      Plataforma: this.getPlataforma(),
      filtro: {
        edad: this.filtroGrupoValue,
        nivel: this.filtroNivelValue,
        modalidad: this.filtroTipoValue
      }
    };
    return this.http.post(`${this.config.getConfig('API_URL')}/api/Tarifas/ProductosDisponibles`, body).pipe(map((res: any) => {
      return res.DatosResult;
    }));
  }

  //GET TODOS LOS PRODUCTOS DE TODOS ENLACES MENU SIN SESIONES(CARGAR CATALOGO)
  getTodosProductos(enlacesMenu) {
    this.getUserPwd();
    this.getDescuento();
    let fHasta = moment();
    fHasta = fHasta.add(1, 'year');
    let fechaHasta = fHasta.format('YYYY/MM/DD');
    let fechaDesde = this.ventaService.fechaSeleccionadaValue.format('YYYY/MM/DD');
    if(this.fechaFiltroProductosValue)
    {
      fechaDesde = this.fechaFiltroProductosValue.format('YYYY/MM/DD');
      fechaHasta = this.fechaFiltroProductosValue.format('YYYY/MM/DD');
    }
    const body = {
      Idioma: this.configurationService.configurationValue.datosInicio.Idioma0,
      clienteCompra: this.clienteCompraBody(),
      EnlaceMenuId: enlacesMenu,
      GruposInternet: '',
      Espectaculo: 0,
      GrupoRecinto: 0,
      Recinto: 0,
      Fecha: fechaDesde,
      Hora: '',
      FechaHasta: fechaHasta,
      HoraHasta: '',
      SalidaDict: 0,
      IncluirSesiones: 2,
      Plataforma: this.getPlataforma()
    };
    return this.http.post(`${this.config.getConfig('API_URL')}/api/Tarifas/ProductosDisponibles`, body).pipe(map((res: any) => {      
      return res.DatosResult;
    }));
  }

  //GET PRODUCTOS DE DETERMINADOS ENLACES MENÚ.
  getProductosMenu() {
    this.getUserPwd();
    this.getDescuento();
    let enlaceMenu;
    let gi;
    if (this.otrosProductosValue === 'EQUIPO') {
      enlaceMenu = '398';
      gi = '75, 81';
    } else {
      enlaceMenu = this.getEnlaceMenuPorNombre(this.otrosProductosValue);
      gi = '';
      if (enlaceMenu === '384') {
        enlaceMenu = '384, 394, 487';
      }
      if (enlaceMenu === '383') {
        enlaceMenu = '383, 1402, 498';
      }
      if (enlaceMenu === '431') {
        enlaceMenu = '431, 395, 488';
      }
      if (enlaceMenu === '430') {
        enlaceMenu = '430, 433, 499';
      }
    }
    let fHasta = moment();
    fHasta = fHasta.add(1, 'year');
    const fechaHasta = fHasta.format('YYYY/MM/DD');
    const body = {
      Idioma: this.configurationService.configurationValue.datosInicio.Idioma0,
      clienteCompra: this.clienteCompraBody(),
      EnlaceMenuId: enlaceMenu,
      GruposInternet: gi,
      Espectaculo: 0,
      GrupoRecinto: 0,
      Recinto: 0,
      Fecha: this.ventaService.fechaSeleccionadaValue.format('YYYY/MM/DD'),
      Hora: '',
      FechaHasta: fechaHasta,
      HoraHasta: '',
      IncluirSesiones: 2,
      Plataforma: this.getPlataforma()
    };
    return this.http.post(`${this.config.getConfig('API_URL')}/api/Tarifas/ProductosDisponibles`, body).pipe(map((res: any) => {
      return res.DatosResult;
    }));
  }

  getMaxFecha():any{
    let fecha = moment(moment(),"DD/MM/YYYY");
    this.ventaService.carritoValue.compras
      .forEach(compra => {
        if(compra.producto.ListTarifasfechasVigencia && compra.producto.ListTarifasfechasVigencia[0] && moment(compra.producto.ListTarifasfechasVigencia[0]["FechaFinTarifa"],"DD/MM/YYYY").isAfter(fecha)){
            fecha = moment(compra.producto.ListTarifasfechasVigencia[0]["FechaFinTarifa"],"DD/MM/YYYY");
        }
      });
      return fecha
  }
  getInterestProducts() {
    let gi;
    const items = [];
    this.ventaService.carritoValue.compras
      .forEach(compra => {
        if (compra.producto.GrupoInternet.toString() !== gi) {
          gi = compra.producto.GrupoInternet.toString();
          const item = { EnlaceMenuId:compra.producto.enlaceMenu,TipoProducto: '', ProductoId: '', GrupoInternetId: gi };
          items.push(item);
        }
      });
    const body = {
      GruposInternet: this.getGrupoInternet().gi,
      EnlaceMenuId: this.getEnlaceMenuId().pkid,
      Espectaculo: '0',
      GrupoRecinto: '0',
      Recinto: '0',
      Fecha: this.ventaService.fechaSeleccionadaValue.format('YYYY/MM/DD'),
      Hora: this.ventaService.fechaSeleccionadaValue.format('hh:mm:ss'),
      FechaHasta: this.getMaxFecha().format('YYYY/MM/DD'),
      HoraHasta: this.getMaxFecha().format('hh:mm:ss'),
      IncluirSesiones: 0,
      ConexionIacpos: '272',
      Idioma: this.configurationService.configurationValue.datosInicio.Idioma0,
      SesionInternetId: '',
      IdentificadorUnico: '', 
      Plataforma: this.getPlataforma(),
      CodigoValidacionUnico: this.configurationService.configurationValue.currentSession.sessionId,
      clienteCompra: {
        Nombre: 'INTERNET',
        Email: '',
        Contrasena: 'INTERNET',
        NombreContacto: 'INTERNET',
        CIFoDNI: '',
        Telefono: '',
        Direccion: '',
        Ciudad: '',
        ProvinciaContacto: '',
        CodigoPostal: '',
        Pais: '',
        CodigoPais: '', //Yaribel 20210531 Añadimos codigo pais al objeto clente
        Nacionalidad: '',
        PrefijoInternacional: '',
        otroEmail: '',
        Descuento: '0',
        TipoDescuento: 'DT',
        Apellidos: '',
        TelefonoMovilContacto: '',
        PersonaPago: '',
        Apellido2Contacto: '',
        TipoViaContacto: '',
        PisoContacto: '',
        Sexo: '',
        Edad: '0',
        Nota: 'null',
        PoliticaPrivacidad: 'null'
      },
      Items: items
    };
    return this.http.post(`${this.config.getConfig('API_URL')}/api/GetTarifasProductosRelacionadosDisponibles`, body).pipe(map((res: any) => {
      if (res.DatosResult) {
        return res.DatosResult.ProductosRelacionadosCross;
      }
    }));
  }

  GetTiposProductos(option) {
    const body = {
      Filtro: option,
    };
    return this.http.post(`${this.config.getConfig('API_URL')}/api/Configuracion/GetTiposProductos`, body).pipe(map((res: any) => {
      return res.DatosResult;
    }));
  }

  GetGruposAsociados(option) {
    const body = {
      Filtro: option,
    };
    return this.http.post(`${this.config.getConfig('API_URL')}/api/Configuracion/GetGruposAsociados`, body).pipe(map((res: any) => {
      return res.DatosResult;
    }));
  }

  GetTiposNiveles(option) {
    const body = {
      Filtro: option,
      Idioma: this.configurationService.configurationValue.datosInicio.Idioma0
    };
    return this.http.post(`${this.config.getConfig('API_URL')}/api/Configuracion/GetTiposNiveles`, body).pipe(map((res: any) => {
      this.setListaNiveles(res.DatosResult.ListadoTiposNiveles);
      return res.DatosResult;
    }));
  }

  getNivelCodigoByNivelNombre(nombre: string) {
    const nivel = this.listaNivelesValue.find(n => n.Nombre === nombre);
    if (nivel) {
      return nivel.Codigo;
    }
  }

  getNivelNombreByNivelCodigo(codigo: string) {
    const nivel = this.listaNivelesValue.find(n => n.Codigo === codigo);
    return nivel.Nombre;
  }

  getTrimestrePkIdByTrimestreNombre(nombre: string) {
    const trimestre = this.listaTrimestresValue.find(t => t.Nombre === nombre);
    if (trimestre) {
      return trimestre.Codigo;
    }
  }

  getTrimestreNombreByTrimestrePkId(pkId: string) {
    const trimestre = this.listaTrimestresValue.find(t => t.Codigo === pkId);
    if (trimestre) {
      return trimestre.Nombre;
    } else {
      return pkId;
    }
  }

  reservaAforo(sesion, numero?) {
    const listadoDeProductos = [];
    this.ventaService.compraValue.productos.forEach(producto => {
      const prod = producto.producto.TipoProducto + ',' + producto.producto.ProductoId + ',' + producto.cantidad;
      listadoDeProductos.push(prod);
    });
    const body = {
      IdentificadorUnico: this.configurationService.configurationValue.currentSession.sessionId,
      IdSesion: sesion.RecintosSesionId,
      listadoProductos: listadoDeProductos,
      Recinto: sesion.IdRecinto,
      FechaHora: moment(sesion.Fecha + ' ' + sesion.HoraInicio, 'DD/MM/YYYY HH:mm').format('YYYY/MM/DD HH:mm:ss'),
      Plataforma: this.getPlataforma(),
      PosicionVenta: this.calcPosicionVenta(),
      NumeroDia: numero ? numero : null
    };
    return this.http.post(`${this.config.getConfig('API_URL')}/api/ReservaAforoProductos`, body).pipe(map((res: any) => {
      return res;
    }));
  }

  reservaAforoModificarReserva(sesion, listadoDeProductos, posicionVenta, numero?) {
    const body = {
      IdentificadorUnico: this.configurationService.configurationValue.currentSession.sessionId,
      IdSesion: sesion.RecintosSesionId,
      listadoProductos: listadoDeProductos,
      Recinto: sesion.IdRecinto,
      FechaHora: moment(sesion.Fecha + ' ' + sesion.HoraInicio, 'DD/MM/YYYY HH:mm').format('YYYY/MM/DD HH:mm:ss'),
      Plataforma: this.getPlataforma(),
      PosicionVenta: posicionVenta,
      NumeroDia: numero ? numero : null,
      EsModificacionGrupo: '1'
    };
    return this.http.post(`${this.config.getConfig('API_URL')}/api/ReservaAforoProductos`, body).pipe(map((res: any) => {
      return { sesion, res };
    }));
  }


  reservaAforoCarrito() {
    const arrayProductos = [];
    this.ventaService.carritoValue.compras.forEach(prod => {
      if (prod.sesionesSeleccionadas) {
        if ((arrayProductos.filter(p => prod.posicionVenta === p.prod.posicionVenta && prod.producto.ProductoId === p.prod.producto.ProductoId)).length === 0) {
          const cantidad = this.ventaService.carritoValue.compras
            .filter(p => prod.posicionVenta === p.posicionVenta && prod.producto.ProductoId === p.producto.ProductoId)
            .map(p => p.cantidad)
            .reduce((p, c) => c)
          arrayProductos.push({ cantidad, prod });
        }
      }
    });
    arrayProductos.forEach(producto => {
      if (producto.prod.sesionesSeleccionadas) {
        producto.prod.sesionesSeleccionadas.forEach((sesion, i) => {
          if (producto.prod.sesionesSeleccionadas.length > 1 &&
            producto.prod.sesionesSeleccionadas.length === (producto.prod.sesionesSeleccionadas.filter(s => s.IdRecinto === sesion.IdRecinto)).length) {
            this.llamadaAforoCarrito(producto, sesion, i).pipe(take(1)).subscribe();
          } else {
            this.llamadaAforoCarrito(producto, sesion).pipe(take(1)).subscribe();
          }
        });
      }
    });
  }

  llamadaAforoCarrito(producto, sesion, i?) {
    const p = producto.prod.producto.TipoProducto + ',' + producto.prod.producto.ProductoId + ',' + producto.cantidad;
    const body = {
      IdentificadorUnico: this.configurationService.configurationValue.currentSession.sessionId,
      IdSesion: sesion.RecintosSesionId,
      listadoProductos: [p],
      Recinto: sesion.IdRecinto,
      FechaHora: moment(sesion.Fecha + ' ' + sesion.HoraInicio, 'DD/MM/YYYY HH:mm').format('YYYY/MM/DD HH:mm:ss'),
      Plataforma: this.getPlataforma(),
      PosicionVenta: producto.prod.posicionVenta,// + 1,
      NumeroDia: i + 1,
    };
    return this.http.post(`${this.config.getConfig('API_URL')}/api/ReservaAforoProductos`, body).pipe(map((res: any) => {
      return res;
    }));
  }


  getPlataforma() {
    let enlaceMenu;
    let plataforma = '1';
    if(this.ventaService.modificandoReservaValue){
      return this.plataformaReservaValue
    }
    if (this.getEnlaceMenuId()) {
      enlaceMenu = JSON.parse(this.getEnlaceMenuId().CaracteristicasAdicionales).CaracteristicasAdicionales.CompraDesdeAdmin;
      if (enlaceMenu) {
        if (enlaceMenu !== '0') {
          plataforma = '4';
        }
      }
    }
    return plataforma;
  }

  cancelarAforo(sesion, numero?) {
    const body = {
      Accion: 6,
      SesionInternetId: this.configurationService.configurationValue.currentSession.sessionId,
      RecintosSesionesId: sesion.RecintosSesionId,
      IdEntrada: '',
      PosicionVenta: this.calcPosicionVenta(),
      NumeroDia: numero ? numero : null
    };
    return this.http.post(`${this.config.getConfig('API_URL')}/api/CancelarReservaAforo`, body).pipe(map((res: any) => {
      return res.DatosResult;
    }));
  }

  cancelarAforoCarrito() {
    const body = {
      Accion: 0,
      SesionInternetId: this.configurationService.configurationValue.currentSession.sessionId,
      RecintosSesionesId: '',
      IdEntrada: '',
    };
    return this.http.post(`${this.config.getConfig('API_URL')}/api/CancelarReservaAforo`, body).pipe(map((res: any) => {
      return res.DatosResult;
    }));
  }

  cancelarAforoInit() {
    const body = {
      Accion: 1,
      SesionInternetId: '',
      RecintosSesionesId: '',
      IdEntrada: ''
    };
    return this.http.post(`${this.config.getConfig('API_URL')}/api/CancelarReservaAforo`, body).pipe(map((res: any) => {
      return res.DatosResult;
    }));
  }

  GetPaises(idioma) {
    const body = {
      NombreTabla: 'pais',
      NombreCampo: '',
      FiltroCampo: '',
      Idioma: idioma,
    };
    return this.http.post(`${this.config.getConfig('API_URL')}/api/Configuracion/TraerDatosListas`, body).pipe(map((res: any) => {
      return res;

    }));
  }
  GetProvincias(idioma) {
    const body = {
      NombreTabla: 'provincia',
      NombreCampo: '',
      FiltroCampo: '',
      Idioma: idioma,
      
    };
    return this.http.post(`${this.config.getConfig('API_URL')}/api/Configuracion/TraerDatosListas`, body).pipe(map((res: any) => {
      return res;

    }));
  }
  GetTipoClientes(idioma) {
    const body = {
      NombreTabla: 'tipoclienteidioma',
      NombreCampo: '',
      FiltroCampo: '',
      Idioma: idioma,

    };
    return this.http.post(`${this.config.getConfig('API_URL')}/api/Configuracion/TraerDatosListas`, body).pipe(map((res: any) => {
      return res;

    }));
  }

  getMotivos(grupoId) {
    const body = {
      GrupoId: grupoId,
      Seleccionable: '1',
      Idioma: this.configurationService.configurationValue.datosInicio.Idioma0,
    };
    return this.http.post(`${this.config.getConfig('API_URL')}/api/Customan/ObtenerCustomanItemsMotivosGrupos `, body).pipe(map((res: any) => {
      return res.DatosResult;

    }));
  }

  traerSesiones(productos, fechaCalendarioModal?) {
    let numeroDia;
    if (this.ventaService.sesionesSeleccionadasValue.length) {
      const sesion = this.ventaService.sesionesSeleccionadasValue.find(element => element.mostrar === true);
      numeroDia = sesion.numero;
    }
    const plataforma = this.getPlataforma();
    const body = {
      FechaDesde: fechaCalendarioModal ? fechaCalendarioModal.format('YYYY/MM/DD') : this.ventaService.fechaSeleccionadaValue.format('YYYY/MM/DD'),
      FechaHasta: fechaCalendarioModal ? fechaCalendarioModal.format('YYYY/MM/DD') : this.ventaService.fechaSeleccionadaValue.format('YYYY/MM/DD'),
      Recinto: '',//this.recintos,
      listadoProductos: productos,
      Plataforma: plataforma,
      IdentificadorUnico: this.configurationService.configurationValue.currentSession.sessionId,
      PosicionVenta: this.calcPosicionVenta(),
      NumeroDia: numeroDia,
      Idioma: this.configurationService.configurationValue.datosInicio.Idioma0,
    };
    return this.http.post(`${this.config.getConfig('API_URL')}/api/Configuracion/TraerSesionesEntreFechas`, body).pipe(map((res: any) => {
      const sesionesAll = [];
      if (res.ListTarifasSesionesGuias) {
        this.setGuardarProfesores(res.ListTarifasSesionesGuias);
      }
      if (res.DatosSesionesEntreFechas) {
        if (res.DatosSesionesEntreFechas[0].ControlAforoExterno !== '') {
          this.muestraSesionesPorProducto = true;
        } else {
          this.muestraSesionesPorProducto = false;
        }
        this.recintosTodos(res.DatosSesionesEntreFechas);
        if (!this.muestraSesionesPorProducto) {
          res.DatosSesionesEntreFechas.forEach(s => {
            if ((plataforma === '1' && s.EstaDisponibleOnline === 'true') || (plataforma === '4' && s.EstaDisponibleTaquilla === 'true') ||
              this.contieneSesionReservada(s)) {
              const sesion = {
                IdRecinto: s.RecintoId.toString(),
                NombreRecinto: s.NombreIdioma,
                TipoAforo: s.TipoAforo,
                ControlaGrupo: s.ControlaGrupo.toString(),
                Disponible: plataforma === '1' ? s.DisponibleIntenet.toString() : s.Disponible.toString(),
                Fecha: moment(s.Fecha).format('DD/MM/YYYY'),
                HoraFin: s.HoraFin,
                HoraInicio: s.HoraInicio,
                RecintosSesionId: s.RecintosSesionId.toString(),
                ProductoId: '',
                Comentarios: s.Comentarios,
                Profesores: s.Guias,
                IdiomaSesion: s.IdiomaSesion,
                GrupoRecintoId: s.GrupoRecintoId,
                EsOpcional: s.EsOpcional
              };
              sesionesAll.push(sesion);
            }
          });
        } else {
          this.ventaService.compraValue.productos.forEach(producto => {
            res.DatosSesionesEntreFechas.forEach(s => {
              const sesion = {
                IdRecinto: s.RecintoId.toString(),
                NombreRecinto: s.NombreIdioma,
                TipoAforo: s.TipoAforo,
                ControlaGrupo: s.ControlaGrupo.toString(),
                Disponible: s.Disponible.toString(),
                Fecha: moment(s.Fecha).format('DD/MM/YYYY'),
                HoraFin: s.HoraFin,
                HoraInicio: s.HoraInicio,
                RecintosSesionId: s.RecintosSesionId.toString(),
                ProductoId: producto.producto.ProductoId,
                Comentarios: s.Comentarios,
                Profesores: s.Guias,
                GrupoRecintoId: s.GrupoRecintoId,
                EsOpcional: s.EsOpcional
              };
              sesionesAll.push(sesion);
            });
          });
        }
        return sesionesAll;
      }
      return sesionesAll;
    }));
  }

  contieneSesionReservada(s) {
    if (this.ventaService.modificandoReservaValue) {
      const posicionVenta = this.ventaService.compraValue.productos[0].posicionVenta;
      const sesiones = [];
      const reservaOriginal = JSON.parse(sessionStorage.getItem('reservaOriginal'))
      const compraCarrito = reservaOriginal.compras.find(c => c.posicionVenta === posicionVenta);
      if (compraCarrito) {
        if (compraCarrito.sesionesSeleccionadas) {
          compraCarrito.sesionesSeleccionadas.forEach(sesion => {
            const newObj = { fecha: moment(sesion.Fecha, 'DD/MM/YYYY').format('YYYYMMDD'), hora: sesion.HoraInicio, RecintoId: sesion.IdRecinto };
            sesiones.push(newObj);
          });
        }
        if (sesiones.find(item => item.fecha === s.Fecha && item.hora === s.HoraInicio && parseInt(item.RecintoId) === parseInt(s.RecintoId))) {
          return true;
        }
      }
    } else {
      return false;
    }
  }

  recintosTodos(sesiones) {
    const sesionesOk = sesiones.map(s => {
      return { ...s, IdRecinto: s.RecintoId.toString() };
    });
    const recintos = [];
    const recintosCodigo = [];
    sesionesOk.forEach(s => {
      if (!recintos.find(rec => rec.IdRecinto === s.IdRecinto)) {
        recintos.push(s);
        recintosCodigo.push({ codRecinto: s.IdRecinto });
      }
    });
    this.recintos = recintosCodigo;
    this.setRecintosTodos(recintos);
  }
  traerEnlacesMenuPorConexionId(res){
    return this.http.post(`${this.config.getConfig('API_URL')}api/TraerEnlacesMenuPorConexionId`, { iConexionId: res }).pipe(map((res: any) => {
      return res;
    }));
  }

  noEsAdministracion() {
    if (sessionStorage.getItem('admin')) {      
      var esAdmin  = -1;
      if (this.getEnlaceMenuId())  
        esAdmin = parseInt(JSON.parse(this.getEnlaceMenuId().CaracteristicasAdicionales).CaracteristicasAdicionales.CompraDesdeAdmin, 10);
      if (esAdmin === 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  calcDiaHoraMinimo() {
    this.minDia = '';
    let minHora;
    if(this.ventaService.fechaSeleccionadaSinSessionValue && this.ventaService.fechaSeleccionadaSinSessionValue.length > 0){
      this.ventaService.fechaSeleccionadaSinSessionValue.forEach(fechas => {
        const compraDia = moment(fechas.fecha, 'DD/MM/YYYY');
        const compraHora = moment('00:00', 'HH:mm');
        if (!this.minDia || !minHora) {
          this.minDia = compraDia;
          minHora = compraHora;
        } else {
          if (this.minDia > compraDia) {
            this.minDia = compraDia;
            minHora = compraHora;
          } else if (this.minDia === compraDia) {
            if (minHora > compraHora) {
              minHora = compraHora;
            }
          }
        }
      });
    }
    this.ventaService.carritoValue.compras.forEach(compra => {
      if (compra.sesionesSeleccionadas) {
        const compraDia = moment(compra.sesionesSeleccionadas[0].Fecha, 'DD/MM/YYYY');
        const compraHora = moment(compra.sesionesSeleccionadas[0].HoraInicio, 'HH:mm');
        if (!this.minDia || !minHora) {
          this.minDia = compraDia;
          minHora = compraHora;
        } else {
          if (this.minDia > compraDia) {
            this.minDia = compraDia;
            minHora = compraHora;
          } else if (this.minDia === compraDia) {
            if (minHora > compraHora) {
              minHora = compraHora;
            }
          }
        }
      } else {
        if(!this.minDia || !minHora) {
          this.minDia = moment();
          minHora = moment();
        }
      }
    });
  }

  calcDiaMaximo() {
    this.maxDia = '';
    if(this.ventaService.fechaSeleccionadaSinSessionValue && this.ventaService.fechaSeleccionadaSinSessionValue.length > 0){
      this.ventaService.fechaSeleccionadaSinSessionValue.forEach(fechas => {
        const compraDia = moment(fechas.fecha, 'DD/MM/YYYY');
        if (!this.maxDia) {
          this.maxDia = compraDia;
        } else {
          if (this.maxDia < compraDia) {
            this.maxDia = compraDia;
          }
        }
      });
    }
    this.ventaService.carritoValue.compras.forEach(compra => {
      if (compra.sesionesSeleccionadas) {
        const compraDia = moment(compra.sesionesSeleccionadas[0].Fecha, 'DD/MM/YYYY');
        if (!this.maxDia) {
          this.maxDia = compraDia;
        } else {
          if (this.maxDia < compraDia) {
            this.maxDia = compraDia;
          }
        }
      } else {
        if(!this.maxDia || this.maxDia < moment()) {
          this.maxDia = moment();
        } 
      }
    });
  }

  calcEnlacesMenu() {
    let em = [];
    this.enlacesMenu = '';
    em = this.ventaService.carritoValue.compras.forEach(compra => {
      if (em.find(en => en === compra.producto.enlaceMenu)) {
      } else {
        em.push(compra.producto.enlaceMenu);
        this.enlacesMenu = this.enlacesMenu + compra.producto.enlaceMenu + ',';
      }
    });
  }

  calcGruposInternet() {
    let gi = [];
    this.gruposInternet = '';
    gi = this.ventaService.carritoValue.compras.forEach(compra => {
      if (gi.find(en => en === compra.producto.GrupoInternet)) {
      } else {
        gi.push(compra.producto.GrupoInternet);
        this.gruposInternet = this.gruposInternet + compra.producto.GrupoInternet + ',';
      }
    });
  }

  calcProductos() {
    let prod = [];
    this.p = '';
    prod = this.ventaService.carritoValue.compras.forEach(compra => {
      if (prod.find(en => en === '[' + compra.producto.TipoProducto + ',' + compra.producto.ProductoId + ']')) {
      } else {
        prod.push('[' + compra.producto.TipoProducto + ',' + compra.producto.ProductoId + ']');
        this.p = this.p + '[' + compra.producto.TipoProducto + ',' + compra.producto.ProductoId + '],';
      }
    });
  }

  checkDescuento() {
    this.calcDiaHoraMinimo();
    this.calcEnlacesMenu();
    this.calcGruposInternet();
    this.calcProductos();
    const body = {
      Idioma: this.configurationService.configurationValue.datosInicio.Idioma0,
      Plataforma: 1,
      Codigo: this.ventaService.descuentoValue.descuento,
      Email: this.ventaService.clienteValue.Email,
      FechaDesde: this.minDia.format('YYYY/MM/DD'),
      ImporteTotal: (this.ventaService.carritoValue.calcularPrecioTotal()).toFixed(4),
      ImporteInicial: (this.ventaService.carritoValue.calcularPrecioTotalSinIva()).toFixed(4),
      Cantidad: this.ventaService.carritoValue.calcularCantidadProductos(),
      EnlacesMenu: this.enlacesMenu.slice(0, -1),
      GruposInternet: this.gruposInternet.slice(0, -1),
      Productos: this.p.slice(0, -1),
    };
    return this.http.post(`${this.config.getConfig('API_URL')}/api/ValesPromocionales`, body).pipe(map((res: any) => {
      return res;
    }));
  }

  checkDescuentoAdmin() {
    this.calcDiaHoraMinimo();
    this.calcEnlacesMenu();
    this.calcGruposInternet();
    this.calcProductos();
    this.getDescuento();
    let tiposDescuento;
    if (this.ventaService.descuentoPerValue.tipo === 'Porcentaje') {
      tiposDescuento = 'DT';
    } else if (this.ventaService.descuentoPerValue.tipo === 'Importe') {
      tiposDescuento = 'PR';
    }
    const body = {
      Idioma: this.configurationService.configurationValue.datosInicio.Idioma0,
      Plataforma: this.getPlataforma(),
      Fecha: this.minDia.format('YYYY/MM/DD'),
      clienteCompra: this.clienteCompraBody(),
      EnlacesMenu: this.enlacesMenu.slice(0, -1),
      GruposInternet: this.gruposInternet.slice(0, -1),
      Productos: this.p.slice(0, -1),
      DtoTipo: tiposDescuento,
      DtoCantidad: this.ventaService.descuentoPerValue.cantidad,
      ImporteInicial: (this.ventaService.carritoValue.calcularImporteInicial()).toFixed(4),
    };
    return this.http.post(`${this.config.getConfig('API_URL')}/api/Tarifas/AplicarDescuentoPersonalizado`, body).pipe(map((res: any) => {
      return res;
    }));
  }

  checkValePromocional() {
    this.calcDiaHoraMinimo();
    this.calcDiaMaximo();
    const body = {
      Idioma: this.configurationService.configurationValue.datosInicio.Idioma0,
      Codigo: this.ventaService.descuentoCodValue.codigo,
      FechaDesde: this.minDia.format('YYYY/MM/DD'),
      FechaHasta: this.maxDia.format('YYYY/MM/DD'),
      Entradas: this.calcEntradasValePromocional(),
      Bonos: this.calcBonosValePromocional(),
      Plataforma: this.getPlataforma(),
    };
    return this.http.post(`${this.config.getConfig('API_URL')}/api/CodigosPromocionales`, body).pipe(map((res: any) => {
      return res;
    }));
  }

  calcEntradasValePromocional() {
    const entradasSinBonos = this.ventaService.carritoValue.compras.filter(p => !p.producto.ProductosBono.length);
    const entradas = [];
    entradasSinBonos.forEach(entrada => {
      entradas.push({ ProductoId: entrada.producto.ProductoId });
    });
    const result = Array.from(entradas.reduce((m, t) => m.set(t.ProductoId, t), new Map()).values());
    return result;
  }

  calcBonosValePromocional() {
    const entradasConBonos = this.ventaService.carritoValue.compras.filter(p => p.producto.ProductosBono.length);
    const bonos = [];
    entradasConBonos.forEach(entrada => {
      bonos.push({ ProductoId: entrada.producto.ProductoId });
    });
    const result = Array.from(bonos.reduce((m, t) => m.set(t.ProductoId, t), new Map()).values());
    return result;
  }

  getPintarCalendario(fechaFormat, fechaHastaFormat) {

    if(this.espectaculoValue && this.mostrarProductosValue==true)
    {
    
    return this.getButacasDisponibleFechas(this.espectaculoValue.EspectaculosId,this.espectaculoValue.GrupoRecintoId,fechaFormat,fechaHastaFormat,'HORAS')  
    
    .pipe(map((res: any) => {
        let estadoSesionesMensual;
        if (res.DatosResult === null) {
          estadoSesionesMensual = [];
        } else {
          estadoSesionesMensual = res.DatosResult.ButacasDisponibleFecha;
        }
        this.tieneSesiones = true;
        const arrayDias = estadoSesionesMensual.map(dia => {
          const fecha = moment(dia.DiaDeSesion, 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY');
          const disponibilidad = dia.Estado;
          return { fecha, disponibilidad };
        });
        this.showDays = res.DatosResult.ButacasDisponibleFecha.map(dia => {
          const diaSinHora = dia.DiaDeSesion.split(' ')[0];
          const horaSinDia = dia.HoraDeSesion.split(' ')[1].slice(0, -3);
          let estado = dia.Estado;
          const entradasDisponibles = dia.NumButacasDisp;
          const diaHora = { diaSinHora, horaSinDia, estado, entradasDisponibles };
          return diaHora;
        });

        if (this.ventaService.fechaSeleccionadaValue) {
          this.getHours(this.ventaService.fechaSeleccionadaValue);
        }
  
        return arrayDias;

      }));

      
    } else{
    const body = {
      FechaInicio: fechaFormat,
      FechaFin: fechaHastaFormat,
      GruposInternet: this.getGrupoInternet().gi,
      SesionInternetId: this.configurationService.configurationValue.currentSession.sessionId,
      TipoEntradaSIId: "",
      PosicionVenta: this.calcPosicionVenta(),
      Plataforma: this.getPlataforma()
    };
    return this.http.post(`${this.config.getConfig('API_URL')}/api/GetEstadoSesionesMensual`, body).pipe(map((res: any) => {
      let estadoSesionesMensual;
      if (res.DatosResult === null) {
        estadoSesionesMensual = [];
      } else {
        estadoSesionesMensual = res.DatosResult.ListadoEstadoSesionesMensual;
      }
      this.tieneSesiones = true;
      const arrayDias = estadoSesionesMensual.map(dia => {
        const fecha = moment(dia.Dia, 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY');
        const disponibilidad = dia.Estado;
        return { fecha, disponibilidad };
      });
      return arrayDias;
    }));
  }
  }

  getPrimerDiaDisponible(fechaFormat, fechaHastaFormat) {
    const body = {
      FechaInicio: fechaFormat,
      FechaFin: fechaHastaFormat,
      GruposInternet: this.getGrupoInternet().gi,
      SesionInternetId: this.configurationService.configurationValue.currentSession.sessionId,
      TipoEntradaSIId: "",
      PosicionVenta: this.calcPosicionVenta(),
      Plataforma: this.getPlataforma()
    };
    if(this.espectaculoValue && this.espectaculoValue.EspectaculosId){
      body['EspectaculoId'] =  this.espectaculoValue.EspectaculosId;
    }
    return this.http.post(`${this.config.getConfig('API_URL')}/api/GetPrimerDiaDisponible`, body).pipe(map((res: any) => {
      let fecha;
      if (res.DatosResult && res.DatosResult.Dia) {
        return  fecha = moment(res.DatosResult.Dia, 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY');
      }      
      return fecha;
    }));
  }

  calcPosicionVenta() {
    let posicionVenta;
    if (this.ventaService.modificarValue) {
      posicionVenta = this.ventaService.compraValue.productos[0].posicionVenta; // + 1;
    } else {
      posicionVenta = this.ventaService.calcularposicionVenta(); // + 1;
    }
    return posicionVenta;
  }

  //<< Yaribel 20210820 VSTS 8424
  checkCodigoClienteAsociado(tipoDocumento, codigoCliente) {
    this.calcDiaHoraMinimo();
    this.calcDiaMaximo();
    const body = {
      Idioma: this.configurationService.configurationValue.datosInicio.Idioma0,
      Codigo: {TipoDocumento: tipoDocumento, NumeroDocumento: codigoCliente},
      FechaDesde: this.minDia.format('YYYY/MM/DD'),
      FechaHasta: this.maxDia.format('YYYY/MM/DD'),
      Entradas: this.calcEntradasClienteAsociado(),
      Bonos: this.calcBonosClienteAsociado(),
      Plataforma: this.getPlataforma(),
    };
    return this.http.post(`${this.config.getConfig('API_URL')}/api/ValidarCompraClienteMapfre`, body).pipe(map((res: any) => {
      return res;
    }));
  }


  calcEntradasClienteAsociado() {
    const entradasSinBonos = this.ventaService.carritoValue.compras.filter(p => !p.producto.ProductosBono.length);
    const entradas = [];
    entradasSinBonos.forEach(entrada => {
      entradas.push({ ProductoId: entrada.producto.ProductoId, IdCategoria_GI: entrada.producto.GrupoInternet, Cantidad: entrada.cantidad }); //TODO falta añadir grupo internet y cantidad{ ProductoId: entrada.entrada.ItemId, IdCategoria_GI: entrada.entrada.CategoriaId, Cantidad: entrada.cantidad}
    });
    const result = Array.from(entradas.reduce((m, t) => m.set(t.ProductoId, t), new Map()).values());
    return result;
  }

  calcBonosClienteAsociado() {
    const entradasConBonos = this.ventaService.carritoValue.compras.filter(p => p.producto.ProductosBono.length);
    const bonos = [];
    entradasConBonos.forEach(entrada => {
      bonos.push({ ProductoId: entrada.producto.ProductoId, IdCategoria_GI: entrada.producto.GrupoInternet, Cantidad: entrada.cantidad }); //TODO falta añadir grupo internet y cantidad{ ProductoId: entrada.entrada.ItemId, IdCategoria_GI: entrada.entrada.CategoriaId, Cantidad: entrada.cantidad}
    });
    const result = Array.from(bonos.reduce((m, t) => m.set(t.ProductoId, t), new Map()).values());
    return result;
  }
    //VSTS 8424 >>

  /******************************** Zona Espectaculos *************************************/  
/* ESPECTACULOS Añadimos observables para controlor los espectaculos*/
get todosEspectaculosCatValue(){
  return this.todosEspectaculosCatSubject.value;
}

public setTodosEspectaculosCat(todos){
  this.todosEspectaculosCatSubject.next(todos);
}

get espectaculosCatValue(){
  return this.espectaculosCatSubject.value;
}

public setEspectaculosCat(espectaculos){
  this.espectaculosCatSubject.next(espectaculos);
}

get backupEspectaculosValue(){
  return this.backupEspectaculosSubject.value;
}

public setBackupEspectaculos(todos){
  this.backupEspectaculosSubject.next(todos);
}

get filtroProductosValue(){
  return this.filtroProductosSubject.value;
}

public setFiltroProductos(valor){
  this.filtroProductosSubject.next(valor);
}

get fechaFiltroProductosValue(){
  return this.fechaFiltroProductosSubject.value;
}
public setFechaFiltroProductos(fecha){
  this.fechaFiltroProductosSubject.next(fecha);
}

get showHoursValue(){
  return this.showHoursSubject.value;
}

public setShowHours(horas)
{
  this.showHoursSubject.next(horas);
}

get ventaEspectaculoValue(){
  return this.ventaEspectaculoSubject.value;
}
public setVentaEspectaculo(valor){
  this.ventaEspectaculoSubject.next(valor);
}
/* */


//Nuevas peticiones a espectaculos
    getEspectaculos(idEspectaculo) {
      this.getUserPwd();
      this.getDescuento();
      let fHasta = moment();
      fHasta = fHasta.add(1, 'year');
      let fechaHasta = fHasta.format('YYYY/MM/DD');
      let fechaDesde = this.ventaService.fechaSeleccionadaValue.format('YYYY/MM/DD');
      if(this.fechaFiltroProductosValue)
      {
        fechaDesde = this.fechaFiltroProductosValue.format('YYYY/MM/DD')
        fechaHasta = this.fechaFiltroProductosValue.format('YYYY/MM/DD')
      }
      const body = {
        Idioma: this.configurationService.configurationValue.datosInicio.Idioma0,
        clienteCompra: this.clienteCompraBody(),
        EnlaceMenuId: this.getEnlaceMenuId()?this.getEnlaceMenuId().pkid:null,
        FechaHasta: fechaHasta,
        IncluirSesiones: 2,
        Plataforma: this.getPlataforma(),
        EspectaculosId: idEspectaculo, //"TODOS",
        FiltroGruposRecintos: "TODOS",
        FiltroTexto: "TODOS",
        VerEspectaculos: 0,
        FechaDesde: fechaDesde,
        Activo: 1

      };
      return this.getEspectaculo(body)
    }
    
    getTodosEspectaculos(enlacesMenu) {
      this.getUserPwd();
      this.getDescuento();
      let fHasta = moment();
      fHasta = fHasta.add(1, 'year');
      let fechaHasta = fHasta.format('YYYY/MM/DD');
      let fechaDesde = this.ventaService.fechaSeleccionadaValue.format('YYYY/MM/DD');
      if(this.fechaFiltroProductosValue)
      {
        fechaDesde = this.fechaFiltroProductosValue.format('YYYY/MM/DD')
        fechaHasta = this.fechaFiltroProductosValue.format('YYYY/MM/DD')
      }
      const body = {
        Idioma: this.configurationService.configurationValue.datosInicio.Idioma0,
        clienteCompra: this.clienteCompraBody(),
        EnlaceMenuId: enlacesMenu,
        GruposInternet: '',
        Espectaculo: 0,
        GrupoRecinto: 0,
        Recinto: 0,
        FechaDesde: fechaDesde,
        FechaHasta: fechaHasta,
        SalidaDict: 0,
        IncluirSesiones: 2,
        Plataforma: this.getPlataforma(),
        EspectaculosId: "TODOS",
        FiltroGruposRecintos: "TODOS",
        FiltroTexto: "TODOS",
        VerEspectaculos: 0,
        Activo: 1
      };
      return this.getEspectaculo(body)
    }
    getEspectaculo(body){
      return this.http.post(`${this.config.getConfig('API_URL')}/api/EspectaculosDisponible`, body).pipe(map((res: any) => {
        return res.DatosResult;
      }));
    }

   //mapas

   public get grupoInternetValue() {
    return this.grupoInternetSubject.value;
  }

  public setGrupoInternetValue(value) {
    this.grupoInternetSubject.next(value);
  }
  

  public setEspectaculoValue(espectaculo) {
    this.espectaculoSubject.next(espectaculo);
  }

public get espectaculoValue() {
  return this.espectaculoSubject.value;
}


//metodo de butacas getShowDays modificado para la nueva venta
getButacasDisponibleFechas(espectaculoId = 1, grupoRecintoId = 1, fechaDesde, fechaHasta, grupBy) {
  // const enlaceMenu = JSON.parse(sessionStorage.getItem('enlaceMenu'));
  // const gruposInternet = JSON.parse(sessionStorage.getItem('gruposInternet'));
  const body = {
    Idioma: this.translate.getLocale(),
    EspectaculoId: espectaculoId,
    GrupoRecintoId: grupoRecintoId,
    Plataforma: this.getPlataforma(),
    FechaDesde: fechaDesde, //fechaDesde.format('YYYY/MM/DD' + ' ' + 'LTS'),
    FechaHasta: fechaHasta, //fechaHasta.format('YYYY/MM/DD' + ' ' + 'LTS'),
    GroupbyDias: grupBy,
    GruposInternet: this.getGrupoInternet()? this.getGrupoInternet().gi:null, // gruposInternet,
    EnlaceMenuId: this.getEnlaceMenuId()?this.getEnlaceMenuId().pkid:null, //enlaceMenu.pkid
  };

  return this.http.post(`${this.config.getConfig('API_URL')}api/ButacasDisponibleFechas`, body);
}

getHours(date: moment.Moment) {
  let showHours = [];
  this.showDays.forEach((day: any) => {
    if (moment(date).format('DD/MM/YYYY') === day.diaSinHora) {
      showHours.push(day);
    }
  });
  this.setShowHours(showHours);
  if (showHours.length === 1) {
    this.selectHour(showHours[0].horaSinDia);
  }
}

selectHour(date: string): void {
  const dateObject = moment(date, 'HH:mm');
  this.ventaService.setHoraSeleccionadaValue(dateObject);
}

}
