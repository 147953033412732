import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'; //VO
import { MaterialModule } from '../../../shared/material/material.module';
import { MinimapaRecintoComponent } from './minimapa-recinto/minimapa-recinto.component';
// import { SelectorNoNumeradasComponent } from '../componentes/selector-no-numeradas/selector-no-numeradas.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { SelectorButacasComponent } from './selector-butacas/selector-butacas.component';
import { MapaRecintoComponent } from './mapa-recinto/mapa-recinto.component';
import { ModalNONumeradoComponent } from './modal-no-numerado/modal-no-numerado.component';
import { SelectorNoNumeradasComponent } from './selector-no-numeradas/selector-no-numeradas.component';
import { ModalNumeradaComponent } from './modal-numerada/modal-numerada.component';


@NgModule({
  declarations: [
    SelectorButacasComponent, 
     MapaRecintoComponent,
     MinimapaRecintoComponent,
    ModalNumeradaComponent,
    ModalNONumeradoComponent,
     SelectorNoNumeradasComponent //,
    //SliderPreciosComponent,
    //ModalInfoReservaComponent
  ],
  imports: [
     FontAwesomeModule,  //VO
     CommonModule,
    // HttpClientModule,
     FormsModule,
     ReactiveFormsModule,
     MaterialModule,
    SharedModule
  ],
  //entryComponents: [SliderPreciosComponent],
  //bootstrap: [SliderPreciosComponent],

  exports: [
     SelectorButacasComponent,MapaRecintoComponent,
     MinimapaRecintoComponent
  ]
})
export class SelectorButacasModule {

}
