import { Component, OnInit } from '@angular/core';
import { InitService } from './init.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductosService } from 'src/app/business/productos.service';
import { AppConfiguration } from 'src/app/app.configuration';
import { take } from 'rxjs/operators';
import { TokenInit } from 'src/app/models/token-init.model';
import { UserService } from 'src/app/user/user.service';


@Component({
  selector: 'app-init',
  templateUrl: './init.component.html',
  styleUrls: ['./init.component.scss']
})
export class InitComponent implements OnInit {

  datosUrl: string;

  //Yaribel añadiendo butacas
  espectaculoId: string;
  grupoRecintoId: string;
  grupoInternetId: string;
  lang: string;
  enlaceMenuParam: string;
  //añadiendo butacas

  constructor(
    private initService: InitService,
    private route: ActivatedRoute,
    private productosService: ProductosService,
    private config: AppConfiguration,
    private router: Router,
    private userService: UserService
  ) {
  }

  ngOnInit() {
    //Yaribel añadiendo butacas para espectaculos
    //esta parte no hace falta que se gestiona todo por DatosInitValue en productService
    this.espectaculoId = this.route.snapshot.queryParams.espectaculoId || 'TODOS';
    this.grupoRecintoId = this.route.snapshot.queryParams.grupoRecintoId || 'TODOS';
    this.grupoInternetId = this.route.snapshot.queryParams.grupoInternetId;
    this.lang = this.route.snapshot.queryParams.lang;
    this.enlaceMenuParam = this.route.snapshot.queryParams.enlaceMenu;
    //añadiendo butacas

    if (this.route.snapshot.queryParams.ck) {
      this.datosUrl = this.route.snapshot.queryParams.ck;
      sessionStorage.setItem('token', this.datosUrl);
      const token: TokenInit = JSON.parse(this.initService.funcionDesencript(this.datosUrl));

      this.initService.setFromAdmonValue(false);

      if (token.userId && token.ua && token.pa) {
        this.initService.setFromAdmonValue(true);
      }
     if(token.espectaculoId && token.espectaculoId != '0'){
      this.productosService.setVentaEspectaculo(true);
     }

      let lang = token.lg;
      localStorage.setItem('lastlang',lang);
      this.userService.setlang.emit(lang);
      
      this.productosService.setDatosInitValue(token);
      if (token.u || token.ua) {
        sessionStorage.setItem('admin', ((this.initService.funcionDesencript(this.datosUrl))));
      }
      this.initService.traerDatosInicio();

    } else if (sessionStorage.getItem('token')) {
      const token: TokenInit = JSON.parse(this.initService.funcionDesencript(sessionStorage.getItem('token')));
      this.productosService.setDatosInitValue(token);
      this.initService.traerDatosInicio();

    } else if (!sessionStorage.getItem('token')) {
      const proyecto = this.config.getProyecto();
      if (proyecto === 'SNOWZONE') {
        // se le pone un token por defecto
        this.datosUrl = '48d30101161801150a515e44150809045f065c56161f165f165148352f3aa375610f74066d9554984656445e461b';
        sessionStorage.setItem('token', this.datosUrl);
        const token: TokenInit = JSON.parse(this.initService.funcionDesencript(this.datosUrl));
        this.productosService.setDatosInitValue(token);
        this.initService.traerDatosInicio();
      } else {
        this.initService.getInitData().pipe(take(1)).subscribe(() => {
          this.router.navigate(['/tienda/productos']);
        });
      }
    }
    let _that=this;
    setTimeout(function() {_that.userService.setlang.emit(localStorage.getItem("lastlang"));}, 3000);
    
  }
}
