import { Component, ElementRef, Input, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { ModalService } from "src/app/shared/modal.service";
import { Butaca, Estados, validateMinMax } from "../../modelos/butaca.model";
import { Entrada } from "../../modelos/entrada";
import { ButacasService } from "../servicios/butacas.service";
import { InfoMapasService } from "../servicios/info-mapas.service";
import { EspectaculosService } from "../../espectaculos.service";

@Component({
  selector: "iacpos-modal-numerada",
  templateUrl: "./modal-numerada.component.html",
  styleUrls: ["./modal-numerada.component.css"],
})
export class ModalNumeradaComponent implements OnInit, OnDestroy {
  @Input() id: string;
  private element: any;
  tiposEntradas: any;
  butaca: any;
  claveEmpresa: string;
  ruta: string;
  estados: Estados;
  valMinMax: validateMinMax;

  constructor(
    public router: Router,
    private modalService: ModalService,
    private el: ElementRef,
    private butacasService: ButacasService,
    private infoMapasService: InfoMapasService,
    private toast: ToastrService,
    private espectaculosService: EspectaculosService
  ) {
    this.element = el.nativeElement;
  }


  ngOnInit(): void {
    this.ruta = this.router.url;
    const modal = this;
    this.tiposEntradas = [];
    this.butacasService.butacaMarcada.subscribe((butaca) => {
      this.butaca = butaca;
    });

    // ensure id attribute exists
    if (!this.id) {
      return;
    }

    // move element to bottom of page (just before </body>) so it can be displayed above everything else
    document.body.appendChild(this.element);

    // close modal on background click
    this.element.addEventListener("click", function (e: any) {
      if (e.target.className === "iacpos-modal-numerada") {
        modal.close();
      }
    });

    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.modalService.add(this);
    this.claveEmpresa = this.butacasService.getConfig("chClaveEmpresa");
  }

  // remove self from modal service when directive is destroyed
  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();
  }

  // open modal
  open(): void {
    if (this.butaca) {
      if (this.butaca.tarifas.length === 1) {
        this.selectTipo(this.butaca, 0);
      } else {
        this.comprobarButaca();
      }
    }
  }

  comprobarButaca() {
    let copyButaca = { ...this.butaca };

    copyButaca.tipoSeleccionado = this.butaca.tarifas[0];
    copyButaca.fecha = this.butacasService.fechaSeleccionadaValue;
    copyButaca.hora = this.butacasService.horaSeleccionadaValue;
    this.butacasService
      .httpPostMarcarDesmarcarButaca(copyButaca, false)
      .subscribe((res: any) => {
        if (
          res &&
          res.DatosResult &&
          res.DatosResult.IdRB &&
          res.DatosResult.IdRB.includes(
            Number.parseInt(this.butaca.info.infoGeneral.recintoButacaId, 10)
          )
        ) {
          this.element.style.display = "block";
          document.body.classList.add("selector");
        } else {
          this.toast.info(
            this.butacasService.getTranslate().data.ERR_BUTACA_NO_DISPONIBLE,
            "Info",
            {
              timeOut: 5000,
              positionClass: "toast-top-center",
            }
          );
          this.modalService.close("selector");
        }
      });
  }

  private checkMinMax = (butaca: Butaca): void => {
    const modificar = this.butacasService.modificarValue();
    const arrSeats = modificar
      ? this.butacasService.selectedSpecialSeats
      : this.butacasService.seatsNow;
    const total = arrSeats.length;

    this.valMinMax = { valid: true, min: true, max: true };
    if (total === 0) {
      return;
    }
    if (
      butaca.tipoSeleccionado &&
      butaca.tipoSeleccionado.MaximoTarifa &&
      butaca.tipoSeleccionado.MinimoTarifa
    ) {
      const minT = parseInt(butaca.tipoSeleccionado.MinimoTarifa);
      const maxT = parseInt(butaca.tipoSeleccionado.MaximoTarifa);

      const filterSeats = arrSeats.filter(
        (item) =>
          item.tipoSeleccionado.ProductoId ===
          butaca.tipoSeleccionado.ProductoId
      );

      /* 
        Se ha sacado la validación de mínimo en la selección de butacas, se deja la validación en el botón continuar por ahora
        if (filterSeats.length < minT) {
          this.valMinMax = { valid: false, min: false, max: true };
          return;
        } 
      */

      if (filterSeats.length > maxT) {
        this.valMinMax = { valid: false, min: true, max: false };
        return;
      }

      if (butaca.tipoSeleccionado.MaximoEspecial) {
        const maxE = parseInt(butaca.tipoSeleccionado.MaximoEspecial);
        if (filterSeats.length > maxE) {
          this.valMinMax = { valid: false, min: true, max: false };
          return;
        }
      }
    }

    if (
      butaca.tipoSeleccionado &&
      butaca.tipoSeleccionado.Maximo &&
      butaca.tipoSeleccionado.Minimo
    ) {
      const min = parseInt(butaca.tipoSeleccionado.Minimo);
      const max = parseInt(butaca.tipoSeleccionado.Maximo);

      if (total < min) {
        this.valMinMax = { valid: false, min: false, max: true };
        return;
      }

      if (total > max) {
        this.valMinMax = { valid: false, min: true, max: false };
        return;
      }
    }
  };

  // close modal
  close(): void {
    this.element.style.display = "none";
    document.body.classList.remove("selector");
  }

  nodoble() {
    return false;
  }

  selectTipo(butaca: any, index): void {
    if (
      this.butacasService.selectedSeats.find(
        (item) => item.PKIDRB === this.butaca.PKIDRB
      )
    ) {
      return;
    }
    if (+butaca.Habilitado !== 0) {
      if (this.modalService.butacas) {
        this.modalService.butacas.forEach((b: Butaca) => {
          const entrada = new Entrada();
          entrada.Id = +this.butacasService.butacasMap[b.idRecintoButaca];
          entrada.Nombre = butaca.NombreEntrada;
          entrada.Precio = butaca.PrecioInternet;

          this.butacasService.addProduct(butaca);
        });
        this.modalService.butacas = undefined;
      } else {
        this.butaca.tipoSeleccionado = this.butaca.tarifas[index];
        this.butaca.fecha = this.butacasService.fechaSeleccionadaValue;
        this.butaca.hora = this.butacasService.horaSeleccionadaValue;
        let modficacionReserva = false;

        if (this.butaca.tipoSeleccionado) {
          let seats = new Set([
            ...this.butacasService.selectedSpecialSeats,
            this.butaca,
          ]);
          this.butacasService.selectedSpecialSeats = [...seats];
          let seatsAll = new Set([
            ...this.butacasService.selectedSeats,
            this.butaca,
          ]);
          let seatsN = new Set([...this.butacasService.seatsNow, this.butaca]);
          this.butacasService.seatsNow = [...seatsN];
          this.butacasService.selectedSeats = [...seatsAll];
          this.butaca = this.butacasService.checkSpecialRates(this.butaca);
        }

        if (this.butacasService.modificarValue) {
          modficacionReserva = this.butacasService
            .compraReservaInicial()
            .some((e) => +e.idRB === +this.butaca.PKIDRB);
        }
        this.checkMinMax(this.butaca);

        if (this.valMinMax.valid) {
          if (!modficacionReserva) {
            this.butacasService
              .httpPostMarcarDesmarcarButaca(this.butaca, true)
              .subscribe((res: any) => {
                if (
                  res &&
                  res.DatosResult &&
                  res.DatosResult.IdRB &&
                  res.DatosResult.IdRB.includes(
                    Number.parseInt(
                      this.butaca.info.infoGeneral.recintoButacaId,
                      10
                    )
                  )
                ) {
                  this.updateDatosButacaMarcada();

                  this.modalService.close("selector");
                } else {
                  this.butacasService.getEstadosButacas(
                    this.infoMapasService.recinto,
                    this.butacasService.nombreRecinto
                  );
                  this.toast.info(
                    this.butacasService.getTranslate().data
                      .ERR_BUTACA_NO_DISPONIBLE,
                    "Info",
                    {
                      timeOut: 5000,
                      positionClass: "toast-top-center",
                    }
                  );
                  this.modalService.close("selector");
                }
              });
          } else {
            this.updateDatosButacaMarcada();

            this.modalService.close("selector");
          }
        } else {
          this.butacasService.selectedSpecialSeats =
            this.butacasService.selectedSpecialSeats.filter(
              (item) => item.PKIDRB !== this.butaca.PKIDRB
            );
          this.butacasService.selectedSeats =
            this.butacasService.selectedSeats.filter(
              (item) => item.PKIDRB !== this.butaca.PKIDRB
            );
          this.butacasService.seatsNow = this.butacasService.seatsNow.filter(
            (item) => item.PKIDRB !== this.butaca.PKIDRB
          );
          this.toast.info(
            this.butacasService.getTranslate().data.ERR_SUPERADO_MAXIMO_TARIFA,
            "Info",
            {
              timeOut: 5000,
              positionClass: "toast-top-center",
            }
          );
        }
      }
    }
  }

  private updateDatosButacaMarcada() {
    const espectaculo = {
      ...this.butacasService.espectaculoValue,
      fecha: this.butacasService.fechaSeleccionadaValue,
      hora: this.butacasService.horaSeleccionadaValue,
    };
    const datosExtra = {
      idEspectaculoAsociado:
        this.butacasService.espectaculoValue.EspectaculosId,
    };

    const copyButaca = { ...this.butaca };
    // delete copyButaca.tarifas;
    delete copyButaca.info.tiposDeEntrada;
    const butacaDom = document.querySelector(
      "circle[_PKIDRB='" + this.butaca.PKIDRB + "']"
    );
    butacaDom.setAttribute(
      "style",
      butacaDom.getAttribute("style") + "fill: rgb(0,0,255)"
    );
    butacaDom.classList.add("marcada");
    this.butacasService.addProduct({ ...copyButaca, ...datosExtra });
    if (
      !this.butacasService.butacasSeleccionadas.includes(+this.butaca.PKIDRB)
    ) {
      this.butacasService.butacasSeleccionadas.push(+this.butaca.PKIDRB);
    }
    this.butacasService.ComprobarNoDejarButacasLibres();
  }

  selectTipo2(tipoSeleccionado: any): void {
    if (this.modalService.butacas) {
      this.modalService.butacas.forEach((b: Butaca) => {
        const entrada = new Entrada();
        entrada.Id = +this.butacasService.butacasMap[b.idRecintoButaca];
        entrada.Nombre = tipoSeleccionado.NombreEntrada;
        entrada.Precio = tipoSeleccionado.PrecioInternet;
      });
      this.modalService.butacas = [];
    } else {
      this.butaca.tipoSeleccionado = tipoSeleccionado;
      if (this.butacasService.removeButaca(this.butaca)) {
        this.butacasService.addProduct(this.butaca);
      }
    }
    this.modalService.close("selector");
  }
}
