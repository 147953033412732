import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProductosService } from 'src/app/business/productos.service';
import { VentaService } from 'src/app/core/services/venta.service';
import { UserService } from 'src/app/user/user.service';
import { ModalService } from '../../modal.service';
import { TranslateService } from '../../translate.service';

@Component({
  selector: 'app-modal-login-and-register',
  templateUrl: './modal-login-and-register.component.html',
  styleUrls: ['./modal-login-and-register.component.scss']
})
export class ModalLoginAndRegisterComponent implements OnInit {
  private ngUnsubscribe = new Subject();


  private element: any;
  @Input() id: string;

  ilogueo=0;
  GIDisponible = 0;
  overFlowRegister=false
  forgetPassw=false;
  passCaducada = false;
  showContinue: boolean = true;
  showRegister$: Observable<boolean>;

  constructor(
    private el: ElementRef,
    private modalService: ModalService,
    private router: Router,
    private translate: TranslateService,
    private ventaService: VentaService,
    private productosService: ProductosService,
    private userService: UserService
  ) {
    this.element = el.nativeElement;
   }

  ngOnInit() {     
    this.showRegister$ = this.productosService.dataRegistro$;
    this.userService.passCaducada$.subscribe(val =>{ this.passCaducada = this.userService.passCaducadaValue });    
    this.initLoging();
    const modal = this;
    document.body.appendChild(this.element);
    // tslint:disable-next-line:only-arrow-functions
    this.element.addEventListener('click', function (e: any) {
      if (e.target.className === 'jw-modal') {
        modal.close();
      }
    });
    this.modalService.add(this);
  }

  initLoging(){
    this.ventaService.enlaceMenuCarrito$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(em => {
      if (em) {
        const pkid = em.pkid ? em.pkid : em;
        const enlaceMenu = this.productosService.enlacesMenuValue.find(e => e.pkid === pkid);
          this.ilogueo = enlaceMenu.iLogueo;
          if(+this.ilogueo < 1 || +this.ilogueo === 3) {
            this.showContinue = true;
          } else if(+this.ilogueo === 1 || +this.ilogueo === 2) {
            this.showContinue = false;
          }
          this.GIDisponible = 1;              
      }
    });
  }
  changeCss(flag){
    this.overFlowRegister=flag
  }
  open(flag?): void {
    if(flag){
      /* this.initService.cookies$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(cookies=>{
        this.cookies = cookies
        this.check=true
      }) */
     // this.getCookiesExterno()
    }
    this.element.style.display = 'block';
    document.body.classList.add('jw-modal-open');

  }
  forgetPassword(flag){
    this.forgetPassw=flag
  }
  ngOnDestroy(): void {

/*     this.modalService.remove(this.id);
       this.ngUnsubscribe.next();
       this.ngUnsubscribe.complete();
     */    
      // this.modalService.remove(this.id);
    this.element.remove();
  }
  close(): void {
    
    //this.router.navigate(['/tienda/productos']);
    this.element.style.display = 'none';
    document.body.classList.remove('jw-modal-open');
  }
}
