import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
import { debounceTime, exhaustMap } from 'rxjs/operators';
import { documentoValidar } from 'src/app/business/documento-validar.validator';
import { ProductosService } from 'src/app/business/productos.service';
import { VentaService } from 'src/app/core/services/venta.service';
import { PagerService } from 'src/app/shared/pager.service';
import { UserService } from 'src/app/user/user.service';

@Component({
  selector: 'app-factura',
  templateUrl: './factura.component.html',
  styleUrls: ['./factura.component.scss']
})
export class FacturaComponent implements OnInit, OnDestroy {
  modelChanged: Subject<string> = new Subject<string>();
  private ngUnsubscribe = new Subject();
  abrir = [];
  clientes = [];
  optionsDrop = ['DNI', 'NIE'];
  check = false;
  cargarDatos = false;
  campo;
  pager: any = {};
  pagedItems: any[];
  totalPages: number;
  datosFacturaForm: FormGroup;
  noEsAdmin;
  @Input() paises: any;
  @Output() facturaForm = new EventEmitter<FormGroup>();
  @Output() isCheck = new EventEmitter<boolean>();
  paisSeleccionado; //Yaribel 20210531 Añadimos variable para obtener el Codigo del pais seleccionado

  constructor(
    private formBuilder: FormBuilder,
    private ventaService: VentaService,
    private productosService: ProductosService,
    private pagerService: PagerService,
    private userService: UserService
  ) { }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.datosFacturaForm = this.formBuilder.group({
      nombre: ['', [Validators.required, Validators.pattern('^[A-zÀ-ÿ\\s]*$')]],
      codigo: ['', [Validators.required]],
      tipo: ['', [Validators.required]],
      documento: ['', [Validators.required]],
      direccion: ['', [Validators.required]],
      ciudad: ['', [Validators.required, Validators.pattern('^[A-zÀ-ÿ\\s]*$')]],
      pais: ['', [Validators.required]],
      codigoPais: ['', [Validators.required]],
      provincia: ['', [Validators.required, Validators.pattern('^[A-zÀ-ÿ\\s]*$')]],
    }, {
      validator: [documentoValidar('tipo', 'documento')]
    });

    this.facturaForm.emit(this.datosFacturaForm);
    this.datosFacturaForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => this.facturaForm.emit(this.datosFacturaForm));
    this.noEsAdmin = this.productosService.noEsAdministracion();
    if (!this.noEsAdmin) {
      this.buscador();
    }
  }

  get g() {
    return this.datosFacturaForm.controls;
  }

  checkFactura() {
    const em = this.ventaService.carritoValue.compras[0].producto.enlaceMenu;
    const enlaceMenu = this.productosService.enlacesMenuValue.find(e => e.pkid === em);
    if (enlaceMenu.iSolicitaFactura === '2') {
      this.check = true;
      this.isCheck.emit(this.check);
      return this.check;
    } else {
      this.check = !this.check;
      this.isCheck.emit(this.check);
      return this.check;
    }
  }

  cargarDatosCliente() {
    this.cargarDatos = !this.cargarDatos;
    if (this.cargarDatos) {
      const clienteModel = this.ventaService.clienteValue;
      if (clienteModel) {
        const nombreCliente = clienteModel.NombreCliente + ' ' + clienteModel.Apellido1 + ' ' + clienteModel.Apellido2;
        this.datosFacturaForm.controls.nombre.patchValue(nombreCliente);
        this.datosFacturaForm.controls.codigo.patchValue(clienteModel.CodigoPostal);
        this.datosFacturaForm.controls.tipo.patchValue(clienteModel.TipoDocumento);
        this.datosFacturaForm.controls.documento.patchValue(clienteModel.Documento);
        this.datosFacturaForm.controls.direccion.patchValue(clienteModel.Direccion);
        this.datosFacturaForm.controls.ciudad.patchValue(clienteModel.Ciudad);
        this.datosFacturaForm.controls.pais.patchValue(clienteModel.Pais);
        this.datosFacturaForm.controls.codigoPais.patchValue(clienteModel.CodigoPais);
        this.datosFacturaForm.controls.provincia.patchValue(clienteModel.Provincia);
      }
    } else {
      this.datosFacturaForm.controls.nombre.patchValue('');
      this.datosFacturaForm.controls.codigo.patchValue('');
      this.datosFacturaForm.controls.tipo.patchValue('');
      this.datosFacturaForm.controls.documento.patchValue('');
      this.datosFacturaForm.controls.direccion.patchValue('');
      this.datosFacturaForm.controls.ciudad.patchValue('');
      this.datosFacturaForm.controls.pais.patchValue('');
      this.datosFacturaForm.controls.codigoPais.patchValue('');
      this.datosFacturaForm.controls.provincia.patchValue('');
    }
  }

  abrirBuscador(campo) {
    this.abrir = [];
    this.abrir.push({ campo, abierto: true });
    this.clientes = [];
    this.setPage(1);
  }

  estaAbierto() {
    let abierto = false;
    if (this.abrir.length) {
      abierto = true;
      return abierto;
    } else {
      return false;
    }
  }

  textoBuscar(texto, campo) {
    this.modelChanged.next(texto);
    this.campo = campo;
  }

  buscador() {
    this.modelChanged
      .pipe(debounceTime(500))
      .pipe(exhaustMap(input => this.userService.getClientes(input, this.campo))).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
        if (res) {
          if (res.length) {
            this.clientes = res;
            this.totalPages = Math.ceil(this.clientes.length / 5);
            this.setPage(1);
          } else {
            this.clientes = [];
            this.setPage(1);
          }
        } else {
          this.clientes = [];
          this.setPage(1);
        }
      });
  }

  setPage(page: number) {
    this.pager = this.pagerService.getPager(this.clientes.length, page);
    this.pagedItems = this.clientes.slice(this.pager.startIndex, this.pager.endIndex + 1);
    this.pager.pages = this.pager.pages.filter(num => num <= this.pager.totalPages);
    if (page > 6) {
      while (this.pager.pages.length < 6) {
        this.pager.pages.unshift(this.pager.pages[0] - 1);
      }
    }
  }

  anterior() {
    this.setPage(this.pager.currentPage - 1);
  }

  siguiente() {
    this.setPage(this.pager.currentPage + 1);
  }

  seleccionarCliente(cliente) {
    let dni;
    if (cliente.TipoDocumentoFiscal === 'DNI/NIF') {
      dni = 'DNI';
    }

    this.datosFacturaForm.controls.nombre.patchValue(cliente.NombreClienteFiscal ? cliente.NombreClienteFiscal : cliente.NombreClienteOriginal);
    this.datosFacturaForm.controls.codigo.patchValue(cliente.CPFiscal ? cliente.CPFiscal : cliente.CP);
    this.datosFacturaForm.controls.tipo.patchValue(dni ? dni : cliente.TipoDocumento);
    this.datosFacturaForm.controls.documento.patchValue(cliente.CIFoDNIFiscal ? cliente.CIFoDNIFiscal : cliente.CIFoDNI);
    this.datosFacturaForm.controls.direccion.patchValue(cliente.DomicilioFiscal ? cliente.DomicilioFiscal : cliente.Domicilio);
    this.datosFacturaForm.controls.ciudad.patchValue(cliente.LocalidadFiscal ? cliente.LocalidadFiscal : cliente.Localidad);
    this.datosFacturaForm.controls.pais.patchValue(cliente.PaisFiscal ? cliente.Pais : cliente.Pais);
    this.datosFacturaForm.controls.codigoPais.patchValue(cliente.PaisFiscal ? cliente.CodigoPais : cliente.CodigoPais);
    this.datosFacturaForm.controls.provincia.patchValue(cliente.ProvinciaFiscal ? cliente.ProvinciaFiscal : cliente.Provincia);

    this.abrir = [];
  }
//<<Yaribel 20210531 añadimos funcion para cargar el nombre del pais cuando se selecciona un nuevo pais
  SeleccionaPais(){
    if (this.datosFacturaForm && this.datosFacturaForm.controls.pais) {
      this.datosFacturaForm.controls.pais.setValue(
        this.paises.find(e=> e.Id == this.paisSeleccionado)?this.paises.find(e=> e.Id == this.paisSeleccionado).Nombre: ''
        );
    }
  }
  //Yaribel 20210531 añadimos funcion para cargar el nombre del pais cuando se selecciona un nuevo pais >>
}
