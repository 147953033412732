import { Injectable } from '@angular/core';
import { VentaService } from 'src/app/core/services/venta.service';
import { HttpClient } from '@angular/common/http';
import { map, mergeMap, take } from 'rxjs/operators';
import { ConfigurationService } from '../config/configuration.service';
import * as moment from 'moment';
import 'moment/min/locales';
import { AppConfiguration } from 'src/app/app.configuration';
import { of, BehaviorSubject, Observable } from 'rxjs';
import { TranslateService } from 'src/app/shared/translate.service';
import { ProductosService } from 'src/app/business/productos.service';
import { PaymentGateway } from './paymentGateway.enum';
import { PaymentType } from './paymentType.enum';
import { Router } from '@angular/router';
import { Carrito } from 'src/app/models/carrito.model';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/user/user.service';
import { TokenManagerService } from '../token/token-manager.service';
import { environment } from 'src/environments/environment';

interface Fecha {
  dia: moment.Moment;
  hora: moment.Moment;
}

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  localizadores: any = [];
  productosOrdenados = [];
  conFactura = 0;

  private paymentGatewaySubject: BehaviorSubject<PaymentGateway>;
  public paymentGateway$: Observable<PaymentGateway>;

  private paymentTypeSubject: BehaviorSubject<PaymentType>;
  public paymentType$: Observable<PaymentType>;

  private adminSubject: BehaviorSubject<any>;
  public admin$: Observable<any>;

  private mostrarBotonesModificarSubject: BehaviorSubject<any>;
  public mostrarBotonesModificar$: Observable<any>;

  private mostrarBotonesPagarSubject: BehaviorSubject<any>;
  public mostrarBotonesPagar$: Observable<any>;

  private mostrarBotonesReservarSubject: BehaviorSubject<any>;
  public mostrarBotonesReservar$: Observable<any>;

  private mostrarBotonesPhoneSubject: BehaviorSubject<any>;
  public mostrarBotonesPhone$: Observable<any>;

  private mostrarBotonesBizumSubject: BehaviorSubject<any>;
  public mostrarBotonesBizum$: Observable<any>;

  private mostrarBotonesPaypalSubject: BehaviorSubject<any>;
  public mostrarBotonesPaypal$: Observable<any>;

  private mostrarValePromocionalSubject: BehaviorSubject<any>;
  public mostrarValePromocional$: Observable<any>;

  private mostrarDescuentoSubject: BehaviorSubject<any>;
  public mostrarDescuento$: Observable<any>;
  //<<Yaribel 20210914  VSTS 8424
  private mostrarDescuentoClienteAsociadoSubject: BehaviorSubject<any>;
  public mostrarDescuentoClienteAsociado$: Observable<any>;
  // VSTS 8424 >>
  private mostrarDescuentoAdminSubject: BehaviorSubject<any>;
  public mostrarDescuentoAdmin$: Observable<any>;

  private mostrarFacturaSubject: BehaviorSubject<any>;
  public mostrarFactura$: Observable<any>;

  private pdfDescargadoSubject: BehaviorSubject<boolean>;
  public pdfDescargado$: Observable<boolean>;

  private hayDescuentoSubject: BehaviorSubject<boolean>;
  public hayDescuento$: Observable<boolean>;

  private tieneAdelandoSubject: BehaviorSubject<boolean>;
  public tieneAdelanto$: Observable<boolean>;

  constructor(
    private toast: ToastrService,
    private router: Router,
    private ventaService: VentaService,
    private translate: TranslateService,
    private http: HttpClient,
    private configurationService: ConfigurationService,
    private config: AppConfiguration,
    private productosService: ProductosService,
    private translateService: TranslateService,
    private tokenManager: TokenManagerService,
    private userService: UserService /*,
    private initService: InitService,*/
  ) {
    this.tieneAdelandoSubject = new BehaviorSubject(false);
    this.tieneAdelanto$ = this.tieneAdelandoSubject.asObservable();

    this.pdfDescargadoSubject = new BehaviorSubject(false);
    this.pdfDescargado$ = this.pdfDescargadoSubject.asObservable();

    this.adminSubject = new BehaviorSubject(undefined);
    this.admin$ = this.adminSubject.asObservable();

    this.mostrarBotonesModificarSubject = new BehaviorSubject(false);
    this.mostrarBotonesModificar$ = this.mostrarBotonesModificarSubject.asObservable();

    this.mostrarBotonesPagarSubject = new BehaviorSubject(false);
    this.mostrarBotonesPagar$ = this.mostrarBotonesPagarSubject.asObservable();

    this.mostrarBotonesReservarSubject = new BehaviorSubject(false);
    this.mostrarBotonesReservar$ = this.mostrarBotonesReservarSubject.asObservable();

    this.mostrarBotonesPhoneSubject = new BehaviorSubject(false);
    this.mostrarBotonesPhone$ = this.mostrarBotonesPhoneSubject.asObservable();

    this.mostrarBotonesBizumSubject = new BehaviorSubject(false);
    this.mostrarBotonesBizum$ = this.mostrarBotonesBizumSubject.asObservable();

    this.mostrarBotonesPaypalSubject = new BehaviorSubject(false);
    this.mostrarBotonesPaypal$ = this.mostrarBotonesPaypalSubject.asObservable();

    this.mostrarValePromocionalSubject = new BehaviorSubject(false);
    this.mostrarValePromocional$ = this.mostrarValePromocionalSubject.asObservable();

    this.mostrarDescuentoSubject = new BehaviorSubject(false);
    this.mostrarDescuento$ = this.mostrarDescuentoSubject.asObservable();

    //<<Yaribel 20210914 VSTS 8424
    this.mostrarDescuentoClienteAsociadoSubject = new BehaviorSubject(false);
    this.mostrarDescuentoClienteAsociado$ = this.mostrarDescuentoClienteAsociadoSubject.asObservable();
    // VSTS 8424 >>

    this.mostrarDescuentoAdminSubject = new BehaviorSubject(false);
    this.mostrarDescuentoAdmin$ = this.mostrarDescuentoAdminSubject.asObservable();

    this.mostrarFacturaSubject = new BehaviorSubject(false);
    this.mostrarFactura$ = this.mostrarFacturaSubject.asObservable();

    this.paymentTypeSubject = new BehaviorSubject(undefined);
    this.paymentType$ = this.paymentTypeSubject.asObservable();

    this.paymentGatewaySubject = new BehaviorSubject(undefined);
    this.paymentGateway$ = this.paymentGatewaySubject.asObservable();

    this.hayDescuentoSubject = new BehaviorSubject(false);
    this.hayDescuento$ = this.hayDescuentoSubject.asObservable();
  }
  public get tieneAdelantoValue() {
    return this.tieneAdelandoSubject.value;
  }

  public settieneAdelanto(adelanto) {
    this.tieneAdelandoSubject.next(adelanto);
  }

  public get hayDescuentoValue() {
    return this.hayDescuentoSubject.value;
  }

  public setHayDescuento(descuento) {
    this.hayDescuentoSubject.next(descuento);
  }

  public get pdfDescargadoValue() {
    return this.pdfDescargadoSubject.value;
  }

  public setPdfDescargado(decargado) {
    this.pdfDescargadoSubject.next(decargado);
  }

  public get mostrarFacturaValue() {
    return this.mostrarFacturaSubject.value;
  }

  public setMostrarFactura(validado) {
    this.mostrarFacturaSubject.next(validado);
  }
  public get mostrarValePromocionalValue() {
    return this.mostrarValePromocionalSubject.value;
  }

  public setMostrarValePromocional(validado) {
    this.mostrarValePromocionalSubject.next(validado);
  }

  public get mostrarDescuentoValue() {
    return this.mostrarDescuentoSubject.value;
  }

  public setMostrarDescuento(validado) {
    this.mostrarDescuentoSubject.next(validado);
  }

  //Yaribel 20210914  VSTS 8424
  public get mostrarDescuentoClienteAsociadoValue() {
    return this.mostrarDescuentoClienteAsociadoSubject.value;
  }

  public setMostrarDescuentoClienteAsociado(validado) {
    this.mostrarDescuentoClienteAsociadoSubject.next(validado);
  }
  // VSTS 8424 >>

  public get mostrarDescuentoAdminValue() {
    return this.mostrarDescuentoAdminSubject.value;
  }

  public setMostrarDescuentoAdmin(validado) {
    this.mostrarDescuentoAdminSubject.next(validado);
  }

  public get mostrarBotonesModificarValue() {
    return this.mostrarBotonesModificarSubject.value;
  }

  public setMostrarBotonesModificar(botones) {
    this.mostrarBotonesModificarSubject.next(botones);
  }

  public get mostrarBotonesPagarValue() {
    return this.mostrarBotonesPagarSubject.value;
  }

  public setMostrarBotonesPagar(botones) {
    this.mostrarBotonesPagarSubject.next(botones);
  }

  public get mostrarBotonesReservarValue() {
    return this.mostrarBotonesReservarSubject.value;
  }

  public setMostrarBotonesReservar(botones) {
    this.mostrarBotonesReservarSubject.next(botones);
  }

  public get mostrarBotonesPhoneValue() {
    return this.mostrarBotonesPhoneSubject.value;
  }

  public setMostrarBotonesPhone(botones) {
    this.mostrarBotonesPhoneSubject.next(botones);
  }
  public get mostrarBotonesBizumValue() {
    return this.mostrarBotonesBizumSubject.value;
  }


  public setMostrarBotonesBizum(botones) {
    this.mostrarBotonesBizumSubject.next(botones);
  }

  public get mostrarBotonesPaypalValue() {
    return this.mostrarBotonesPaypalSubject.value;
  }


  public setmostrarBotonesPaypal(botones) {
    this.mostrarBotonesPaypalSubject.next(botones);
  }

  public get adminValue() {
    return this.adminSubject.value;
  }

  public setAdmin(botones) {
    this.adminSubject.next(botones);
  }

  get paymentType() {
    if (!this.paymentTypeSubject.value) {
      const payment = JSON.parse(sessionStorage.getItem('paymentType'));
      this.setPaymentType(payment);
    }
    return this.paymentTypeSubject.value;
  }

  setPaymentType(paymentType: PaymentType) {
    sessionStorage.setItem('paymentType', JSON.stringify(paymentType));
    this.paymentTypeSubject.next(paymentType);
  }

  get paymentGateway() {
    if (!this.paymentGatewaySubject.value) {
      const payment = JSON.parse(sessionStorage.getItem('payment'));
      this.setPaymentGateway(payment);
    }
    return this.paymentGatewaySubject.value;
  }

  setPaymentGateway(paymentGateway: PaymentGateway) {
    this.paymentGatewaySubject.next(paymentGateway);
  }

  generaFechasMinimas(compras: Array<any>): Fecha {
    const fecha: Fecha = {
      dia: null,
      hora: null
    };
    compras.forEach(compra => {
      if (compra.sesionesSeleccionadas && compra.sesionesSeleccionadas.length > 0) {
        const compraDia = moment(compra.sesionesSeleccionadas[0].Fecha, 'DD/MM/YYYY');
        const compraHora = moment(compra.sesionesSeleccionadas[0].HoraInicio, 'HH:mm');
        if (!fecha.dia || !fecha.hora) {
          fecha.dia = compraDia;
          fecha.hora = compraHora;
        } else {
          if (fecha.dia > compraDia) {
            fecha.dia = compraDia;
            fecha.hora = compraHora;
          } else if (fecha.dia === compraDia) {
            if (fecha.hora > compraHora) {
              fecha.hora = compraHora;
            }
          } else if (fecha.hora.hours().toString() == "0" && fecha.hora < compraHora) {
            fecha.hora = compraHora;
          }
        }
      } else { 
        if (!fecha.dia || !fecha.hora) {
          if (this.ventaService.fechaSeleccionadaSinSessionValue && this.ventaService.fechaSeleccionadaSinSessionValue.length > 0 && this.ventaService.fechaSeleccionadaSinSessionValue.find(find => find.posicionVenta == compra.posicionVenta && find.productoId == compra.producto.ProductoId)) {
            fecha.dia = this.ventaService.fechaSeleccionadaSinSessionValue.find(find => find.posicionVenta == compra.posicionVenta && find.productoId == compra.producto.ProductoId).fecha? moment(this.ventaService.fechaSeleccionadaSinSessionValue.find(find => find.posicionVenta == compra.posicionVenta && find.productoId == compra.producto.ProductoId).fecha, 'DD/MM/YYYY') : moment()
          } else {
            fecha.dia = moment();
          }
          fecha.hora = moment('00:00:00', 'HH:mm:ss');
        }
      }       
      
      if(compra.producto.butacas && compra.producto.butacas.length > 0){
        const compraDia = moment(compra.producto.butacas[0].FechaSesion, 'YYYY/MM/DD');
        const compraHora = moment(compra.producto.butacas[0].HoraSesion, 'HH:mm');
        if (!fecha.dia || !fecha.hora) {
          fecha.dia = compraDia;
          fecha.hora = compraHora;
        } else {
          if (fecha.dia > compraDia) {
            fecha.dia = compraDia;
            fecha.hora = compraHora;
          } else if (fecha.dia === compraDia) {
            if (fecha.hora > compraHora) {
              fecha.hora = compraHora;
            }
          } else if (fecha.hora.hours().toString() == "0" && fecha.hora < compraHora) {
            fecha.hora = compraHora;
          }
        }
      }
    });
    return fecha;
  }

  private postForm(path, params, method) {
    const form = document.createElement('form');
    form.setAttribute('method', method);
    form.setAttribute('action', path);
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const hiddenField = document.createElement('input');
        hiddenField.setAttribute('type', 'hidden');
        hiddenField.setAttribute('name', key);
        hiddenField.setAttribute('value', params[key]);
        form.appendChild(hiddenField);
      }
    }
    document.body.appendChild(form);
    form.submit();
  }

  confirmarPago(token, numeroPedido, url, sesionIdCompra,mediopago) {
    const body = {
      EmpresaClave: this.config.getConfig('chClaveEmpresa'),
      ProyectoNombre: this.config.getConfig('chNombreProyecto'),
      PasarelaEntorno: this.getPaymentGateway(mediopago),
      URLOK: url,
      numPedido: numeroPedido,
      SessionId: sesionIdCompra? sesionIdCompra:token.sessionId,
      EsPagoBizum: mediopago===2 ?1:0
    };
    return this.http.post(`${this.config.getConfig('API_URL')}/api/Pagos/PasarelaPagoConfirmacion`, body);
  }

  modificarNumeroPedido(numPedido, localizador, tipo, Nombre?, Contrasena?) {
    const body = {
      NumeroPedido: numPedido,
      Localizador: [localizador],
      Usuario: '',
      Password: '',
      TipoUsuario: tipo

    };

    const clienteApi = {
      Id: this.tokenManager.currentTokenValue.sessionId,
      Token: this.tokenManager.currentTokenValue.token
    };

    const conexionIacpos = this.tokenManager.currentStorageConexionValue;
    body["SesionId"] = clienteApi.Id;


    if (Nombre && Contrasena) {
      body.Usuario = Nombre;
      body.Password = Contrasena
    }
    return this.http.post(`${this.config.getConfig('API_URL')}/api/ActualizarNumeroPedido`, body);

  }

  modificarReserva(json_encrypted) {
    let url = environment["URL"] + "tienda/resumen?ck=";

    this.router.navigateByUrl("/tienda/resumen?ck=" + json_encrypted);
  }

  /*
{"numpedmod":"083810007438","aforo":"1","u":"info@kulturaltours.com_213","p":"cf83e1357eefb8bdf1542850d66d8007d620e4050b5715dc83f4a921d36ce9ce47d0d13c5d85f2b0ff8318d2877eec2f63b931bd47417a81a538327af927da3e","lg":"ca"}
*/

  confirmarPagoPhoneAndSell(numeroPedido) {
    const body = {
      empresaClave: this.config.getConfig('chClaveEmpresa'),
      proyectoNombre: this.config.getConfig('chNombreProyecto'),
      pasarelaEntorno: this.config.getConfig('pasarelaEntornoPhoneSell'),
      objetoGenerar: {
        monedaISO: '978',
        nombreReserva: numeroPedido.toString(),
        //numeroTerminal: this.configurationService.configurationValue.datosProyecto.chNumeroTerminal,
        FOP:'PS'
      },      
    };
    return this.http.post(`${this.config.getConfig('API_URL')}/api/Pagos/PasarelaValidarPagoEnBanco`, body);
  }

  confirmarVenta(pedido, token) {
    const body = {
      IdentificadorUnico: token.sessionId,
      NumeroPedido: pedido.toString(),
      FOP: 'PS'
    };
    return this.http.post(`${this.config.getConfig('API_URL')}/api/ConfirmacionVenta`, body);
  }

  traerReserva(numPedido, localizador, tipo, Nombre?, Contrasena?) {
    const body = {
      NumeroPedido: numPedido,
      Localizador: localizador.split('(')[0],
      Usuario: '',
      Password: '',
      TipoUsuario: tipo
    };

    const clienteApi = {
      Id: this.tokenManager.currentTokenValue.sessionId,
      Token: this.tokenManager.currentTokenValue.token
    };

    const conexionIacpos = this.tokenManager.currentStorageConexionValue;
    /*   body.chParams = codigoCliente; */
    body["ClienteAPI"] = clienteApi;
    body["ConexionIacpos"] = conexionIacpos;

    if (Nombre && Contrasena) {
      body.Usuario = Nombre;
      body.Password = Contrasena
    }
    return this.http.post(`${this.config.getConfig('API_URL')}/api/TraerReservaUnitaria`, body).pipe(map((res: any) => {
      if (!res.DatosResult) {
        return of();
      }
      return res.DatosResult;
    }));
  }

  irPDFCompra(nombreReserva, tipo, email) {
    const body = {
      EnlaceMenu: this.productosService.getEnlaceMenuId() ? this.productosService.getEnlaceMenuId().pkid : undefined,
      Idioma: this.translate.getLocale(),
      ClaveReserva: '',
      NumeroPedido: nombreReserva,
      EnvioMail: email,
      Nombre: '',
      Contrasena: '',
      Tipocliente: tipo,
      NuevoSistema: 1
    };
    return this.http.post(`${this.config.getConfig('API_URL')}/api/GenerarPDF`, body);
  }

  getPaymentGateway(paymentGateway: PaymentGateway) {
    switch (paymentGateway) {
      case PaymentGateway.REDSYS:
        return this.config.getConfig('pasarelaRedsys');
      case PaymentGateway.PAYPAL:
        return this.config.getConfig('pasarelaPaypal');
      case PaymentGateway.BIZUM:
        return this.config.getConfig('pasarelaRedsys');
      case PaymentGateway.PHONEANDSELL:
        return this.config.getConfig('pasarelaPhoneSell');
    }
  }

  generarFactura(pedido, localizadores) {
    const factura = JSON.parse(sessionStorage.getItem('factura'));
    const body = {
      Idioma: this.configurationService.configurationValue.datosInicio.Idioma0,
      CIFCliente: factura.documento,
      NombreCliente: factura.nombre,
      DomicilioCliente: factura.direccion,
      LocalidadCliente: factura.ciudad,
      CPCliente: factura.codigo,
      Provincia: factura.provincia,
      Pais: factura.pais,
      Localizadores: localizadores,
      NumerosPedido: pedido,
      NuevoFuncionamiento: '1'
    };
    return this.http.post(`${this.config.getConfig('API_URL')}/api/GenerarFactura`, body).pipe(map((res: any) => {
      return res;
    }));
  }

  pagarInsercionUnica() {
    const body = this.bodyInsercionUnica(2);// se pasa un 2 por tipo de accion(compra o reserva)
    if(this.validarCampos(body.clienteCompra))
    {
    return this.http.post(`${this.config.getConfig('API_URL')}/api/InsercionUnitaria`, body)
      .pipe(mergeMap((res: any) => {
        if (res.DatosResult === null) {
          this.productosService.setCargandoValue(false);
          this.toast.error(this.translateService.data.ERROROPERACION, 'Error', {
            timeOut: 5000,
            positionClass: 'toast-top-right',
          });
          return of();
        }
        if (res.DatosResult.Localizador === null) {

          
          if (res.Mensajes[0].CodigoMensaje === '-6') {
            this.productosService.setCargandoValue(false);
            this.toast.error(this.translateService.data.ERROROPERACIONAFORO, 'Error', {
              timeOut: 5000,
              positionClass: 'toast-top-right',
            });
            return of();
          } else if (res.Mensajes[0].CodigoMensaje.substring(0,2) === 'RN') {
            this.productosService.setCargandoValue(false);
            let xMensaje = res.Mensajes[0].CodigoMensaje;
            this.toast.error(this.translateService.data[xMensaje], 'Error', {
              timeOut: 5000,
              positionClass: 'toast-top-right',
            });
            return of();
          }           
          else {
            this.productosService.setCargandoValue(false);
            this.toast.error(this.translateService.data.ERROROPERACION, 'Error', {
              timeOut: 5000,
              positionClass: 'toast-top-right',
            });
            return of();
          }
    
        }
        sessionStorage.setItem('localizador', res.DatosResult.Localizador);
        sessionStorage.setItem('pedido', res.DatosResult.Pedido);
        if (sessionStorage.getItem('currentUser')) {
          this.userService.updateCliente(JSON.parse(sessionStorage.getItem('currentUser')).CodigoCliente).pipe(take(1)).pipe(map(res => {
            if (res && res.DatosResult) {
              this.userService.setDatosUserValue(res.DatosResult);
              this.userService.setCurrentUserValue(res.DatosResult);
              this.ventaService.mapCliente(res.DatosResult);
              sessionStorage.setItem('currentUser', JSON.stringify(res.DatosResult));

            }
          })).subscribe();
        }
        return of({
          primeraPeticion: res
        });
      }),
        mergeMap((res: any) => {
          if (this.ventaService.carritoValue.calcularPrecioTotal() == 0 || this.ventaService.descuentoTotalesValue && this.ventaService.descuentoTotalesValue.totalDescuento != undefined && this.ventaService.descuentoTotalesValue.totalDescuento == 0) {
            this.router.navigate(['/thankyou']);
          } else {
            let tokenCompra = JSON.parse(sessionStorage.getItem('currentToken'));
            const pasarelaBody = {
              EmpresaClave: this.config.getConfig('chClaveEmpresa'),
              ProyectoNombre: this.config.getConfig('chNombreProyecto'),
              PasarelaEntorno: this.getPaymentGateway(this.paymentGateway),
              ObjetoGenerar: {
                Moneda: '978',
                TotalCompra: this.ventaService.carritoValue.calcularPrecioTotal(),
                NombreReserva: sessionStorage.getItem('pedido'),
                NumeroTerminal: this.configurationService.configurationValue.datosProyecto.chNumeroTerminal,
                // URLOK: this.config.getConfig('URLOK') + '/' + this.paymentType + '/' + paymentGateway + '/' + sessionStorage.getItem('pedido'),
                // URLKO: this.config.getConfig('URLKO') + '/' + this.paymentType + '/' + paymentGateway + '/' + sessionStorage.getItem('pedido'),
                URLOK: this.config.getConfig('URLOK') + '/' + this.crearDatosPago({tipoPago: this.paymentType, medioPago: this.paymentGateway, pedido:sessionStorage.getItem('pedido') , sesionIdCompra:tokenCompra.sessionId}),
                URLKO: this.config.getConfig('URLKO') + '/' +  this.crearDatosPago({tipoPago: this.paymentType, medioPago: this.paymentGateway, pedido:sessionStorage.getItem('pedido') , sesionIdCompra:tokenCompra.sessionId}),
                MensajeCompleto: '',
                MetodoDePago: this.paymentGateway == PaymentGateway.BIZUM ? "z" : null
              }
            };

            return this.http.post(`${this.config.getConfig('API_URL')}/api/Pagos/PasarelaPago`, pasarelaBody).pipe(map((datosPago: any) => {
              const urlRedSys = datosPago.DatosResult.Url_PaymentForm_Redsys;
              const postData = {
                Ds_SignatureVersion: datosPago.DatosResult.Ds_SignatureVersion,
                Ds_MerchantParameters: datosPago.DatosResult.Ds_MerchantParameters,
                Ds_Signature: datosPago.DatosResult.Ds_Signature
              };
              this.postForm(urlRedSys, postData, 'post');
              res.primeraPeticion.datosPago = datosPago;
              return res;
            }));
          }
        })
      );
    } else{      
      this.productosService.setCargandoValue(false);
      this.toast.error(this.translateService.data.VERIFICAREMAIL, 'Error', {
        timeOut: 5000,
        positionClass: 'toast-top-right',
      });
    }

    
  }

  pasarelaPago(pedido, total) {

    let paymentGateway = this.paymentGateway != 2 ? this.paymentGateway : 1
    const pasarelaBody = {
      EmpresaClave: this.config.getConfig('chClaveEmpresa'),
      ProyectoNombre: this.config.getConfig('chNombreProyecto'),
      PasarelaEntorno: this.config.getConfig('chEntornoConexion'),
      ObjetoGenerar: {
        Moneda: '978',
        TotalCompra: total,
        NombreReserva: pedido,
        NumeroTerminal: this.configurationService.configurationValue.datosProyecto.chNumeroTerminal,
        /*         URLOK: this.config.getConfig('URLOK')+ '/' + this.paymentType   + '/'+ paymentGateway + '/'+ pedido,
                URLKO: this.config.getConfig('URLKO')+ '/' + this.paymentType   + '/'+ paymentGateway + '/'+ pedido, */
        URLOK: this.config.getConfig('URLOK') + '/0/0/' + pedido,
        URLKO: this.config.getConfig('URLKO') + '/0/0/' + pedido,
        MensajeCompleto: '',
        MetodoDePago: this.paymentGateway == PaymentGateway.BIZUM ? "z" : null
      }
    };

    return this.http.post(`${this.config.getConfig('API_URL')}/api/Pagos/PasarelaPago`, pasarelaBody).pipe(map((datosPago: any) => {
      const urlRedSys = datosPago.DatosResult.Url_PaymentForm_Redsys;
      const postData = {
        Ds_SignatureVersion: datosPago.DatosResult.Ds_SignatureVersion,
        Ds_MerchantParameters: datosPago.DatosResult.Ds_MerchantParameters,
        Ds_Signature: datosPago.DatosResult.Ds_Signature
      };
      this.postForm(urlRedSys, postData, 'post');
      //res.primeraPeticion.datosPago = datosPago;
      //return res;
    }));
  }

  reservarInsercionUnica() {
    const body = this.bodyInsercionUnica(1);
    if(this.validarCampos(body.clienteCompra)){
    return this.http.post(`${this.config.getConfig('API_URL')}/api/InsercionUnitaria`, body)
      .pipe(mergeMap((res: any) => {
        if (res.DatosResult === null) {
          if (res.Mensajes[0].CodigoMensaje === '-6') {
            this.productosService.setCargandoValue(false);
            this.toast.error(this.translateService.data.ERROROPERACIONAFORO, 'Error', {
              timeOut: 5000,
              positionClass: 'toast-top-right',
            });
            return of();
          } else if (res.Mensajes[0].CodigoMensaje.substring(0,2) === 'RN') {
            this.productosService.setCargandoValue(false);
            let xMensaje = res.Mensajes[0].CodigoMensaje;
            this.toast.error(this.translateService.data[xMensaje], 'Error', {
              timeOut: 5000,
              positionClass: 'toast-top-right',
            });
            return of();
          }   
          else {
            this.productosService.setCargandoValue(false);
            this.toast.error(this.translateService.data.ERROROPERACION, 'Error', {
              timeOut: 5000,
              positionClass: 'toast-top-right',
            });
            return of();
          }
        }
        if (res.DatosResult.Localizador === null) {
          if (res.Mensajes[0].CodigoMensaje === '-6') {
            this.productosService.setCargandoValue(false);
            this.toast.error(this.translateService.data.ERROROPERACIONAFORO, 'Error', {
              timeOut: 5000,
              positionClass: 'toast-top-right',
            });
            return of();
          } else if (res.Mensajes[0].CodigoMensaje.substring(0,2) === 'RM') {
            this.productosService.setCargandoValue(false);
            let xMensaje = res.Mensajes[0].CodigoMensaje;
            this.toast.error(this.translateService.data[xMensaje], 'Error', {
              timeOut: 5000,
              positionClass: 'toast-top-right',
            });
            return of();
          }   
          else {
            this.productosService.setCargandoValue(false);
            this.toast.error(this.translateService.data.ERROROPERACION, 'Error', {
              timeOut: 5000,
              positionClass: 'toast-top-right',
            });
            return of();
          }
        }
        sessionStorage.setItem('localizador', res.DatosResult.Localizador);
        sessionStorage.setItem('pedido', res.DatosResult.Pedido);
        if (sessionStorage.getItem('currentUser')) {
          this.userService.updateCliente(JSON.parse(sessionStorage.getItem('currentUser')).CodigoCliente).pipe(take(1)).pipe(map(res => {
            if (res && res.DatosResult) {
              this.userService.setDatosUserValue(res.DatosResult);
              this.userService.setCurrentUserValue(res.DatosResult);
              this.ventaService.mapCliente(res.DatosResult);
              sessionStorage.setItem('currentUser', JSON.stringify(res.DatosResult));

            }
          })).subscribe();
        }
        return of({
          primeraPeticion: res,
        });
      }),
        // QUITAR
        mergeMap((res: any) => {
          if (!res.primeraPeticion.DatosResult.Pedido) {
            if (res.primeraPeticion.Mensajes[0].CodigoMensaje === '-6') {
              this.productosService.setCargandoValue(false);
              this.toast.error(this.translateService.data.ERROROPERACIONAFORO, 'Error', {
                timeOut: 5000,
                positionClass: 'toast-top-right',
              });
            } else if (res.primeraPeticion.Mensajes[0].CodigoMensaje.substring(0,2) === 'RM') {
              this.productosService.setCargandoValue(false);
              let xMensaje = res.primeraPeticion.Mensajes[0].CodigoMensaje;
              this.toast.error(this.translateService.data[xMensaje], 'Error', {
                timeOut: 5000,
                positionClass: 'toast-top-right',
              });
              return of();
            }               
            else {
              this.productosService.setCargandoValue(false);
              this.toast.error(this.translateService.data.ERROROPERACION, 'Error', {
                timeOut: 5000,
                positionClass: 'toast-top-right',
              });
            }
          } else {
            this.ventaService.setCarrito(new Carrito());
            sessionStorage.removeItem('cesta');
            sessionStorage.removeItem('fechaSeleccionadaSinSession');
            this.router.navigate(['/thankyou']);
            return of({ ...res });
          }
        })
        ,
      );
    }else{
      this.productosService.setCargandoValue(false);
      this.toast.error(this.translateService.data.VERIFICAREMAIL, 'Error', {
        timeOut: 5000,
        positionClass: 'toast-top-right',
      });
    }

  }

  phoneNsellInsercionUnica() {
    const body = this.bodyInsercionUnica(2);
    if(this.validarCampos(body.clienteCompra)){
    return this.http.post(`${this.config.getConfig('API_URL')}/api/InsercionUnitaria`, body)
      .pipe(mergeMap((res: any) => {
        if (!res.DatosResult) {
          if (res.Mensajes[0].CodigoMensaje === '-6') {
            this.productosService.setCargandoValue(false);
            this.toast.error(this.translateService.data.ERROROPERACIONAFORO, 'Error', {
              timeOut: 5000,
              positionClass: 'toast-top-right',
            });
            return of();
          } else if (res.Mensajes[0].CodigoMensaje.substring(0,2) === 'RN') {
            this.productosService.setCargandoValue(false);
            let xMensaje = res.Mensajes[0].CodigoMensaje;
            this.toast.error(this.translateService.data[xMensaje], 'Error', {
              timeOut: 5000,
              positionClass: 'toast-top-right',
            });
            return of();
          }   
          else {
            this.productosService.setCargandoValue(false);
            this.toast.error(this.translateService.data.ERROROPERACION, 'Error', {
              timeOut: 5000,
              positionClass: 'toast-top-right',
            });
            return of();
          }
        }
        if (res.DatosResult.Localizador === null) {
          if (res.Mensajes[0].CodigoMensaje === '-6') {
            this.productosService.setCargandoValue(false);
            this.toast.error(this.translateService.data.ERROROPERACIONAFORO, 'Error', {
              timeOut: 5000,
              positionClass: 'toast-top-right',
            });
            return of();
          } else if (res.Mensajes[0].CodigoMensaje.substring(0,2) === 'RM') {
            this.productosService.setCargandoValue(false);
            let xMensaje = res.Mensajes[0].CodigoMensaje;
            this.toast.error(this.translateService.data[xMensaje], 'Error', {
              timeOut: 5000,
              positionClass: 'toast-top-right',
            });
            return of();
          }   
          else {
            this.productosService.setCargandoValue(false);
            this.toast.error(this.translateService.data.ERROROPERACION, 'Error', {
              timeOut: 5000,
              positionClass: 'toast-top-right',
            });
            return of();
          }
        }
        sessionStorage.setItem('localizador', res.DatosResult.Localizador);
        sessionStorage.setItem('pedido', res.DatosResult.Pedido);
        if (sessionStorage.getItem('currentUser')) {
          this.userService.updateCliente(JSON.parse(sessionStorage.getItem('currentUser')).CodigoCliente).pipe(take(1)).pipe(map(res => {
            if (res && res.DatosResult) {
              this.userService.setDatosUserValue(res.DatosResult);
              this.userService.setCurrentUserValue(res.DatosResult);
              this.ventaService.mapCliente(res.DatosResult);
              sessionStorage.setItem('currentUser', JSON.stringify(res.DatosResult));

            }
          })).subscribe();
        }
        return of({
          primeraPeticion: res
        });
      })
        ,
        mergeMap((res: any) => {
          if (this.ventaService.carritoValue.calcularPrecioTotal() == 0 || this.ventaService.descuentoTotalesValue && this.ventaService.descuentoTotalesValue.totalDescuento != undefined && this.ventaService.descuentoTotalesValue.totalDescuento == 0) {
            this.router.navigate(['/thankyou']);

          } else {
            const user1 = JSON.parse(sessionStorage.getItem('clienteContacto'));
            const pasarelaBody = {
              empresaClave: this.config.getConfig('chClaveEmpresa'),
              proyectoNombre: this.config.getConfig('chNombreProyecto'),
              PasarelaEntorno: this.getPaymentGateway(this.paymentGateway),
              objetoGenerar: {
                monedaISO: '978',
                totalCompra: this.ventaService.carritoValue.calcularPrecioTotal(),
                nombreReserva: sessionStorage.getItem('pedido'),
                numeroTerminal: this.configurationService.configurationValue.datosProyecto.chNumeroTerminal
              },
              emailSend: user1.email ? user1.email : '',
              telefonoSMSSend: user1 ? user1.telefono : ''
            };
            return this.http.post(`${this.config.getConfig('API_URL')}/api/Pagos/PasarelaPagoPhoneAndSell`, pasarelaBody).pipe(map((datosPago: any) => {
              return datosPago;
            }));
          }
        })
      );
      }else{
        this.productosService.setCargandoValue(false);
        this.toast.error(this.translateService.data.VERIFICAREMAIL, 'Error', {
          timeOut: 5000,
          positionClass: 'toast-top-right',
        });
      }
  }

  reenviarPasarelaPago() {
    const user = JSON.parse(sessionStorage.getItem('admin')) ? JSON.parse(sessionStorage.getItem('admin')).u : null;
    const password = JSON.parse(sessionStorage.getItem('admin')) ? JSON.parse(sessionStorage.getItem('admin')).p : null;
    const body = {
      NumeroPedido: '',
      Localizador: [sessionStorage.getItem('localizador')],
      Usuario: user,
      Password: password,
      SesionId: '',
      TipoUsuario: '1',
    };
    return this.http.post(`${this.config.getConfig('API_URL')}/api/ActualizarNumeroPedido`, body).pipe(mergeMap((res: any) => {
      if (res.DatosResult) {
        sessionStorage.setItem('pedido', res.DatosResult.MensajeRespuesta);
      }
      return of(res);
    }),
      mergeMap((res: any) => {
        const user1 = JSON.parse(sessionStorage.getItem('clienteContacto'));
        const pasarelaBody = {
          empresaClave: this.config.getConfig('chClaveEmpresa'),
          proyectoNombre: this.config.getConfig('chNombreProyecto'),
          PasarelaEntorno: this.getPaymentGateway(this.paymentGateway),
          objetoGenerar: {
            monedaISO: '978',
            totalCompra: 2233,
            nombreReserva: sessionStorage.getItem('pedido'),
            numeroTerminal: this.configurationService.configurationValue.datosProyecto.chNumeroTerminal
          },
          emailSend: user1.email ? user1.email : '',
          telefonoSMSSend: user1 ? user1.telefono : ''
        };
        return this.http.post(`${this.config.getConfig('API_URL')}/api/Pagos/PasarelaPagoPhoneAndSell`, pasarelaBody).pipe(map((datosPago: any) => {
          return datosPago;
        }));
      }
      ));
  }

  bodyInsercionUnica(accion) {
    const userId = JSON.parse(this.productosService.datosInitValue).userId ? JSON.parse(this.productosService.datosInitValue).userId : '';
    const fechaMinima: Fecha = this.generaFechasMinimas(this.ventaService.carritoValue.compras);
    let entradasSinSesion = 1;
    let aplazadoEsVenta = '1';
    if (JSON.parse(this.configurationService.configurationValue.datosProyecto.CaracteristicasAdicionales).CaracteristicasAdicionales.AplazadoEsVenta) {
      aplazadoEsVenta = JSON.parse(this.configurationService.configurationValue.datosProyecto.CaracteristicasAdicionales).CaracteristicasAdicionales.AplazadoEsVenta;
    }      
    
    let precioTotal = 0;
    let precioInicial = 0;
    this.ventaService.carritoValue.compras.forEach(compra => { 
      const precioInternet = Number.parseFloat((compra.producto.PVPInternet).replace(/,/, '.'));
      const comisionInternet = Number.parseFloat((compra.producto.ComisionInternet).replace(/,/, '.'));
      const precioBase = Number.parseFloat((Number.parseFloat((compra.producto.PrecioBase).replace(/,/, '.')).toFixed(4)));
      const cantidad = compra['cantidad'];
      let subtotal = 0;
      let subtotalIni = 0;
      subtotal = (precioInternet*cantidad) + (comisionInternet*cantidad);
      subtotalIni = precioBase*cantidad;
      precioTotal = precioTotal + subtotal;
      precioInicial = precioInicial + subtotalIni;     
      
      if (compra.sesionesSeleccionadas) {
        entradasSinSesion = 0;
      }
      else if(compra.producto.butacas){
        entradasSinSesion = 0;
      }
    });
    
    const body = {       
      IncluirComision: this.ventaService.incluirComisionValue ? 1:0,
      Idioma: this.configurationService.configurationValue.datosInicio.Idioma0,
      NumeroEntradasTotal: this.ventaService.carritoValue.calcularTotalEntradas(),
      Fecha: fechaMinima.dia.format('YYYY/MM/DD'),
      Hora: fechaMinima.hora ? fechaMinima.hora.format('HH:mm:ss') : '00:00:00',
      EntradasGratuitas: 0,
      ImporteInicial: this.ventaService.getTwoDecimal(precioInicial).toFixed(4), //(this.ventaService.carritoValue.calcularPrecioTotalSinIva()).toFixed(4), 
      TotalReserva: precioTotal.toFixed(4), //(this.ventaService.descuentoCodValue || this.ventaService.descuentoClienteAsociadoCodValue ? this.ventaService.descuentoTotalesValue.totalDescuento : this.ventaService.getPreciofinal()).toFixed(4), 
      TipoReserva: this.ventaService.carritoValue.compras[0].producto.butacas && this.ventaService.carritoValue.compras[0].producto.butacas.length > 0? 2: this.ventaService.carritoValue.compras[0].producto.TipoReservaId,
      Accion: accion,
      Audioguia: 0,
      notas: '',
      EntradasSinFecha: entradasSinSesion,
      EntradasSinHora: entradasSinSesion,
      ClaveReserva: '',
      LetraLocalizador: this.letraLocalizador() ? this.letraLocalizador() : 'N',
      NumeroPedido: '',
      CodigoValidacionUnico: this.configurationService.configurationValue.currentSession.sessionId,
      EnlaceMenu: this.ventaService.carritoValue.compras[0].producto.enlaceMenu,
      ExternoId: '',
      UsuarioBDId: userId,
      NombreUsuarioBD: '',
      ReservasAdicionales: {
        NotasInternas: this.ventaService.clienteValue.NotasInternas ? this.ventaService.clienteValue.NotasInternas.split('\n').join('#') : '',
      },
      clienteCompra: this.bodyClienteCompra(),
      ProductosCompra: this.generarProductosCompraInsercionUnica(),
      ClaveReservaOrigen: '',
      GenerarFactura: this.conFactura,
      FOP:accion == '1' ? (aplazadoEsVenta  == '1' && this.ventaService.clienteValue.TipoPagoNombre ? (this.ventaService.clienteValue.TipoPagoNombre == 'DP'? 'DP':''):'') :'',
      ValePromocional: this.ventaService.descuentoValue ? this.ventaService.descuentoValue.descuento : '',
      ValePromocionalImporteTotal: this.ventaService.descuentoValue ? this.ventaService.carritoValue.calcularPrecioTotal() : '',
      ValePromocionalNumProductosTotal: this.ventaService.descuentoValue ? this.ventaService.carritoValue.calcularTotalEntradas() : '',
      DtoPersonalizadoTipo: this.ventaService.descuentoPersonalizadoValue ? this.ventaService.descuentoPersonalizadoValue.tipoDescuento : '',
      DtoPersonalizadoCantidad: this.ventaService.descuentoPersonalizadoValue ? this.ventaService.descuentoPersonalizadoValue.cantidad : '',
      DtoPersonalizadoImporte: this.ventaService.descuentoPersonalizadoValue ? this.ventaService.descuentoPersonalizadoValue.importe : '',
      Plataforma: this.productosService.getPlataforma(),
      CodigoPromocionalDatos: this.ventaService.descuentoCodValue ? this.calcCodigoPromocional() : '',
      DescuentoClienteAsociadoDatos: this.ventaService.descuentoClienteAsociadoValue ? this.calcDescuentoClienteAsociado() : '',
    };
    return body;
  }
  calcCodigoPromocional() {
    const CodigoPromocional = this.ventaService.descuentoCodValue.codigo;
    const DtoImporte = this.ventaService.descuentoTotalesValue.descuento;
    const ProductosSeleccionados = this.ventaService.descuentoObjetoValue;
    const codigoPromocionalDatos = { CodigoPromocional, DtoImporte, ProductosSeleccionados };
    return codigoPromocionalDatos;
  }
  //<< Yaribel 20210820 VSTS 8424
  calcDescuentoClienteAsociado() {
    const Codigo = this.ventaService.descuentoClienteAsociadoCodValue;
    const DtoImporte = this.ventaService.descuentoTotalesValue.descuento;
    const ProductosSeleccionados = this.ventaService.descuentoClienteAsociadoObjetoValue;
    const codigoClienteAsociadoDatos = { Codigo, DtoImporte, ProductosSeleccionados };
    return codigoClienteAsociadoDatos;
  }
  // VSTS 8424 >>
  bodyReservasAdiciones() {
    //Modificamos para que se obtenga el descuento del currentUser
    //const userReserva = this.ventaService.clienteValue;
    const userReserva = JSON.parse(sessionStorage.getItem('currentUser')) || this.ventaService.clienteValue;
    let tipoDescuento;
    let descuento;
    if (userReserva) {
      if (userReserva.Descuento === '1') {
        tipoDescuento = userReserva.TipoDto;
        descuento = userReserva.rDescuento;
      }
    }
    const user = JSON.parse(sessionStorage.getItem('admin')) ? JSON.parse(sessionStorage.getItem('admin')).u : '';
    const password = JSON.parse(sessionStorage.getItem('admin')) ? JSON.parse(sessionStorage.getItem('admin')).p : '';
    let personaPago;
    if (this.ventaService.clienteValue.NombreResponsable) {
      personaPago = this.ventaService.clienteValue.NombreResponsable;
    } else if (this.ventaService.clienteValue.NombreCliente) {
      personaPago = this.ventaService.clienteValue.NombreCliente + ' ' + this.ventaService.clienteValue.Apellido1;
    }
    const body = {
      Nombre: user ? user : this.ventaService.clienteValue.User,
      Contrasena: password ? password : this.ventaService.clienteValue.Pass,
      NombreCliente: this.ventaService.clienteValue.NombreCliente ? this.ventaService.clienteValue.NombreCliente : '',
      NombreContacto: this.ventaService.clienteValue.NombreContacto ? this.ventaService.clienteValue.NombreContacto : '',
      PersonaPago: personaPago ? personaPago : '',
      CIFoDNI: this.ventaService.clienteValue.Documento ? this.ventaService.clienteValue.Documento : '',
      Telefono: this.ventaService.clienteValue.Telefono ? this.ventaService.clienteValue.Telefono : '',
      Direccion: this.ventaService.clienteValue.Direccion ? this.ventaService.clienteValue.Direccion : '',
      Ciudad: this.ventaService.clienteValue.Ciudad ? this.ventaService.clienteValue.Ciudad : '',
      ProvinciaContacto: '',
      CodigoPostal: this.ventaService.clienteValue.CodigoPostal ? this.ventaService.clienteValue.CodigoPostal : '',
      Pais: this.ventaService.clienteValue.Pais ? this.ventaService.clienteValue.Pais : '',
      CodigoPais: this.ventaService.clienteValue.CodigoPais ? this.ventaService.clienteValue.CodigoPais : '',//Yaribel 20210531 Añadimos codigo pais al objeto clente
      Email: this.ventaService.clienteValue.Email.toLowerCase(),
      Apellidos: this.ventaService.clienteValue.Apellido1 ? this.ventaService.clienteValue.Apellido1 : '',
      Apellido2Contacto: this.ventaService.clienteValue.Apellido2 ? this.ventaService.clienteValue.Apellido2 : '',
      TelefonoMovilContacto: this.ventaService.clienteValue.Telefono2 ? this.ventaService.clienteValue.Telefono2 : '',
      TipoViaContacto: '',
      PisoContacto: '',
      Nacionalidad: '',
      PrefijoInternacional: '',
      Edad: 0,
      Sexo: '',
      otroEmail: '',
      Curso: this.ventaService.clienteValue.Curso ? this.ventaService.clienteValue.Curso : '',
      TipoCentro: this.ventaService.clienteValue.TipoCentro ? this.ventaService.clienteValue.TipoCentro : '',
      Nota: this.ventaService.clienteValue.Notas ? this.ventaService.clienteValue.Notas.split('\n').join('<br>') : '',
      TipoDescuento: tipoDescuento,
      Descuento: descuento,
      TipoDocumento: this.ventaService.clienteValue.TipoDocumento ? this.ventaService.clienteValue.TipoDocumento : '',
      PoliticaPrivacidad: {
        ConexionIacpos: 0,
        clienteAPI: null,
        TipoPolitica: 0,
        EnlacePolitica: this.translateService.data.ENLACEPOLITICAPRIVACIDAD,
        NombreProyecto: this.config.getConfig('chClaveEmpresa'),
        NombreFormulario: '/tienda/resumen',
        Nombres: null,
        Apellidos: null,
        Email: this.ventaService.clienteValue.Email.toLowerCase(),
        CodigoCliente: 0,
        PublicidadInterna: this.ventaService.clienteValue.Publicidad ? 1 : 0,
        DNI: this.ventaService.clienteValue.Documento ? this.ventaService.clienteValue.Documento : '',
      },
    };
    return body;
  }
  bodyClienteCompra() {
    //Modificamos para que se obtenga el descuento del currentUser
    //const userReserva = this.ventaService.clienteValue;
    const userReserva = JSON.parse(sessionStorage.getItem('currentUser')) || this.ventaService.clienteValue;
    let tipoDescuento;
    let descuento;
    if (userReserva) {
      if (userReserva.Descuento === '1') {
        tipoDescuento = userReserva.TipoDto;
        descuento = userReserva.rDescuento;
      }
    }
    const user = JSON.parse(sessionStorage.getItem('admin')) ? JSON.parse(sessionStorage.getItem('admin')).u : '';
    const password = JSON.parse(sessionStorage.getItem('admin')) ? JSON.parse(sessionStorage.getItem('admin')).p : '';
    let personaPago;
    if (this.ventaService.clienteValue.NombreResponsable) {
      personaPago = this.ventaService.clienteValue.NombreResponsable;
    } else if (this.ventaService.clienteValue.NombreCliente) {
      personaPago = this.ventaService.clienteValue.NombreCliente + ' ' + this.ventaService.clienteValue.Apellido1;
    }
    const body = {
      Nombre: user ? user : this.ventaService.clienteValue.User,
      Contrasena: password ? password : this.ventaService.clienteValue.Pass,
      NombreCliente: this.ventaService.clienteValue.NombreCliente ? this.ventaService.clienteValue.NombreCliente : '',
      NombreContacto: this.ventaService.clienteValue.NombreContacto ? this.ventaService.clienteValue.NombreContacto : '',
      PersonaPago: personaPago ? personaPago : '',
      CIFoDNI: this.ventaService.clienteValue.Documento ? this.ventaService.clienteValue.Documento : '',
      Telefono: this.ventaService.clienteValue.Telefono ? this.ventaService.clienteValue.Telefono : '',
      Direccion: this.ventaService.clienteValue.Direccion ? this.ventaService.clienteValue.Direccion : '',
      Ciudad: this.ventaService.clienteValue.Ciudad ? this.ventaService.clienteValue.Ciudad : '',
      ProvinciaContacto: this.ventaService.clienteValue.Provincia ? this.ventaService.clienteValue.Provincia : '',
      CodigoPostal: this.ventaService.clienteValue.CodigoPostal ? this.ventaService.clienteValue.CodigoPostal : '',
      Pais: this.ventaService.clienteValue.Pais ? this.ventaService.clienteValue.Pais : '',
      CodigoPais: this.ventaService.clienteValue.CodigoPais ? this.ventaService.clienteValue.CodigoPais : '',//Yaribel 20210531 Añadimos codigo pais al objeto clente
      Email: this.ventaService.clienteValue.Email.toLowerCase(),//se convierte a minuscula
      Apellidos: this.ventaService.clienteValue.Apellido1 ? this.ventaService.clienteValue.Apellido1 : '',
      Apellido2Contacto: this.ventaService.clienteValue.Apellido2 ? this.ventaService.clienteValue.Apellido2 : '',
      TelefonoMovilContacto: this.ventaService.clienteValue.Telefono2 ? this.ventaService.clienteValue.Telefono2 : '',
      TipoViaContacto: '',
      PisoContacto: '',
      Nacionalidad: '',
      PrefijoInternacional: '',
      Edad: 0,
      Sexo: '',
      otroEmail: '',
      Curso: this.ventaService.clienteValue.Curso ? this.ventaService.clienteValue.Curso : '',
      TipoCentro: this.ventaService.clienteValue.TipoCentro ? this.ventaService.clienteValue.TipoCentro : '',
      Nota: this.ventaService.clienteValue.Notas ? this.ventaService.clienteValue.Notas.split('\n').join('#') : '',
      TipoDescuento: tipoDescuento,
      Descuento: descuento,
      TipoDocumento: this.ventaService.clienteValue.TipoDocumento ? this.ventaService.clienteValue.TipoDocumento : '',
      PoliticaPrivacidad: {
        ConexionIacpos: 0,
        clienteAPI: null,
        TipoPolitica: 0,
        EnlacePolitica: this.translateService.data.ENLACEPOLITICAPRIVACIDAD,
        NombreProyecto: this.config.getConfig('chClaveEmpresa'),
        NombreFormulario: '/tienda/resumen',
        Nombres: null,
        Apellidos: null,
        Email: this.ventaService.clienteValue.Email.toLowerCase(), //se convierte a minuscula
        CodigoCliente: this.userService.currentUserValue && this.userService.currentUserValue.CodigoCliente && this.userService.currentUserValue.CodigoCliente > 0 ? this.userService.currentUserValue.CodigoCliente : 0,
        PublicidadInterna: this.ventaService.clienteValue.PublicidadInterna ? 1 : 0,
        DNI: this.ventaService.clienteValue.Documento ? this.ventaService.clienteValue.Documento : '',
      },
    };
    return body;
  }

  bodyPersonaNominada(data, comentarios) {
    const p = {
      Nombre: data ? data.txtNombre : '',
      Apellido1: data ? data.txtApellidos : '',
      Apellido2: '',
      CifoDni: data ? data.txtDNI : '',
      Domicilio: '',
      TipoCalle: '',
      NumCalle: '',
      Piso: '',
      Localidad: '',
      CP: data ? data.txtCodigoPostal : '',
      Provincia: '',
      Pais: data ? data.cboPaises : '',
      Telefono: data ? data.txtTelefono : '',
      TelefonoMovil: '',
      Fax: '',
      Email: data ?  data.txtEmail1 : '',
      FechaNacimiento: '',
      Sexo: data ? data.cboSexo : '',
      TipoCentro: '',
      Edad: data ? data.txtEdad : '',
      RangoEdad: data ? data.cboRangoEdad : '',
      Minusvalido: 0,
      Jubilado: 0,
      PublicidadInterna: 0,
      PublicidadExterna: 0,
      NombreResponsable: '',
      Apellido1Responsable: '',
      Apellido2Responsable: '',
      Nota: '',
      Audioguia: 0,
      PublicidadOrganizador: 0,
      Curso: data ? data.cboNivel : '',
      LSE: 0,
      Pregunta1: '',
      Respuesta1: data ? data.cboDia : '',
      Pregunta2: '',
      Respuesta2: data ? data.cboTrimestre : '',
      Pregunta3: '',
      Respuesta3: '',
      Pregunta4: '',
      Respuesta4: data ? data.cboTipoTabla : '',
      NombreCentro: comentarios,
      Nacionalidad: '',
      ProductoId: data ? data.ProductoId : '',
      IdiomaGuia: data ? data.cboIdiomaVisita : '',
      PoliticaPrivacidad: {
        ConexionIacpos: 0,
        clienteAPI: null,
        TipoPolitica: 0,
        EnlacePolitica: null,
        NombreProyecto: null,
        NombreFormulario: null,
        Nombres: null,
        Apellidos: null,
        Email: null,
        CodigoCliente: 0,
        PublicidadInterna: this.ventaService.clienteValue.Publicidad ? 1 : 0,
        DNI: null
      },
      TipoDocumento: data ? data.cboTipoDNI : ''
    };
    // mnieva se agrega chTipoDocumento que se guardaba en Pregunta3 cuando ya existia el campo de destino correcto.
    return p;
  }

  bodySesion(sesion, cantidad) { 
    const aforoPorProducto = sesion.TotalAforoUnidad ? sesion.TotalAforoUnidad : 1;
    const afotoTotalPorProducto = aforoPorProducto * cantidad;
    const af = {
      IdRecinto: sesion.IdRecinto,
      NombreRecinto: sesion.NombreRecinto,
      DescripcionRecinto: '',
      HoraFin: sesion.HoraFin ? sesion.HoraFin : '',
      FechaVisita: moment(sesion.Fecha, 'DD/MM/YYYY').format('YYYY/MM/DD'),
      HoraVisita: moment(sesion.HoraInicio, 'HH:mm').format('HH:mm:ss'),
      TotalAforo: afotoTotalPorProducto,
      TipoAforo: sesion.TipoAforo,
      RecintoSesion: sesion.RecintosSesionId,
      Guia: sesion.Profesor ? sesion.Profesor : '',
    };
    return af;
  }

  generarProductosCompraInsercionUnica() {
    let comentarios;
    const productos = [];
    this.ventaService.carritoValue.compras.forEach(compra => {
      if (compra.sesionesSeleccionadas) {
        if (compra.sesionesSeleccionadas[0].Comentarios) {
          comentarios = compra.sesionesSeleccionadas[0].Comentarios;
        } else {
          comentarios = '';
        }
      }
      const personas = [];
      if (compra.datos) {
        if (compra.datos.length) {
          compra.datos.forEach(data => {
            personas.push(this.bodyPersonaNominada(data, comentarios));
          });
        } else {
          personas.push(this.bodyPersonaNominada(compra.datos, comentarios));
        }
      }
      const aforos = [];
      if (compra.sesionesSeleccionadas) {
        compra.sesionesSeleccionadas.forEach(sesion => {
          aforos.push(this.bodySesion(sesion, compra.cantidad));
        });
      }
      const motivos = [];
      if (compra.producto.motivos) {
        if (compra.producto.motivos.length) {
          compra.producto.motivos.forEach(motivo => {
            motivos.push({ MotivoId: motivo.PkId, Cantidad: motivo.cantidad });
          });
        }
      }
      const butacas = [];
      // TODO YS 20221221 añadir los datos de la butaca del producto
      
      const producto = {
        EsBono: compra.producto.TipoProducto ? (compra.producto.TipoProducto - 1).toString() : compra.producto.EsBono,
        ProductoId: compra.producto.ProductoId,
        NombreProducto: compra.producto.NombreProducto,
        DescripcionProducto: compra.producto.DescripcionProducto,
        Cantidad: compra.cantidad,
        GrupoInternet: compra.producto.GrupoInternet,
        EnlaceMenu: compra.producto.enlaceMenu,
        PosicionVenta: compra.posicionVenta, // + 1,
        NombreGrupoInternet: compra.producto.NombreGrupo,
        PrecioBruto: Number.parseFloat(compra.producto.PrecioBase.replace(/,/, '.')).toFixed(4),
        PvpProducto: Number.parseFloat(compra.producto.PVPSinDescuento.replace(/,/, '.')).toFixed(4), // PrecioEnDivisaReferencia //PVPSinDescuento
        DescuentoUnidad: Number.parseFloat(compra.producto.Descuento.replace(/,/, '.')).toFixed(4),
        Comision: Number.parseFloat(compra.producto.ComisionInternet.replace(/,/, '.')).toFixed(4),
        IvaComision: compra.producto.IvaComision,
        CodigoPromocional: '',
        Conjunta: 0,
        TotalEntradas: compra.producto.NumeroTotalEntradasBono * compra.cantidad,
        DesglosarComision: compra.producto.DesglosarComision,
        FechaVisita: compra.sesionesSeleccionadas ? moment(compra.sesionesSeleccionadas[0].Fecha, 'DD/MM/YYYY').format('YYYY/MM/DD') : compra.producto.butacas && compra.producto.butacas[0]? compra.producto.butacas[0].FechaSesion : this.ventaService.fechaSeleccionadaSinSessionValue.length > 0 ? moment(this.ventaService.fechaSeleccionadaSinSessionValue.find(find => find.posicionVenta == compra.posicionVenta && find.productoId == compra.producto.ProductoId).fecha, 'DD/MM/YYYY').format('YYYY/MM/DD') : "",
        butacas: compra.producto.butacas,
        IVA: compra.producto.IVA,
        AforosConsumidos: aforos,
        PersonasReserva: personas,
        InformacionListasBlancas: null,
        TipoListado: 0,
        MotivosGruposItems: motivos,
        GrupoTarifa:compra.producto.GrupoTarifa
      };
      productos.push(producto);
    });
    return productos;
  }

  modificarReservaUnica() {
    const fecha = this.generaFechasMinimas(this.ventaService.carritoValue.compras);
    const aforoAnterior = [];
    const userId = JSON.parse(this.productosService.datosInitValue).userId ? JSON.parse(this.productosService.datosInitValue).userId : JSON.parse(sessionStorage.getItem("admin")) ? JSON.parse(sessionStorage.getItem("admin"))["userId"] ? JSON.parse(sessionStorage.getItem("admin"))["userId"] : "" : "";
    let entradasSinSesion = 1;
    let precioTotal = 0;
    let precioInicial = 0;
    this.ventaService.carritoValue.compras.forEach(compra => {
      const precioInternet = Number.parseFloat((compra.producto.PVPInternet).replace(/,/, '.'));
      const comisionInternet = Number.parseFloat((compra.producto.ComisionInternet).replace(/,/, '.'));
      const precioBase = Number.parseFloat((Number.parseFloat((compra.producto.PrecioBase).replace(/,/, '.')).toFixed(4)));
      const comisionBase = Number.parseFloat((Number.parseFloat((compra.producto.ComisionBase).replace(/,/, '.')).toFixed(4)));
      const cantidad = compra['cantidad'];
      let subtotal = 0;
      let subtotalIni = 0;
      subtotal = (precioInternet*cantidad) + (comisionInternet*cantidad);
      subtotalIni = (precioBase*cantidad) + (comisionBase*cantidad);
      precioTotal = precioTotal + subtotal;
      precioInicial = precioInicial + subtotalIni;   
      
      if (compra.sesionesSeleccionadas) {
        entradasSinSesion = 0;
      } else if(compra.producto.butacas){
        entradasSinSesion = 0;
      }
    });
    const reserva = JSON.parse(sessionStorage.getItem('reservaOriginal')).compras;
    reserva.forEach(compra => {
      if (compra.sesionesSeleccionadas) {
        compra.sesionesSeleccionadas.forEach(sesion => {
          aforoAnterior.push(sesion);
        });
      }
    });
    const aforoFinal = [];
    if (aforoAnterior.length !== 0) {
      aforoAnterior.forEach(aforo => {
        const af = {
          IdRecinto: aforo.IdRecinto,
          NombreRecinto: aforo.NombreRecinto,
          DescripcionRecinto: aforo.DescripcionRecinto,
          FechaVisita: aforo.Fecha,
          HoraVisita: aforo.HoraInicio,
          TotalAforo: aforo.TotalAforo,
          HoraFin: aforo.HoraFin ? aforo.HoraFin : '',
          RecintoSesion: aforo.RecintosSesionId
        };
        aforoFinal.push(af);
      });
    }
    const body = {
      IncluirComision: this.ventaService.incluirComisionValue ? 1:0,
      Idioma: this.configurationService.configurationValue.datosInicio.Idioma0,
      NumeroEntradasTotal: this.ventaService.carritoValue.calcularTotalEntradas(),
      Fecha: fecha.dia.format('YYYY/MM/DD'),
      Hora: fecha.hora.format('HH:mm:ss'),
      EntradasGratuitas: 0,
      ImporteInicial: this.ventaService.getTwoDecimal(precioInicial).toFixed(4), //(this.ventaService.carritoValue.calcularPrecioTotalSinIva()).toFixed(4), 
      TotalReserva: precioTotal.toFixed(4), //(this.ventaService.descuentoCodValue || this.ventaService.descuentoClienteAsociadoCodValue ? this.ventaService.descuentoTotalesValue.totalDescuento : this.ventaService.getPreciofinal()).toFixed(4),  
      TipoReserva: reserva[0].producto.tipoReseva,
      Accion: 1,
      Audioguia: 0,
      notas: '',
      EntradasSinFecha: entradasSinSesion,
      EntradasSinHora: entradasSinSesion,
      ClaveReserva: reserva[0].producto.ClaveReserva,
      LetraLocalizador: this.letraLocalizador() ? this.letraLocalizador() : 'O',
      NumeroPedido: this.ventaService.modificandoReservaValue,
      CodigoValidacionUnico: this.configurationService.configurationValue.currentSession.sessionId,
      EnlaceMenu: this.ventaService.carritoValue.compras[0].producto.enlaceMenu,
      ExternoId: '',
      UsuarioBDId: userId,
      NombreUsuarioBD: '',
      AforoAnterior: aforoFinal,
      ReservasAdicionales: {
        NotasInternas: this.ventaService.clienteValue.NotasInternas ? this.ventaService.clienteValue.NotasInternas.split('\n').join('<br>') : '',
      },
      clienteCompra: this.bodyClienteCompra(),
      ProductosCompra: this.generarProductosCompraInsercionUnica(),
      FOP: '',
      Plataforma: this.productosService.getPlataforma(),
      CodigoPromocionalDatos: this.ventaService.descuentoCodValue ? this.calcCodigoPromocional() : ''
    };
    return this.http.post(`${this.config.getConfig('API_URL')}/api/ModificarReservaConAforoUnitaria`, body)
      .pipe(mergeMap((res: any) => {
        if (!res.DatosResult) {
          if (res.Mensajes[0].CodigoMensaje === '-21') {
            this.productosService.setCargandoValue(false); 
            this.toast.error(this.translateService.data.ERRORMODIFICARRESERVA, 'Error', {
              timeOut: 5000,
              positionClass: 'toast-top-right',
            });
            return of();
          }
          if (res.Mensajes[0].CodigoMensaje === '-6') {
            this.productosService.setCargandoValue(false);
            this.toast.error(this.translateService.data.ERROROPERACIONAFORO, 'Error', {
              timeOut: 5000,
              positionClass: 'toast-top-right',
            });
            return of();
          } else if (res.Mensajes[0].CodigoMensaje.substring(0,2) === 'RN') {
            this.productosService.setCargandoValue(false);
            let xMensaje = res.Mensajes[0].CodigoMensaje;
            this.toast.error(this.translateService.data[xMensaje], 'Error', {
              timeOut: 5000,
              positionClass: 'toast-top-right',
            });
            return of();
          }   
          else {
            this.productosService.setCargandoValue(false);
            this.toast.error(this.translateService.data.ERROROPERACION, 'Error', {
              timeOut: 5000,
              positionClass: 'toast-top-right',
            });
            return of();
          }
        }
        if (!res.DatosResult.Pedido) {
          if (res.Mensajes[0].CodigoMensaje === '-21') {
            this.productosService.setCargandoValue(false);
            this.toast.error(this.translateService.data.ERRORMODIFICARRESERVA, 'Error', {
              timeOut: 5000,
              positionClass: 'toast-top-right',
            });
            return of();
          }
          if (res.Mensajes[0].CodigoMensaje === '-6') {
            this.productosService.setCargandoValue(false);
            this.toast.error(this.translateService.data.ERROROPERACIONAFORO, 'Error', {
              timeOut: 5000,
              positionClass: 'toast-top-right',
            });
            return of();
          } else if (res.Mensajes[0].CodigoMensaje.substring(0,2) === 'RN') {
            this.productosService.setCargandoValue(false);
            let xMensaje = res.Mensajes[0].CodigoMensaje;
            this.toast.error(this.translateService.data[xMensaje], 'Error', {
              timeOut: 5000,
              positionClass: 'toast-top-right',
            });
            return of();
          }   
          else {
            this.productosService.setCargandoValue(false);
            this.toast.error(this.translateService.data.ERROROPERACION, 'Error', {
              timeOut: 5000,
              positionClass: 'toast-top-right',
            });
            return of();
          }
        } else {
          sessionStorage.setItem('localizador', res.DatosResult.Localizador);
          sessionStorage.setItem('pedido', res.DatosResult.Pedido);
          this.ventaService.setCarrito(new Carrito());
          sessionStorage.removeItem('cesta');
          sessionStorage.removeItem('fechaSeleccionadaSinSession');
          this.router.navigate(['/thankyou']);
          return of({ ...res });
        }
      }),
      );
  }

  letraLocalizador() {
    const letra = JSON.parse(this.productosService.getEnlaceMenuId().CaracteristicasAdicionales).CaracteristicasAdicionales.LetraLocalizador;
    if (letra !== '') {
      return letra;
    }
  }

  traerReservaEstado(numPedido) {
    const body = {
      NumeroPedido: numPedido,
      Usuario: '',
      Password: '',
      TipoUsuario: 0,
      Localizador: ''
    };
    return this.http.post(`${this.config.getConfig('API_URL')}/api/TraerReservaEstado`, body);
  }

  validarCampos(datos){
    let res = true;
    const em = this.ventaService.enlaceMenuCarritoValue;
    const enlaceMenu = this.productosService.enlacesMenuValue.find(e => e.pkid === em);
    let campos = JSON.parse(enlaceMenu.CaracteristicasAdicionales).CaracteristicasAdicionales.DatosCompra;
    if(this.getCampoObligatorio(campos, 'email'))
    {
      res = (datos.Email && (datos.Email).length > 0 && datos.Email != '')? true: false;
    }
    if(this.getCampoObligatorio(campos, 'telefono')){
      res = (datos.Telefono && (datos.Telefono).length > 0 && datos.Telefono != '')? true: false;
    }
    return res;
  }

  getCampoObligatorio(campos, valor){
    let res = false;
    campos.forEach(campo => {
      if(campo.name === valor)
      {
        res = campo.required; 
      }
    });
    return res;
  }


  crearDatosPago(datos){
    const cadena = JSON.stringify(datos);
    return this.tokenManager.funcionEncript(cadena);
  }

  borrarDescuentos() {
    // Borrar todos descuentos si una vez aplicado se cambia
    this.setHayDescuento(false);

    this.ventaService.setDescuentoObjeto(undefined);
    this.ventaService.setDescuentoCod(undefined);
    this.ventaService.setDescuento(undefined);
    this.ventaService.setDescuentoPer(undefined);
    this.ventaService.setDescuentoPersonalizado(undefined);
    this.ventaService.setDescuentoTotales(undefined);
    this.ventaService.setDescuentoClienteAsociado(undefined);
    this.ventaService.setDescuentoClienteAsociadoCod(undefined);
    this.ventaService.setDescuentoClienteAsociadoObjeto(undefined);
    
    sessionStorage.removeItem("descuento");
  }

}
